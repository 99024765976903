// react
import React, { useEffect } from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import AsyncAction from '../shared/AsyncAction';
import Currency from '../shared/Currency';
import PageHeader from '../shared/PageHeader';
import Rating from '../shared/Rating';
import { cartAddItem } from '../../redux/ducks/cartDucks';
import { Cross12Svg } from '../../svg';
import { getUserWishlist, wishlistRemoveItem } from '../../redux/ducks/wishlistDucks';

// data stubs
import { getTitleLocale } from '../../helpers/general';
import UnipackHelmet from '../shared/UnipackHelmet';
import { IMAGE_URL } from '../config/general';
import { FormattedMessage } from 'react-intl';

function WishlistPage({ wishlist, cartAddItem, wishlistRemoveItem, locale, getUserWishlist }) {
    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'Wishlist', url: '' },
    ];

    useEffect(() => {
        getUserWishlist();
    }, []);

    let content;

    if (wishlist.length) {
        const itemsList = wishlist.map((item) => {
            let image;

            const { discounted_price, price } = item;
            const productPrice = !!Number(discounted_price) ? discounted_price : price;

            if (!!item.avatar) {
                image = (
                    <Link to={`/shop/product/${item.id}`}>
                        <img src={IMAGE_URL + item.avatar} alt="" />
                    </Link>
                );
            }

            const renderAddToCarButton = ({ run, loading }) => {
                const classes = classNames('btn btn-primary btn-sm', {
                    'btn-loading': loading,
                });

                return (
                    <button type="button" onClick={run} className={classes}>
                        <FormattedMessage id="Add To Cart" />
                    </button>
                );
            };

            const renderRemoveButton = ({ run, loading }) => {
                const classes = classNames('btn btn-light btn-sm btn-svg-icon', {
                    'btn-loading': loading,
                });

                return (
                    <button type="button" onClick={run} className={classes} aria-label="Remove">
                        <Cross12Svg />
                    </button>
                );
            };

            return (
                <tr key={item.id} className="wishlist__row">
                    <td className="wishlist__column wishlist__column--image">{image}</td>
                    <td className="wishlist__column wishlist__column--product">
                        <Link to={`/shop/product/${item.id}`} className="wishlist__product-name">
                            {item[getTitleLocale(locale)]}
                        </Link>
                        <div className="wishlist__product-rating">
                            <Rating value={item.rate} />
                            <div className="wishlist__product-rating-legend">
                                {item.reviews} <FormattedMessage id="Reviews" />
                            </div>
                        </div>
                    </td>
                    <td className="wishlist__column wishlist__column--stock">
                        <div className="badge badge-success">
                            <FormattedMessage id="In Stock" />
                        </div>
                    </td>
                    <td className="wishlist__column wishlist__column--price">
                        <Currency value={productPrice} />
                        {!!Number(discounted_price) && <Currency value={price} classNames={'small'} isDiscounted={true} />}
                    </td>
                    <td className="wishlist__column wishlist__column--tocart">
                        <AsyncAction action={() => cartAddItem(item)} render={renderAddToCarButton} />
                    </td>
                    <td className="wishlist__column wishlist__column--remove">
                        <AsyncAction action={() => wishlistRemoveItem(item)} render={renderRemoveButton} />
                    </td>
                </tr>
            );
        });

        content = (
            <div className="block">
                <div className="container">
                    <table className="wishlist">
                        <thead className="wishlist__head">
                            <tr className="wishlist__row">
                                <th className="wishlist__column wishlist__column--image">
                                    <FormattedMessage id="Image" />
                                </th>
                                <th className="wishlist__column wishlist__column--product">
                                    <FormattedMessage id="Product" />
                                </th>
                                <th className="wishlist__column wishlist__column--stock">
                                    <FormattedMessage id="Stock Status" />
                                </th>
                                <th className="wishlist__column wishlist__column--price">
                                    <FormattedMessage id="Price" />
                                </th>
                                <th className="wishlist__column wishlist__column--tocart" aria-label="Add to cart" />
                                <th className="wishlist__column wishlist__column--remove" aria-label="Remove" />
                            </tr>
                        </thead>
                        <tbody className="wishlist__body">{itemsList}</tbody>
                    </table>
                </div>
            </div>
        );
    } else {
        content = (
            <div className="block block-empty">
                <div className="container">
                    <div className="block-empty__body">
                        <div className="block-empty__message">
                            <FormattedMessage id="Your wish list is empty!" />
                        </div>
                        <div className="block-empty__actions">
                            <Link to="/" className="btn btn-primary btn-sm">
                                <FormattedMessage id="Continue" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <UnipackHelmet title="Wish List" />

            <PageHeader header="Wishlist" breadcrumb={breadcrumb} />

            {content}
        </>
    );
}

const mapStateToProps = ({ wishlist, locale }) => ({
    wishlist,
    locale,
});

const mapDispatchToProps = {
    cartAddItem,
    wishlistRemoveItem,
    getUserWishlist,
};

export default connect(mapStateToProps, mapDispatchToProps)(WishlistPage);
