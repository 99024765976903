import React from 'react';
import { useFormik } from 'formik';
import FormContainer from './FormContainer';
import FormInput from '../../../shared/FormInput';
import { Link } from 'react-router-dom';
import { Check9x7Svg } from '../../../../svg';
import { connect } from 'react-redux';
import { signIn } from '../../../../redux/ducks/userDucks';
import useRedirectOnClick from '../../../hooks/useRedirectOnClick';
import DangerAlert from './DangerAlert';
import { FormattedMessage } from 'react-intl';
import { resetLoginError } from '../../../../redux/ducks/validationDucks';
import useOnUnmount from '../../../hooks/useOnUnmount';

function LoginForm({ signIn, loginError, resetLoginError }) {
    const handleLogin = useRedirectOnClick(signIn, '/');
    const { location, message } = loginError;
    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
            isSave: false,
        },
        onSubmit: (values) => {
            handleLogin(values);
        },
    });

    useOnUnmount(resetLoginError);

    return (
        <FormContainer title={'Login'}>
            <form onSubmit={formik.handleSubmit}>
                <FormInput
                    id={'username'}
                    placeholder={'Enter email'}
                    label={'Enter email'}
                    type={'email'}
                    error={location === 'main'}
                    onChange={formik.handleChange}
                />
                <FormInput
                    id={'password'}
                    placeholder={'Password'}
                    label={'Password'}
                    type={'password'}
                    error={location === 'main'}
                    onChange={formik.handleChange}
                >
                    <small className="form-text text-muted">
                        <Link to="/account/forgotPass">
                            <FormattedMessage id="Forgot Password" />
                        </Link>
                    </small>
                </FormInput>
                <DangerAlert isVisible={location === 'main'} message={message} />
                <div className="form-group">
                    <div className="form-check">
                        <span className="form-check-input input-check">
                            <span className="input-check__body">
                                <input
                                    id="isSave"
                                    type="checkbox"
                                    className="input-check__input"
                                    onChange={formik.handleChange}
                                />
                                <span className="input-check__box" />
                                <Check9x7Svg className="input-check__icon" />
                            </span>
                        </span>
                        <label className="form-check-label" htmlFor="login-remember">
                            <FormattedMessage id="Remember Me" />
                        </label>
                    </div>
                </div>
                <button type="submit" className="btn btn-primary mt-2 mt-md-3 mt-lg-4">
                    <FormattedMessage id="Login" />
                </button>
            </form>
        </FormContainer>
    );
}

const mapDispatchToProps = {
    signIn,
    resetLoginError,
};

const mapStateToProps = ({ loginError }) => ({ loginError });

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
