// react
import React from 'react';

// third-party
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// application
import Dropdown from './Dropdown';
import { changeLocale } from '../../redux/ducks/localeDucks';
import { changeLanguage } from '../../api/requests';

function DropdownLanguage(props) {
    const { locale, changeLocale, isAuthenticated } = props;

    const languages = [
        {
            title: <FormattedMessage id="Armenian" />,
            locale: 'am',
            code: 'AM',
            icon: 'images/languages/language-3.png',
            icon_srcset: 'images/languages/language-3.png 1x, images/languages/language-3@2x.png 2x',
        },
        {
            title: <FormattedMessage id="English" />,
            locale: 'en',
            code: 'EN',
            icon: 'images/languages/language-1.png',
            icon_srcset: 'images/languages/language-1.png 1x, images/languages/language-1@2x.png 2x',
        },
        {
            title: <FormattedMessage id="Russian" />,
            locale: 'ru',
            code: 'RU',
            icon: 'images/languages/language-2.png',
            icon_srcset: 'images/languages/language-2.png 1x, images/languages/language-2@2x.png 2x',
        },
    ];

    const language = languages.find((x) => x.locale === locale);

    const title = (
        <>
            <FormattedMessage id="Language" defaultMessage="Language" />
            {': '}
            <span className="topbar__item-value">{language.code}</span>
        </>
    );

    return <Dropdown title={title} withIcons items={languages} onClick={handleLanguageChange} />;

    function handleLanguageChange(item) {
        isAuthenticated && changeLanguage(item.locale);
        changeLocale(item.locale);
    }
}

const mapStateToProps = ({ locale, isAuthenticated }) => ({
    locale,
    isAuthenticated,
});

const mapDispatchToProps = {
    changeLocale,
};

export default connect(mapStateToProps, mapDispatchToProps)(DropdownLanguage);
