// react
import React, { useEffect } from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// application
import MobileLinks from './MobileLinks';
import { Cross20Svg } from '../../svg';
import { changeLocale } from '../../redux/ducks/localeDucks';
import { mobileMenuClose } from '../../redux/ducks/mobileMenuDucks';

// data stubs
import currencies from '../../data/shopCurrencies';
import { currencyChange } from '../../redux/ducks/currencyDucks';
import { signOut } from '../../redux/ducks/userDucks';
import { FormattedMessage } from 'react-intl';
import { changeLanguage } from '../../api/requests';

function MobileMenu({ history, ...redux }) {
    const {
        categoriesMenu,
        locale,
        mobileMenuState,
        closeMobileMenu,
        changeLocale,
        changeCurrency,
        isAuthenticated,
        signOut,
    } = redux;

    const mobileMenuLinks = generateMobileLinks(isAuthenticated, categoriesMenu, signOut);

    useEffect(() => {
        const { pathname } = history.location;
        if (pathname.includes('shop/category/')) {
            const splitPath = pathname.split('/');
            const id = splitPath[splitPath.length - 1];
            if (!isNaN(id)) {
                closeMobileMenu();
            }
        }
    }, [closeMobileMenu, history.location, history.location.pathname]);

    const classes = classNames('mobilemenu', {
        'mobilemenu--open': mobileMenuState.open,
    });

    const handleItemClick = (item) => {
        if (item.data) {
            if (item.data.type === 'language') {
                isAuthenticated && changeLanguage(item.data.locale);
                changeLocale(item.data.locale);
                closeMobileMenu();
            }
            if (item.data.type === 'currency') {
                const currency = currencies.find((x) => x.currency.code === item.data.code);

                if (currency) {
                    changeCurrency(currency.currency);
                    closeMobileMenu();
                }
            }
        }
        if (item.type === 'link') {
            closeMobileMenu();
        }
    };

    return (
        <div className={classes}>
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
            <div className="mobilemenu__backdrop" onClick={closeMobileMenu} />
            <div className="mobilemenu__body">
                <div className="mobilemenu__header">
                    <div className="mobilemenu__title">
                        <FormattedMessage id={'Menu'} />
                    </div>
                    <button type="button" className="mobilemenu__close" onClick={closeMobileMenu}>
                        <Cross20Svg />
                    </button>
                </div>
                <div className="mobilemenu__content">
                    <MobileLinks
                        links={mobileMenuLinks}
                        locale={locale}
                        onItemClick={handleItemClick}
                        mobileMenuState={mobileMenuState}
                    />
                </div>
            </div>
        </div>
    );

    // function handleFilterCheckboxChange(parentId, id) {
    //     if (parentId === 'options') {
    //         updateFilterOptions(id);
    //     }
    // }
    //
    // function handlePriceFilterAndResetClicks({ currentTarget: { id } }) {
    //     setFilteredPrice({ from: filters.price.from, to: filters.price.to });
    //     if (id === 'reset') {
    //         resetFilteredPrice();
    //     }
    // }
}

function generateMobileLinks(isAuthenticated, categoriesMenu, signOut = () => null) {
    const accountMenu = isAuthenticated
        ? [
              { type: 'link', label: 'Edit Profile', url: '/account/profile' },
              { type: 'link', label: 'Order History', url: '/account/orders' },
              { type: 'link', label: 'Change Password', url: '/account/password' },
              { type: 'link', label: 'Logout', url: '', onClick: signOut },
          ]
        : [{ type: 'link', label: 'Login', url: '/account/login' }];
    return [
        {
            type: 'link',
            label: 'Categories',
            url: '/shop/products',
            children: categoriesMenu,
        },
        {
            type: 'link',
            label: 'New Products',
            url: '/shop/opt/new',
        },
        {
            type: 'link',
            label: 'Featured',
            url: '/shop/opt/featured',
        },
        // {
        //     type: 'link',
        //     label: 'Gifts',
        //     url: '/gifts',
        // },
        {
            type: 'link',
            label: 'Sale',
            url: '/shop/opt/sale',
        },
        {
            type: 'link',
            label: 'Wishlist',
            url: '/shop/wishlist',
        },
        {
            type: 'link',
            label: 'Partnership',
            url: '/site/partnership',
        },
        {
            type: 'link',
            label: 'Account',
            url: '/account',
            children: accountMenu,
        },
        {
            type: 'button',
            label: 'Language',
            icons: [
                'images/languages/language-3.png',
                'images/languages/language-1.png',
                'images/languages/language-2.png',
            ],
            children: [
                { type: 'button', label: 'Armenian', data: { type: 'language', locale: 'am' } },
                { type: 'button', label: 'English', data: { type: 'language', locale: 'en' } },
                { type: 'button', label: 'Russian', data: { type: 'language', locale: 'ru' } },
            ],
        },
    ];
}

const mapStateToProps = ({ mobileMenu, locale, categoriesMenu, isAuthenticated, loaders: { isConfigsLoaded } }) => ({
    mobileMenuState: mobileMenu,
    isAuthenticated,
    categoriesMenu,
    locale,
    isConfigsLoaded,
});

const mapDispatchToProps = {
    closeMobileMenu: mobileMenuClose,
    changeLocale: changeLocale,
    changeCurrency: currencyChange,
    signOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MobileMenu));
