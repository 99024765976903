// react
import React from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';

// application
import { FormattedMessage } from 'react-intl';
import { tempQuickViewOpen } from '../../../../redux/ducks/tempQuickViewDucks';
import { IMAGE_URL } from '../../../config/general';
import Currency from '../../../shared/Currency';

function TempSuggestions({ context, className, products, ...redux }) {
    const { tempQuickViewOpen, locale } = redux;
    const rootClasses = classNames(`suggestions suggestions--location--${context}`, className);

    const list = !!products.length ? (
        products.map((product) => (
            <li key={product.id} className="suggestions__item">
                <div className="suggestions__item-image">
                    <img src={IMAGE_URL + product.avatar} alt="" />
                </div>
                <div className="suggestions__item-info" onClick={() => tempQuickViewOpen(product)}>
                    <div className="suggestions__item-name">{product[`title_${locale}`]}</div>
                    <div className="suggestions__item-meta">
                        <FormattedMessage id="SKU" />: {product.article_id}
                    </div>
                </div>
                <div className="suggestions__item-price">
                    <Currency value={product.price} />
                </div>
            </li>
        ))
    ) : (
        <li className="suggestions__item">
            <div className="suggestions__item-info">No such product</div>
        </li>
    );

    return (
        <div className={rootClasses}>
            <ul className="suggestions__list">{list}</ul>
        </div>
    );
}

const mapStateToProps = ({ locale }) => ({ locale });

const mapDispatchToProps = {
    tempQuickViewOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(TempSuggestions);
