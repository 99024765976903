// react
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AppLink from '../shared/AppLink';
import { FormattedMessage } from 'react-intl';
const headerNavigation = [
    {
        title: <FormattedMessage id="Shop" />,
        url: '/shop/opt/products',
    },
    {
        title: <FormattedMessage id="SaleHeader" />,
        url: '/shop/opt/sale',
    },
    {
        title: <FormattedMessage id="New Products" />,
        url: '/shop/opt/new',
    },
    // {
    //     title: <FormattedMessage id="Gifts" />,
    //     url: '/gifts',
    // },
];

function NavLinks({ locale }) {
    const handleMouseEnter = (event) => {
        const item = event.currentTarget;
        const megamenu = item.querySelector('.nav-links__megamenu');

        if (megamenu) {
            const container = megamenu.offsetParent;
            const containerWidth = container.getBoundingClientRect().width;
            const megamenuWidth = megamenu.getBoundingClientRect().width;
            const itemOffsetLeft = item.offsetLeft;

            const megamenuPosition = Math.round(Math.min(itemOffsetLeft, containerWidth - megamenuWidth));

            megamenu.style.right = '';
            megamenu.style.left = `${megamenuPosition}px`;
        }
    };

    return (
        <ul className="nav-links__list">
            {headerNavigation.map((item, index) => {
                return (
                    <li key={index} className={classNames('nav-links__item')} onMouseEnter={handleMouseEnter}>
                        <AppLink to={item.url} {...item.props}>
                            <span>{item.title}</span>
                        </AppLink>
                    </li>
                );
            })}
        </ul>
    );
}

NavLinks.propTypes = {
    /** current locale */
    locale: PropTypes.string,
};

const mapStateToProps = ({ locale }) => ({
    locale,
});

export default connect(mapStateToProps)(NavLinks);
