import { FormattedMessage } from 'react-intl';
import React from 'react';

export const SHOP_ALL_CATEGORIES_ID = 1;

export const TOAST_MESSAGES = {
    reviewSuccess: <FormattedMessage id="Thank you for your review" />,
    updateCartSuccess: <FormattedMessage id="Products are successfully updated!" />,
    cartAddOrderSuccess: <FormattedMessage id="Order add to cart" />,
    changeSuccess: <FormattedMessage id="Successfully changed" />,
    subscribeSuccess: <FormattedMessage id="Successfully subscribed" />,
    wishlistAddSuccess: (productName) => <FormattedMessage id="wishlistAddSuccess" values={{ productName }} />,
    wishlistRemoveSuccess: (productName) => <FormattedMessage id="wishlistRemoveSuccess" values={{ productName }} />,
    cartAddSuccess: (productName, quantity) => (
        <FormattedMessage id="cartAddSuccess" values={{ productName, quantity }} />
    ),
    cartRemoveSuccess: (productName) => <FormattedMessage id="cartRemoveSuccess" values={{ productName }} />,
    checkoutFail: (amount) => <FormattedMessage id="checkoutFail" values={{ amount }} />,
    cartUpdateFail: (productName, quantity) => (
        <FormattedMessage id="cartUpdateFail" values={{ productName, quantity }} />
    ),
};
