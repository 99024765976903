import axios from 'axios';
import { GLOBAL_URL } from '../../components/config/general';
import { createAction, createReducer } from '../../helpers/redux';
import { setFilterCategories } from './filtersDucks';
import { setCartDefaultProps } from './cartDucks';
import { setIsConfigsLoaded } from './loadersDucks';

const SET_CATEGORIES = 'SET_CATEGORIES';
const SET_CATEGORIES_MENU = 'SET_CATEGORIES_MENU';
const SET_BANNERS = 'SET_BANNERS';
const SET_CONFIGS = 'SET_CONFIGS';

const setCategories = createAction(SET_CATEGORIES);
const setCategoriesMenu = createAction(SET_CATEGORIES_MENU);
const setBanners = createAction(SET_BANNERS);
const setConfigs = createAction(SET_CONFIGS);

export function getConfigs() {
    return (dispatch) => {
        dispatch(setIsConfigsLoaded(false));
        return axios
            .post(GLOBAL_URL + 'configs/get')
            .then((res) => {
                const { categories, categoriesMenu, banners, configs } = res.data.data;
                dispatch(setFilterCategories(categories));
                dispatch(setCategories(categories));
                dispatch(setCategoriesMenu(categoriesMenu));
                dispatch(setBanners(banners));
                dispatch(setConfigs(configs));
                const { shippingAmount, bonusAmount, shippingOptions } = configs;
                dispatch(
                    setCartDefaultProps({
                        defaultShippingAmount: shippingAmount,
                        defaultBonusAmount: bonusAmount,
                        defaultShippingOptions: shippingOptions,
                    })
                );
                dispatch(setIsConfigsLoaded(true));
            })
            .catch((e) => console.log(e));
    };
}

export const categories = createReducer([], (state, { value }) => ({
    [SET_CATEGORIES]: () => value,
}));

export const categoriesMenu = createReducer([], (state, { value }) => ({
    [SET_CATEGORIES_MENU]: () => value,
}));

export const banners = createReducer([], (state, { value }) => ({
    [SET_BANNERS]: () => value,
}));

export const configs = createReducer({}, (state, { value }) => ({
    [SET_CONFIGS]: () => value,
}));
