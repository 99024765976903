import classes from './SubCategoryCard.module.css';
import React from 'react';

export default function SubCategoryCard({ url, title }) {
    return (
        <div className={classes.container}>
            <div style={{ backgroundImage: `url(${url})` }} className={classes.backgroundImage}></div>
            <div className={classes.textContainer}>
                <span className={classes.text}>{title}</span>
            </div>
        </div>
    );
}
