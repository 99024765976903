// react
import React from 'react';

// third-party
import { connect } from 'react-redux';

// application
import Indicator from '../Indicator';
import { Person20Svg } from '../../../svg';
import UserDetails from './components/UserDetails';
import UserLogin from './components/UserLogin';

function IndicatorAccount({ isAuthenticated }) {
    const dropdown = <div className="account-menu">{isAuthenticated ? <UserDetails /> : <UserLogin />}</div>;

    return <Indicator url="/account" dropdown={dropdown} icon={<Person20Svg />} />;
}

const mapStateToProps = ({ isAuthenticated }) => ({
    isAuthenticated,
});

export default connect(mapStateToProps)(IndicatorAccount);
