// react
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// third-party
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import Collapse from '../shared/Collapse';
import Currency from '../shared/Currency';
import PageHeader from '../shared/PageHeader';
import { Check9x7Svg } from '../../svg';

// data stubs
import paymentList from '../../data/shopPayments';
import axios from 'axios';
import { GLOBAL_URL } from '../config/general';
import Cookies from 'js-cookie';
import FormAddress from '../shared/FormAddress';
import { useFormik } from 'formik';
import { deleteCart } from '../../redux/ducks/cartDucks';
import UnipackHelmet from '../shared/UnipackHelmet';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { setUserAddress } from '../../redux/ducks/userDucks';
import { TOAST_MESSAGES } from '../../constants/general';
import { setIsOrderPlaced } from '../../redux/ducks/loadersDucks';

function CheckoutPage(redux) {
    const {
        userAddress,
        cart,
        locale,
        deleteCart,
        currency,
        setUserAddress,
        setIsOrderPlaced,
        shippingOptions,
    } = redux;
    const [currentPayment, setCurrentPayment] = useState('cash');
    const [notes, setNotes] = useState('');
    const [needToSaveAddress, setNeedToSaveAddress] = useState(false);
    const [addressErrors, setAddressErrors] = useState([]);
    const history = useHistory();

    useEffect(() => {
        return () => setIsOrderPlaced(true);
    }, []);

    useEffect(() => {
        if (!!cart && !!shippingOptions && cart.products.length && cart.totalAmount < shippingOptions.min) {
            toast.error(TOAST_MESSAGES.checkoutFail(shippingOptions.min + currency.symbol));
            history.push('/');
        }
        if (cart?.loaded && cart.products.length === 0) {
            history.push('/');
        }
    }, [cart, shippingOptions]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: userAddress || {
            first_name: '',
            last_name: '',
            country: '',
            streetAddress: '',
            apt: '',
            city: '',
            postCode: '',
            phone: '',
        },
        onSubmit: (values) => {
            handlePlaceOrder(values);
        },
    });

    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'Shopping Cart', url: '/shop/cart' },
        {
            title: 'Checkout',
            url: '',
        },
    ];

    return (
        <>
            <UnipackHelmet title="Checkout" />

            <PageHeader header="Checkout" breadcrumb={breadcrumb} />

            <div className="checkout block">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-6 col-xl-7">
                            <div className="card mb-lg-0">
                                <div className="card-body">
                                    <h3 className="card-title">
                                        <FormattedMessage id="Shipping Details" />
                                    </h3>
                                    <FormAddress errors={addressErrors} formik={formik} />
                                    <div className="form-group">
                                        <div className="form-check">
                                            <span className="form-check-input input-check">
                                                <span className="input-check__body">
                                                    <input
                                                        className="input-check__input"
                                                        type="checkbox"
                                                        id="checkout-create-account"
                                                        onChange={handleSaveAddressChange}
                                                    />
                                                    <span className="input-check__box" />
                                                    <Check9x7Svg className="input-check__icon" />
                                                </span>
                                            </span>
                                            <label className="form-check-label" htmlFor="checkout-create-account">
                                                <FormattedMessage id="Save address" />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-divider" />
                                <div className="card-body">
                                    <div className="form-group">
                                        <label htmlFor="checkout-comment">
                                            <FormattedMessage id="Order notes" />{' '}
                                            <span className="text-muted">
                                                (<FormattedMessage id="Optional" />)
                                            </span>
                                        </label>
                                        <textarea
                                            id="checkout-comment"
                                            className="form-control"
                                            rows="4"
                                            value={notes}
                                            onChange={handleNotesChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-6 col-xl-5 mt-4 mt-lg-0">
                            <div className="card mb-0">
                                <div className="card-body">
                                    <h3 className="card-title">
                                        <FormattedMessage id="Your Order" />
                                    </h3>

                                    {renderCart()}

                                    {renderPaymentList()}

                                    <div className="checkout__agree form-group">
                                        <div className="form-check">
                                            <span className="form-check-input input-check">
                                                <span className="input-check__body">
                                                    <input
                                                        className="input-check__input"
                                                        type="checkbox"
                                                        id="checkout-terms"
                                                    />
                                                    <span className="input-check__box" />
                                                    <Check9x7Svg className="input-check__icon" />
                                                </span>
                                            </span>
                                            <label className="form-check-label" htmlFor="checkout-terms">
                                                <FormattedMessage id="I have read and agree to the website" />{' '}
                                                <Link to="/site/privacy-policy">
                                                    <FormattedMessage id="terms and conditions" />
                                                </Link>
                                                *
                                            </label>
                                        </div>
                                    </div>

                                    <button className="btn btn-primary btn-xl btn-block" onClick={formik.submitForm}>
                                        <FormattedMessage id="Place Order" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

    function handleSaveAddressChange(e) {
        setNeedToSaveAddress(e.target.checked);
    }

    function handleNotesChange(e) {
        setNotes(e.target.value);
    }

    function handlePaymentChange(e) {
        if (e.target.checked) {
            setCurrentPayment(e.target.value);
        }
    }

    function renderCart() {
        const productList = cart.products.map((product) => {
            const { discounted_price, price } = product;
            const productPrice = !!Number(discounted_price) ? discounted_price : price;

            return (
                <tr key={product.id}>
                    <td>{`${product[`title_${locale}`]} × ${product.product_count}`}</td>
                    <td>
                        <Currency value={product.product_count * productPrice} />
                        {!!Number(discounted_price) && <Currency value={price} isDiscounted={true} classNames={['small']} />}
                    </td>
                </tr>
            );
        });

        return (
            <table className="checkout__totals">
                <thead className="checkout__totals-header">
                    <tr>
                        <th>
                            <FormattedMessage id="Product" />
                        </th>
                        <th>
                            <FormattedMessage id="Total" />
                        </th>
                    </tr>
                </thead>
                <tbody className="checkout__totals-products">{productList}</tbody>
                <>
                    <tbody className="checkout__totals-subtotals">
                        <tr>
                            <th>
                                <FormattedMessage id="Subtotal" />
                            </th>
                            <td>
                                <Currency value={cart.subTotal} />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <FormattedMessage id="Shipping" />
                            </th>
                            <td>
                                <Currency value={cart.shippingAmount} />
                            </td>
                        </tr>
                        <tr className="color-discount">
                            <th className="order-list__column-label">
                                <FormattedMessage id="Discount" />
                            </th>
                            <td>
                                <Currency value={cart.discount} />
                            </td>
                        </tr>
                        {/*<tr className="color-bonus">*/}
                        {/*    <th className="order-list__column-label">*/}
                        {/*        <FormattedMessage id="Bonus" />*/}
                        {/*    </th>*/}
                        {/*    <td className="order-list__column-total">{cart.bonus}</td>*/}
                        {/*</tr>*/}
                    </tbody>
                </>
                <tfoot className="checkout__totals-footer">
                    <tr>
                        <th>
                            <FormattedMessage id="Total" />
                        </th>
                        <td>
                            <Currency value={cart.totalAmount} />
                        </td>
                    </tr>
                </tfoot>
            </table>
        );
    }

    function renderPaymentList() {
        const payments = paymentList.map((payment) => {
            const renderPayment = ({ setItemRef, setContentRef }) => (
                <li className="payment-methods__item" ref={setItemRef}>
                    <label className="payment-methods__item-header">
                        <span className="payment-methods__item-radio input-radio">
                            <span className="input-radio__body">
                                <input
                                    type="radio"
                                    className="input-radio__input"
                                    name="checkout_payment_method"
                                    value={payment.key}
                                    checked={currentPayment === payment.key}
                                    onChange={handlePaymentChange}
                                />
                                <span className="input-radio__circle" />
                            </span>
                        </span>
                        <span className="payment-methods__item-title">{<FormattedMessage id={payment.title} />}</span>
                    </label>
                    <div className="payment-methods__item-container" ref={setContentRef}>
                        <div className="payment-methods__item-description text-muted">{payment.description}</div>
                    </div>
                </li>
            );

            return (
                <Collapse
                    key={payment.key}
                    open={currentPayment === payment.key}
                    toggleClass="payment-methods__item--active"
                    render={renderPayment}
                />
            );
        });

        return (
            <div className="payment-methods">
                <ul className="payment-methods__list">{payments}</ul>
            </div>
        );
    }

    function handlePlaceOrder(values) {
        setIsOrderPlaced(false);
        axios
            .post(
                GLOBAL_URL + 'order/placeOrder',
                { ...values, shippingSave: needToSaveAddress, orderType: 1, notes },
                {
                    headers: {
                        authorization: Cookies.get('token'),
                    },
                }
            )
            .then((res) => {
                const { data, error, message } = res.data;
                if (!error) {
                    if (needToSaveAddress) {
                        setUserAddress(data.shipping_details);
                    }
                    deleteCart();
                    history.push({
                        pathname: '/shop/checkout/success',
                        state: { order: data },
                    });
                } else {
                    if (message !== 'Validation') {
                        toast.error(message);
                    } else {
                        setAddressErrors(data);
                    }
                }
                console.log(data);
                setIsOrderPlaced(true);
            })
            .catch((e) => console.log(e));
    }
}

const mapStateToProps = ({ cart, user, locale, currency, configs: { shippingOptions } }) => ({
    cart,
    shippingOptions,
    userAddress: user.shipping_details,
    locale,
    currency,
});

const mapDispatchToProps = { deleteCart, setUserAddress, setIsOrderPlaced };

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPage);
