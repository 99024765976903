import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

function AddressCard({ address, editable = true, headerText, cardWidth }) {
    const { country, streetAddress, city, postCode, first_name, last_name, phone } = address;

    return (
        <div className={`dashboard__address card address-card address-card--featured ${cardWidth}`}>
            {address.default && (
                <div className="address-card__badge">
                    <FormattedMessage id="Address" />
                </div>
            )}
            <div className="address-card__body">
                {!!headerText && (
                    <div className="address-card__badge address-card__badge--muted">
                        {<FormattedMessage id={headerText} />}
                    </div>
                )}
                <div className="address-card__name">{`${first_name} ${last_name}`}</div>
                <div className="address-card__row">
                    {country}
                    <br />
                    {!!postCode && `${postCode},`}
                    {city}
                    <br />
                    {streetAddress}
                </div>
                <div className="address-card__row">
                    <div className="address-card__row-title">
                        <FormattedMessage id="Phone Number" />
                    </div>
                    <div className="address-card__row-content">{phone}</div>
                </div>
                {editable && (
                    <div className="address-card__footer">
                        <Link to="/account/edit-address">
                            <FormattedMessage id="Edit Address" />
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
}

export default AddressCard;
