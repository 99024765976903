import { useEffect } from 'react';

const useOnUnmount = (fn, args) => {
    useEffect(() => {
        return () => {
            fn(args)
        }
    }, []);
};

export default useOnUnmount;
