import { createAction, createReducer } from '../../helpers/redux';
import axios from 'axios';
import { GLOBAL_URL } from '../../components/config/general';

const SET_HOME_PAGE_PRODUCTS = 'SET_HOME_PAGE_PRODUCTS';
export const setHomePageProducts = createAction(SET_HOME_PAGE_PRODUCTS);

export function getHomePageProducts() {
    return (dispatch) => {
        axios
            .post(GLOBAL_URL + 'products/getHomeProducts')
            .then((res) => dispatch(setHomePageProducts(res.data.data)))
            .catch((e) => console.log(e));
    };
}

const initialState = {
    is_featured: [],
    is_new: [],
    is_sale: [],
};

export const homePageProducts = createReducer(initialState, (state, { value }) => ({
    [SET_HOME_PAGE_PRODUCTS]: () => value,
}));
