import classes from './CategoryItem.module.css';
import React, { useEffect, useRef } from 'react';

export default function CategoryItem({ title, moveCenter, onClick, isActive, id }) {
    const elemRef = useRef(null);
    const className = isActive ? `${classes.container} ${classes.active}` : classes.container;
    useEffect(() => {
        if (isActive) {
            const elem = elemRef.current;
            moveCenter(elem);
        }
    }, [isActive]);

    return (
        <div ref={elemRef} className={className} onClick={onClick} data-id={`top_category_${id}`}>
            <div>
                <span>{title}</span>
            </div>
        </div>
    );
}
