import { createAction, createReducer } from '../../helpers/redux';
import { getLocalLanguage, setLocalLanguage } from '../../helpers/general';

export const CHANGE_LOCALE = 'LOCALE_CHANGE';

export const changeLocale = createAction(CHANGE_LOCALE);

const initialState = getLocalLanguage() || 'am';

export const locale = createReducer(initialState, (state, { value }) => ({
    [CHANGE_LOCALE]: () => {
        setLocalLanguage(value);
        return value;
    },
}));
