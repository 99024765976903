// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import Indicator from '../../../../header/Indicator';
import { LogoSmallSvg, Search20Svg } from '../../../../../svg';
import { mobileMenuOpen } from '../../../../../redux/ducks/mobileMenuDucks';
import SideMenuButton from './languageButton/SideMenuButton';
import TempSearch from '../../search/TempSearch';

class MobileHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchOpen: false,
        };
        this.searchInput = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        const { searchOpen } = this.state;

        if (searchOpen && searchOpen !== prevState.searchOpen && this.searchInput.current) {
            this.searchInput.current.focus();
        }
    }

    handleOpenSearch = () => {
        this.setState(() => ({ searchOpen: true }));
    };

    handleCloseSearch = () => {
        this.setState(() => ({ searchOpen: false }));
    };

    render() {
        const { openMobileMenu, sectionNumber } = this.props;
        const { searchOpen } = this.state;
        const searchClasses = classNames('mobile-header__search', {
            'mobile-header__search--open': searchOpen,
        });
        return (
            <div className="mobile-header">
                <div className="mobile-header__panel">
                    <div className="container">
                        <div className="mobile-header__body">
                            <Link className="mobile-header__logo" to={`/menu/${sectionNumber}/categories`}>
                                <div>
                                    <LogoSmallSvg />
                                </div>
                            </Link>
                            <TempSearch
                                context="mobile-header"
                                className={searchClasses}
                                inputRef={this.searchInput}
                                onClose={this.handleCloseSearch}
                            />
                            <div className="mobile-header__indicators">
                                <Indicator
                                    className="indicator--mobile indicator--mobile-search d-md-none"
                                    onClick={this.handleOpenSearch}
                                    icon={<Search20Svg />}
                                />
                                <SideMenuButton onClick={openMobileMenu} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    openMobileMenu: mobileMenuOpen,
};

export default connect(null, mapDispatchToProps)(MobileHeader);
