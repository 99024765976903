import React from 'react';
import { setClass } from '../../../../helpers/general';

function DangerAlert({ isVisible, message }) {
    return (
        <div className={`alert alert-danger ${setClass(!isVisible, 'invisible')}`} role="alert">
            {message}
        </div>
    );
}

export default DangerAlert;
