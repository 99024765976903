import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { compose } from 'redux';

const PrivateRoute = ({ children: Component, isAuthenticated, loaders, ...rest }) => {
    useEffect(() => {}, [isAuthenticated]);
    return (
        <Route
            {...rest}
            render={(props) => {
                return isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    loaders.isUserLoaded && (
                        <Redirect
                            to={{
                                pathname: '/account/login',
                                state: { from: props.location },
                            }}
                        />
                    )
                );
            }}
        />
    );
};

const mapStateToProps = ({ isAuthenticated, loaders }) => ({
    isAuthenticated,
    loaders,
});

export default compose(withRouter, connect(mapStateToProps, null))(PrivateRoute);
