// react
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// application
import Pagination from '../../shared/Pagination';
import ProductCard from '../../shared/ProductCard';
import { Filters16Svg, LayoutGrid16x16Svg, LayoutList16x16Svg } from '../../../svg';
import { sidebarOpen } from '../../../redux/ducks/sidebarDucks';
import { FormattedMessage, useIntl } from 'react-intl';

let viewModes = [
    { key: 'grid', title: 'Grid', icon: <LayoutGrid16x16Svg /> },
    { key: 'list', title: 'List', icon: <LayoutList16x16Svg /> },
];

function ProductsView({
    layout: propsLayout,
    grid,
    offcanvas,
    shopProductsData,
    viewLimit,
    page,
    sortValue,
    onSortByDropdown,
    onViewLimitDropdown,
    onPageChange,
    totalPages,
    ...redux
}) {
    const { sidebarOpen } = redux;
    const [layout, setLayout] = useState(propsLayout);
    const intl = useIntl();
    const t = (text) => intl.formatMessage({ id: text });

    return (
        <div className="products-view">
            <div className="products-view__options">
                <div
                    className={classNames('view-options', {
                        'view-options--offcanvas--always': offcanvas === 'always',
                        'view-options--offcanvas--mobile': offcanvas === 'mobile',
                    })}
                >
                    <div className="view-options__filters-button">
                        <button type="button" className="filters-button" onClick={sidebarOpen}>
                            <Filters16Svg className="filters-button__icon" />
                            <span className="filters-button__title">
                                {' '}
                                <FormattedMessage id="Filters" />
                            </span>
                            <span className="filters-button__counter">3</span>
                        </button>
                    </div>
                    <div className="view-options__layout">
                        <div className="layout-switcher">
                            {!isMobile && (
                                <div className="layout-switcher__list">
                                    {viewModes.map((viewMode) => {
                                        const className = classNames('layout-switcher__button', {
                                            'layout-switcher__button--active': layout === viewMode.key,
                                        });

                                        return (
                                            <button
                                                key={viewMode.key}
                                                title={viewMode.title}
                                                type="button"
                                                className={className}
                                                onClick={() => setLayout(viewMode.key)}
                                            >
                                                {viewMode.icon}
                                            </button>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="view-options__legend">
                        <FormattedMessage
                            id="Showing {count} of {allCount} products"
                            defaultMessage="Showing {count} of {allCount} products"
                            values={{ count: shopProductsData.products.length, allCount: shopProductsData.count }}
                        />
                    </div>
                    <div className="view-options__divider" />
                    <div className="view-options__control">
                        <label htmlFor="view-options-sort">
                            <FormattedMessage id="Sort By" />
                        </label>
                        <div>
                            <select
                                className="form-control form-control-sm"
                                name=""
                                id="view-options-sort"
                                value={sortValue}
                                onChange={onSortByDropdown}
                            >
                                <optgroup>
                                    <option value="">{t('Default')}</option>
                                </optgroup>
                                <optgroup label={t('name')}>
                                    <option value="title-ASC">
                                        {t('Name')} ({t('A-Z')})
                                    </option>
                                    <option value="title-DESC">
                                        {t('Name')} ({t('Z-A')})
                                    </option>
                                </optgroup>
                                <optgroup label={t('price')}>
                                    <option value="price-ASC">
                                        {t('Price')} ({t('Min-Max')})
                                    </option>
                                    <option value="price-DESC">
                                        {t('Price')} ({t('Max-Min')})
                                    </option>
                                </optgroup>
                                <optgroup label={t('rating')}>
                                    <option value="rate-ASC">
                                        {t('Rating')} ({t('Min-Max')})
                                    </option>
                                    <option value="rate-DESC">
                                        {t('Rating')} ({t('Max-Min')})
                                    </option>
                                </optgroup>
                            </select>
                        </div>
                    </div>
                    <div className="view-options__control">
                        <label htmlFor="view-options-limit">
                            <FormattedMessage id="Show" />
                        </label>
                        <div>
                            <select
                                value={viewLimit}
                                className="form-control form-control-sm"
                                name=""
                                id="view-options-limit"
                                onChange={onViewLimitDropdown}
                            >
                                <option value="12">12</option>
                                <option value="24">24</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="products-view__list products-list"
                data-layout={layout !== 'list' ? grid : layout}
                data-with-features={layout === 'grid-with-features' ? 'true' : 'false'}
            >
                <div className="products-list__body">
                    {shopProductsData.products.map((product) => (
                        <div key={product.id} className="products-list__item">
                            <ProductCard product={product} />
                        </div>
                    ))}
                </div>
            </div>

            {!!shopProductsData.products.length && (
                <div className="products-view__pagination">
                    <Pagination current={page} siblings={2} total={totalPages} onPageChange={onPageChange} />
                </div>
            )}
        </div>
    );
}

ProductsView.propTypes = {
    /**
     * array of product objects
     */
    products: PropTypes.array,
    /**
     * products list layout (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    layout: PropTypes.oneOf(['grid', 'grid-with-features', 'list']),
    /**
     * products list layout (default: 'grid')
     * one of ['grid-3-sidebar', 'grid-4-full', 'grid-5-full']
     */
    grid: PropTypes.oneOf(['grid-3-sidebar', 'grid-4-full', 'grid-5-full']),
    /**
     * indicates when sidebar bar should be off canvas
     */
    offcanvas: PropTypes.oneOf(['always', 'mobile']),
};

ProductsView.defaultProps = {
    products: [],
    layout: 'grid',
    grid: 'grid-3-sidebar',
    offcanvas: 'mobile',
};

const mapDispatchToProps = {
    sidebarOpen,
};

export default connect(null, mapDispatchToProps)(ProductsView);
