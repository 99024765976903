// react
import React from 'react';

// third-party
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// application
import Dropdown from './Dropdown';
import DropdownLanguage from './DropdownLanguage';
import { signOut } from '../../redux/ducks/userDucks';
import useRedirectOnClick from '../hooks/useRedirectOnClick';

const links = [
    { title: <FormattedMessage id="About Us" defaultMessage="About Us" />, url: '/site/about-us' },
    { title: <FormattedMessage id="Contacts" defaultMessage="Contacts" />, url: '/site/contact-us' },
    { title: <FormattedMessage id="Partnership" defaultMessage="Partnership" />, url: '/site/partnership' },
];

const linksList = links.map((item, index) => (
    <div key={index} className="topbar__item topbar__item--link">
        <Link className="topbar-link" to={item.url}>
            {item.title}
        </Link>
    </div>
));

function Topbar({ signOut, isAuthenticated }) {
    const handleLogoutClick = useRedirectOnClick(signOut, '/account/login');

    const accountLinks = [
        { title: <FormattedMessage id="Profile" />, url: '/account/profile' },
        { title: <FormattedMessage id="Edit Profile" />, url: '/account/edit-profile' },
        { title: <FormattedMessage id="Order History" />, url: '/account/orders' },
        { title: <FormattedMessage id="Password" />, url: '/account/password' },
        { title: <FormattedMessage id="Logout" />, onClick: handleLogoutClick },
    ];

    return (
        <div className="site-header__topbar topbar">
            <div className="topbar__container container">
                <div className="topbar__row">
                    {linksList}
                    <div className="topbar__spring" />
                    {isAuthenticated && (
                        <div className="topbar__item">
                            <Dropdown
                                title={<FormattedMessage id="My Account" defaultMessage="My Account" />}
                                items={accountLinks}
                            />
                        </div>
                    )}
                    <div className="topbar__item">
                        <DropdownLanguage />
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({ isAuthenticated }) => ({
    isAuthenticated,
});

const mapDispatchToProps = {
    signOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
