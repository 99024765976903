import React from 'react';
import { useFormik } from 'formik';
import FormContainer from './FormContainer';
import FormInput from '../../../shared/FormInput';
import { signUp } from '../../../../redux/ducks/userDucks';
import { connect } from 'react-redux';
import useRedirectOnClick from '../../../hooks/useRedirectOnClick';
import useFormWithErrors from '../../../hooks/useFormWithErrors';
import { useAreFieldsEmpty } from '../../../hooks/useAreFieldsEmpty';
import { FormattedMessage } from 'react-intl';
import DangerAlert from './DangerAlert';
import useOnUnmount from '../../../hooks/useOnUnmount';
import {
    resetCustomError,
    resetRegistrationValidationErrors,
} from '../../../../redux/ducks/validationDucks';

const registerInputsData = [
    { label: 'Email address', type: 'email', placeholder: 'Enter email', id: 'email', error: false, message: '' },
    { label: 'First name', type: 'text', placeholder: 'First name', id: 'first_name', error: false, message: '' },
    { label: 'Last name', type: 'text', placeholder: 'Last name', id: 'last_name', error: false, message: '' },
    { label: 'Phone number', type: 'tel', placeholder: 'Phone number', id: 'phone', error: false, message: '' },
    { label: 'Password', type: 'password', placeholder: 'Password', id: 'password', error: false, message: '' },
    {
        label: 'Repeat Password',
        type: 'password',
        placeholder: 'Password',
        id: 'confirm_password',
        error: false,
        message: '',
    },
];

function RegisterForm({ locale, registrationErrors, customErrorMessage, signUp, resetRegistrationValidationErrors, resetCustomError }) {
    const handleRegister = useRedirectOnClick(signUp, '/');
    const formik = useFormik({
        initialValues: {
            email: '',
            first_name: '',
            last_name: '',
            phone: '',
            password: '',
            confirm_password: '',
        },
        onSubmit: (values) => {
            handleRegister({ ...values, locale });
        },
    });

    useOnUnmount(() => {
        resetCustomError()
        resetRegistrationValidationErrors();
    });

    const formInputData = useFormWithErrors(registerInputsData, registrationErrors);

    const areFieldsEmpty = useAreFieldsEmpty(formik.values);

    return (
        <FormContainer title={'Register'}>
            <form onSubmit={formik.handleSubmit}>
                {formInputData.map(({ id, label, placeholder, type, error, message }) => (
                    <FormInput
                        {...{
                            id,
                            label,
                            placeholder,
                            type,
                            error,
                            message,
                            onChange: formik.handleChange,
                            value: formik.values[id],
                            key: id,
                        }}
                    />
                ))}
                <DangerAlert isVisible={!!customErrorMessage} message={customErrorMessage} />
                <button type="submit" className="btn btn-primary mt-2 mt-md-3 mt-lg-4" disabled={areFieldsEmpty}>
                    <FormattedMessage id="Register" />
                </button>
            </form>
        </FormContainer>
    );
}

const mapStateToProps = ({ locale, registrationErrors, customErrorMessage }) => ({ locale, registrationErrors, customErrorMessage });

const mapDispatchToProps = { signUp, resetRegistrationValidationErrors, resetCustomError };

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
