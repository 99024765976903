// react
import React, { useEffect, useState } from 'react';

// third-party
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// data stubs
import UserCard from './components/UserCard';
import AddressCard from './components/AddressCard';
import NewAddressCard from './components/NewAddressCard';
import { getDateFromIso } from '../../../helpers/general';
import Currency from '../../shared/Currency';
import { ORDER_STATUS_NAMES } from '../../../constants/names';
import { getOrderHistory } from '../../../helpers/api';
import UnipackHelmet from '../../shared/UnipackHelmet';
import { FormattedMessage, useIntl } from 'react-intl';
import { setIsOrderHistoryLoaded } from '../../../redux/ducks/loadersDucks';
import FetchLoader from '../../shared/FetchLoader';
import { Cart20Svg } from '../../../svg';
import { cartAddOrder } from '../../../redux/ducks/cartDucks';
import CompanyCard from './components/CompanyCard';

function AccountPageProfile({ user, ...redux }) {
    const { setIsOrderHistoryLoaded, isOrderHistoryLoaded, cartAddOrder, userType, companyName } = redux;
    const { first_name, last_name, email, bonus } = user;
    const [ordersData, setOrdersData] = useState({ count: 0, orders: [] });
    const intl = useIntl();

    useEffect(() => {
        getOrderHistory(3, 1).then((res) => {
            setOrdersData(res);
            setIsOrderHistoryLoaded(true);
        });

        return () => setIsOrderHistoryLoaded(false);
    }, []);

    return (
        <div className="dashboard">
            <UnipackHelmet title="My Account" />
            {userType === 1 ? (
                <UserCard first_name={first_name} email={email} last_name={last_name} bonus={bonus}  />
            ) : (
                <CompanyCard companyName={companyName} email={email} bonus={bonus} />
            )}
            {!!user.shipping_details ? <AddressCard address={user.shipping_details} /> : <NewAddressCard />}
            <div className="dashboard__orders card">
                <FetchLoader loaded={isOrderHistoryLoaded}>
                    {ordersData.orders.length ? (
                        <>
                            <div className="card-header">
                                <h5>
                                    <FormattedMessage id="Recent Orders" />
                                </h5>
                            </div>
                            <div className="card-divider" />
                            <div className="card-table">
                                <div className="table-responsive-sm">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <FormattedMessage id="Order" />
                                                </th>
                                                <th>
                                                    <FormattedMessage id="Date" />
                                                </th>
                                                <th>
                                                    <FormattedMessage id="Status" />
                                                </th>
                                                <th>
                                                    <FormattedMessage id="Total" />
                                                </th>
                                                <th>{'    '}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ordersData.orders.map((order) => {
                                                const date = getDateFromIso(order.created_at);
                                                const dateText = `${date.day} ${intl.formatMessage({
                                                    id: date.monthText,
                                                })} ${date.year}`;
                                                return (
                                                    <tr key={order.id}>
                                                        <td>
                                                            <Link
                                                                to={`/account/orders/${order.order_id}`}
                                                            >{`#${order.order_id}`}</Link>
                                                        </td>
                                                        <td>{dateText}</td>
                                                        <td>
                                                            {
                                                                <FormattedMessage
                                                                    id={ORDER_STATUS_NAMES[order.order_status]}
                                                                />
                                                            }
                                                        </td>
                                                        <td>
                                                            <Currency value={order.total_amount} />
                                                        </td>
                                                        <td>
                                                            <button
                                                                className="order-history-cart-button"
                                                                onClick={() => onCartButtonClick(order.order_id)}
                                                            >
                                                                <Cart20Svg />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="block-empty__body" style={{ padding: '18px 0 18px' }}>
                            <div className="block-empty__message">
                                <FormattedMessage id="Your order history is empty!" />
                            </div>
                            <div className="block-empty__actions">
                                <Link to="/" className="btn btn-primary btn-sm">
                                    <FormattedMessage id="Continue" />
                                </Link>
                            </div>
                        </div>
                    )}
                </FetchLoader>
            </div>
        </div>
    );

    function onCartButtonClick(orderId) {
        cartAddOrder(orderId);
    }
}

const mapStateToProps = ({
    user: { type: userType, company_name: companyName },
    loaders: { isOrderHistoryLoaded },
}) => ({
    isOrderHistoryLoaded,
    userType,
    companyName,
});

const mapDispatchToProps = { setIsOrderHistoryLoaded, cartAddOrder };

export default connect(mapStateToProps, mapDispatchToProps)(AccountPageProfile);
