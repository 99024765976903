// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';

// application
import departmentsAria from '../../services/departmentsArea';
import StroykaSlick from '../shared/StroykaSlick';
import { IMAGE_URL } from '../config/general';
import SliderPrevArrow from '../shared/SliderPrevArrow';
import SliderNextArrow from '../shared/SliderNextArrow';

const slickSettings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    draggable: false,
    swipe: false,
    pauseOnHover: true,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
};

class BlockSlideShow extends Component {
    departmentsAreaRef = null;

    media = window.matchMedia('(min-width: 992px)');

    componentDidMount() {
        if (this.media.addEventListener) {
            this.media.addEventListener('change', this.onChangeMedia);
        } else {
            this.media.addListener(this.onChangeMedia);
        }
    }

    componentWillUnmount() {
        departmentsAria.area = null;

        if (this.media.removeEventListener) {
            this.media.removeEventListener('change', this.onChangeMedia);
        } else {
            this.media.removeListener(this.onChangeMedia);
        }
    }

    onChangeMedia = () => {
        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    setDepartmentsAreaRef = (ref) => {
        this.departmentsAreaRef = ref;

        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    render() {
        const { withDepartments } = this.props;
        const blockClasses = classNames('block-slideshow block', {
            'block-slideshow--layout--full': !withDepartments,
            'block-slideshow--layout--with-departments': withDepartments,
        });

        const layoutClasses = classNames('col-12', {
            'col-lg-12': !withDepartments,
            'col-lg-9': withDepartments,
        });

        const slides = this.props.slides.map((slide, index) => {
            const mobilePath = slide?.mobile?.path || '';
            const webPath = slide?.web?.path || '';
            const link = slide?.web?.link || slide?.mobile?.link;

            return (
                <a href={link} key={index} className="block-slideshow__slide" target="_blank" rel="noreferrer">
                    <div
                        className="block-slideshow__slide-image block-slideshow__slide-image--desktop"
                        style={{
                            backgroundImage: `url(${IMAGE_URL + webPath})`,
                        }}
                    />
                    <div
                        className="block-slideshow__slide-image block-slideshow__slide-image--mobile"
                        style={{
                            backgroundImage: `url(${IMAGE_URL + mobilePath})`,
                        }}
                    />
                </a>
            );
        });

        return (
            <div className={blockClasses}>
                <div className="container">
                    <div className="row">
                        {withDepartments && (
                            <div className="col-3 d-lg-block d-none" ref={this.setDepartmentsAreaRef} />
                        )}

                        <div className={layoutClasses}>
                            <div className="block-slideshow__body">
                                <StroykaSlick {...slickSettings}>{slides}</StroykaSlick>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

BlockSlideShow.propTypes = {
    withDepartments: PropTypes.bool,
    /** current locale */
    locale: PropTypes.string,
};

BlockSlideShow.defaultProps = {
    withDepartments: false,
};

export default BlockSlideShow;
