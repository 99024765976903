import React from 'react';
import InputMask from 'react-input-mask';
import { setClass } from '../../helpers/general';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';

function FormInput({
    label,
    type,
    placeholder,
    id,
    value,
    onChange,
    children,
    message = '',
    error,
    inputClass = '',
    wrapperClass,
    isOptional,
    disabled,
}) {
    const intl = useIntl();
    let translatedPlaceholder = '';
    if (!!placeholder) {
        translatedPlaceholder = intl.formatMessage({ id: placeholder });
    }
    return (
        <div className={`form-group ${wrapperClass}`}>
            <label>
                {<FormattedMessage id={label} />}{' '}
                {isOptional && (
                    <span className="text-muted">
                        (<FormattedMessage id="Optional" />)
                    </span>
                )}
            </label>
            {id !== 'phone' ? (
                <input
                    id={id}
                    type={type}
                    className={`form-control ${inputClass} ${setClass(error, 'is-invalid')}`}
                    placeholder={translatedPlaceholder}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                />
            ) : (
                <InputMask
                    mask="+37499999999"
                    id={id}
                    type={type}
                    className={`form-control ${inputClass} ${setClass(error, 'is-invalid')}`}
                    placeholder={translatedPlaceholder}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                />
            )}
            <div className="invalid-feedback">{message}</div>
            {children}
        </div>
    );
}

export default FormInput;
