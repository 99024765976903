// react
import React, { useEffect, useState } from 'react';

// third-party
import { Link } from 'react-router-dom';

// application
import Currency from '../shared/Currency';
import { Check100Svg } from '../../svg';

// data stubs
import UnipackHelmet from '../shared/UnipackHelmet';
import { getDateFromIso } from '../../helpers/general';
import { connect } from 'react-redux';
import AddressCard from '../account/accountPageProfile/components/AddressCard';
import { IMAGE_URL } from '../config/general';
import { FormattedMessage, useIntl } from 'react-intl';
import { getUser } from '../../redux/ducks/userDucks';

function PageCheckoutSuccess({ location, ...redux }) {
    const { locale, getUser } = redux;
    const [order, setOrder] = useState({ orders: [] });
    const intl = useIntl();

    useEffect(() => {
        if (!!location.state) {
            const { order } = location.state;
            setOrder(order);
            getUser();
        }
    }, [getUser, location]);

    const items = order.orders.map((product) => {
        const options = (product.options || []).map((option, i) => (
            <li className="order-list__options-item" key={i}>
                <span className="order-list__options-label">
                    {option.label}
                    {': '}
                </span>
                <span className="order-list__options-value">{option.value}</span>
            </li>
        ));

        const { discounted_price, price } = product;
        const productPrice = !!Number(discounted_price) ? discounted_price : price;

        return (
            <tr key={product.id}>
                <td className="order-list__column-image">
                    <Link to={{ pathname: `/shop/product/${product.id}`, product: product }}>
                        <img src={IMAGE_URL + product.avatar} alt="" />
                    </Link>
                </td>
                <td className="order-list__column-product">
                    <Link to={{ pathname: `/shop/product/${product.id}`, product: product }}>
                        {product[`description_short_${locale}`]}
                    </Link>
                    {options.length > 0 && (
                        <div className="order-list__options">
                            <ul className="order-list__options-list">{options}</ul>
                        </div>
                    )}
                </td>
                <td className="order-list__column-quantity" data-title={`${intl.formatMessage({ id: 'Qty' })}:`}>
                    {product.product_count}
                </td>
                <td className="order-list__column-total">
                    <Currency value={product.product_count * productPrice} />
                </td>
            </tr>
        );
    });

    const orderDate = getDateFromIso(order.created_at);
    let orderDateText = '';
    if (!!orderDate.monthText) {
        orderDateText = `${orderDate.day} ${intl.formatMessage({ id: orderDate.monthText })} ${orderDate.year}`;
    }

    return (
        <div className="block order-success">
            <UnipackHelmet title="Order Success" />

            <div className="container">
                <div className="order-success__body">
                    <div className="order-success__header">
                        <Check100Svg className="order-success__icon" />
                        <h1 className="order-success__title">
                            <FormattedMessage id="Thank you" />
                        </h1>
                        <div className="order-success__subtitle">
                            <FormattedMessage id="Your order has been received" />
                        </div>
                        <div className="order-success__actions">
                            <Link to="/" className="btn btn-xs btn-secondary">
                                <FormattedMessage id="Go To Homepage" />
                            </Link>
                        </div>
                    </div>
                    {!!order.order_id && (
                        <>
                            <div className="order-success__meta">
                                <ul className="order-success__meta-list">
                                    <li className="order-success__meta-item">
                                        <span className="order-success__meta-title">
                                            <FormattedMessage id="Order number" />:
                                        </span>
                                        <span className="order-success__meta-value">{`#${order.order_id}`}</span>
                                    </li>
                                    <li className="order-success__meta-item">
                                        <span className="order-success__meta-title">
                                            <FormattedMessage id="Created at" />:
                                        </span>
                                        <span className="order-success__meta-value">{orderDateText}</span>
                                    </li>
                                    <li className="order-success__meta-item">
                                        <span className="order-success__meta-title">
                                            <FormattedMessage id="Total" />:
                                        </span>
                                        <span className="order-success__meta-value">
                                            <Currency value={order.total_amount} />
                                        </span>
                                    </li>
                                    <li className="order-success__meta-item">
                                        <span className="order-success__meta-title">
                                            <FormattedMessage id="Payment method" />:
                                        </span>
                                        {/*TODO will cash stay fixed ?*/}
                                        <span className="order-success__meta-value">
                                            <FormattedMessage id="Cash" />
                                        </span>
                                    </li>
                                </ul>
                            </div>

                            <div className="card">
                                <div className="order-list">
                                    <table>
                                        <thead className="order-list__header">
                                            <tr>
                                                <th className="order-list__column-label" colSpan="2">
                                                    <FormattedMessage id="Product" />
                                                </th>
                                                <th className="order-list__column-quantity">
                                                    <FormattedMessage id="Qty" />
                                                </th>
                                                <th className="order-list__column-total">
                                                    <FormattedMessage id="Total" />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="order-list__products">{items}</tbody>
                                        <tbody className="order-list__subtotals">
                                            <tr>
                                                <th className="order-list__column-label" colSpan="3">
                                                    <FormattedMessage id="Subtotal" />
                                                </th>
                                                <td className="order-list__column-total">
                                                    <Currency value={order.sub_total} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="order-list__column-label" colSpan="3">
                                                    <FormattedMessage id="Shipping" />
                                                </th>
                                                <td className="order-list__column-total">
                                                    <Currency value={order.shipping_price} />
                                                </td>
                                            </tr>
                                            <tr className="color-discount">
                                                <th className="order-list__column-label" colSpan="3">
                                                    <FormattedMessage id="Discount" />
                                                </th>
                                                <td className="order-list__column-total">
                                                    {<Currency value={order.discount} />}
                                                </td>
                                            </tr>
                                            {/*<tr className="color-bonus">*/}
                                            {/*    <th className="order-list__column-label" colSpan="3">*/}
                                            {/*        <FormattedMessage id="Bonus" />*/}
                                            {/*    </th>*/}
                                            {/*    <td className="order-list__column-total">{order.bonus}</td>*/}
                                            {/*</tr>*/}
                                        </tbody>
                                        <tfoot className="order-list__footer">
                                            <tr>
                                                <th className="order-list__column-label" colSpan="3">
                                                    <FormattedMessage id="Total" />
                                                </th>
                                                <td className="order-list__column-total">
                                                    <Currency value={order.total_amount} />
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>

                            <div className="row mt-3 no-gutters mx-n2">
                                <div className="col-sm-12 col-12 px-2">
                                    <div className="card address-card">
                                        <AddressCard
                                            address={order.shipping_details}
                                            editable={false}
                                            headerText={'Shipping Details'}
                                            cardWidth={'w-100'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({ locale }) => ({
    locale,
});

const mapDispatchToProps = {
    getUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageCheckoutSuccess);
