// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';

// application
import AppLink from '../shared/AppLink';
import Collapse from '../shared/Collapse';
import { ArrowRoundedDown12x7Svg } from '../../svg';
import { FormattedMessage } from 'react-intl';
import { getTitleLocale } from '../../helpers/general';
import { connect } from 'react-redux';
import { resetFilteredPrice, resetFilterOptions } from '../../redux/ducks/filtersDucks';

function isFlagIcons(icons) {
    const a = icons.map((link) => {
        return <img key={`flag_link_${link}`} className="mobile-icons-margin" src={link} alt="" />;
    });
    return <span>{a}</span>;
}

function MobileLinks({ links, level, onItemClick, mobileMenuState, locale, resetFilteredPrice, resetFilterOptions }) {
    const handleItemClick = (item) => {
        if (onItemClick) {
            onItemClick(item);
        }
    };

    const labelTitle = (label) => {
        if (typeof label === 'object') {
            return label[getTitleLocale(locale)];
        }
        return <FormattedMessage id={label} />;
    };

    const linksList = links.map((link, index) => {
        let item;

        if (link.type === 'link' || link.type === 'button') {
            item = (
                <Collapse
                    toggleClass="mobile-links__item--open"
                    open={mobileMenuState.open}
                    render={({ toggle, setItemRef, setContentRef }) => {
                        let arrow;
                        let subLinks;
                        let linkOrButton;

                        if ((link.children && link.children.length > 0) || link.component) {
                            arrow = (
                                <button className="mobile-links__item-toggle" type="button" onClick={toggle}>
                                    <ArrowRoundedDown12x7Svg className="mobile-links__item-arrow" />
                                </button>
                            );

                            subLinks = (
                                <div className="mobile-links__item-sub-links" ref={setContentRef}>
                                    {!!link.component ? (
                                        link.component
                                    ) : (
                                        <MobileLinks
                                            links={link.children}
                                            locale={locale}
                                            level={level + 1}
                                            onItemClick={onItemClick}
                                            mobileMenuState={mobileMenuState}
                                            resetFilteredPrice={resetFilteredPrice}
                                            resetFilterOptions={resetFilterOptions}
                                        />
                                    )}
                                </div>
                            );
                        }

                        if (link.type === 'link') {
                            linkOrButton = (
                                <AppLink
                                    to={link.url}
                                    className="mobile-links__item-link"
                                    onClick={() => {
                                        resetFilteredPrice();
                                        resetFilterOptions();
                                        handleItemClick(link);
                                        !!link.onClick && link.onClick();
                                    }}
                                >
                                    {labelTitle(link.label)}
                                </AppLink>
                            );
                        } else {
                            linkOrButton = (
                                <button
                                    type="button"
                                    className="mobile-links__item-link"
                                    onClick={() => {
                                        resetFilteredPrice();
                                        resetFilterOptions();
                                        handleItemClick(link);
                                        !!link.onClick && link.onClick();
                                    }}
                                >
                                    {<FormattedMessage id={link.label} />} {link.icons ? isFlagIcons(link.icons) : ''}
                                </button>
                            );
                        }

                        return (
                            <div className="mobile-links__item" ref={setItemRef}>
                                <div className="mobile-links__item-title">
                                    {linkOrButton}
                                    {arrow}
                                </div>
                                {subLinks}
                            </div>
                        );
                    }}
                />
            );
        } else if (link.type === 'divider') {
            item = <div className="mobile-links__divider" />;
        }

        return <li key={index}>{item}</li>;
    });

    return <ul className={`mobile-links mobile-links--level--${level}`}>{linksList}</ul>;
}

MobileLinks.propTypes = {
    links: PropTypes.array,
    level: PropTypes.number,
    onItemClick: PropTypes.func,
};

MobileLinks.defaultProps = {
    links: [],
    level: 0,
};

const mapDispatchToProps = { resetFilteredPrice, resetFilterOptions };

export default connect(null, mapDispatchToProps)(MobileLinks);
