// react
import React from 'react';

// data stubs
import UnipackHelmet from '../shared/UnipackHelmet';
import { FormattedMessage } from 'react-intl';

function InfoPage({ title, html, imageUri }) {
    const imageString = 'url("images/' + imageUri + '.jpg")';
    return (
        <div className="block about-us">
            <UnipackHelmet title={title} />

            <div className="about-us__image" style={{ backgroundImage: imageString }} />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-xl-10">
                        <div className="about-us__body">
                            <h1 className="about-us__title">
                                <FormattedMessage id={title} />
                            </h1>
                            <div className="about-us__text typography">
                                <p dangerouslySetInnerHTML={{ __html: html }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InfoPage;
