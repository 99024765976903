import { useHistory } from 'react-router-dom';

export default function useRedirectOnClick(onClick, path) {
    let history = useHistory();
    return async (data) => {
        try {
            await onClick(data, 'main');
            history.push(path);
        } catch (e) {
            console.log(e);
        }
    };
}
