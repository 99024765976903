// react
import React, { useEffect, useState } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import AsyncAction from './AsyncAction';
import Currency from './Currency';
import Rating from './Rating';
import { cartAddItem } from '../../redux/ducks/cartDucks';
import { Quickview16Svg } from '../../svg';
import { quickviewOpen } from '../../redux/ducks/quickviewDucks';
import { getTitleLocale } from '../../helpers/general';
import { IMAGE_URL } from '../config/general';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import InputNumber from './InputNumber';

function ProductCard({ product, ...redux }) {
    const { layout, quickviewOpen, cartAddItem, locale } = redux;
    const [quantity, setQuantity] = useState(1);

    useEffect(() => {
        setQuantity(1);
    }, [product.id]);

    const containerClasses = classNames('product-card', {
        'product-card--layout--grid product-card--size--sm': layout === 'grid-sm',
        'product-card--layout--grid product-card--size--nl': layout === 'grid-nl',
        'product-card--layout--grid product-card--size--lg': layout === 'grid-lg',
        'product-card--layout--list': layout === 'list',
        'product-card--layout--horizontal': layout === 'horizontal',
    });

    let badges = [];
    let image;
    let price;
    let features;

    if (product.is_sale) {
        badges.push(
            <div key="sale" className="product-card__badge product-card__badge--sale">
                <FormattedMessage id="SaleWithNumber" values={{ number: product.percent || 0 }} />
            </div>
        );
    }
    if (product.is_sale && !!product.sale_date) {
        badges.push(
            <div
                key="saleDate"
                className="product-card__badge product-card__badge--sale product-card__badge--sale--date"
            >
                <FormattedMessage id="SaleUntilLight" />
                {': '}
                {moment(product.sale_date).format('DD-MM-YYYY')}
            </div>
        );
    }
    if (product.is_featured) {
        badges.push(
            <div key="hot" className="product-card__badge product-card__badge--hot">
                <FormattedMessage id="Featured" />
            </div>
        );
    }
    if (product.is_new) {
        badges.push(
            <div key="new" className="product-card__badge product-card__badge--new">
                <FormattedMessage id="New" />
            </div>
        );
    }

    badges = badges.length ? <div className="product-card__badges-list">{badges}</div> : null;

    image = (
        <div className="product-card__image">
            <Link to={{ pathname: `/shop/product/${product.id}`, product: product }}>
                <img src={IMAGE_URL + product.avatar} alt="" />
            </Link>
        </div>
    );

    if (!!Number(product.discounted_price)) {
        price = (
            <div className="product-card__prices">
                <span className="product-card__new-price">
                    <Currency value={product.discounted_price} />
                </span>
                <span className="product-card__old-price">
                    <Currency value={product.price} isDiscounted={true} nearPrice={true} classNames={'big'} />
                </span>
            </div>
        );
    } else {
        price = (
            <div className="product-card__prices">
                <Currency value={product.price} />
            </div>
        );
    }
    if (!!product.params) {
        features = (
            <ul className="product-card__features-list">
                {Object.entries(product.params).map(([key, value], i) => {
                    if (key === 'Count') {
                        return (
                            <li key={i}>
                                <FormattedMessage id="Count" />: {Math.round(value)}
                            </li>
                        );
                    }
                    if (key === 'Name') {
                        return (
                            <li key={i}>
                                <FormattedMessage id="Type" /> : {value}
                            </li>
                        );
                    }
                    return '';
                })}
            </ul>
        );
    } else {
        features = (
            <ul className="product-card__features-list">
                <li>
                    <FormattedMessage id="Type" />: <FormattedMessage id="Single" />
                </li>
                <li>
                    <FormattedMessage id="Count" />: 1
                </li>
            </ul>
        );
    }

    let count = !!product.params && product.params.Count ? Math.round(product.params.Count) : 1;

    return (
        <div className={containerClasses}>
            <AsyncAction
                action={() => quickviewOpen(product)}
                render={({ run, loading }) => (
                    <button
                        type="button"
                        onClick={run}
                        className={classNames('product-card__quickview', {
                            'product-card__quickview--preload': loading,
                        })}
                    >
                        <Quickview16Svg />
                    </button>
                )}
            />
            {badges}
            {image}
            <div className="product-card__info">
                <div className="product-card__name">
                    <Link to={{ pathname: `/shop/product/${product.id}`, product: product }}>
                        {product[getTitleLocale(locale)]}
                    </Link>
                </div>
                <div className="product-card__rating">
                    <Rating value={product.rate} />
                    <div className="product-card__rating-legend">
                        {product.reviews} {<FormattedMessage id="Reviews" />}
                    </div>
                </div>
                {features}
            </div>
            <div className="product-card__actions">
                <div className="product-card__availability">
                    <FormattedMessage id="Availability" />:
                    <span className="text-success">
                        <FormattedMessage id="In Stock" />
                    </span>
                </div>
                {price}
                <div className="product-card__buttons">
                    <div className="product__actions-item" style={{ display: 'flex' }}>
                        <InputNumber
                            id="product-quantity"
                            aria-label="Quantity"
                            className="product__quantity_item"
                            size="sm"
                            min={1}
                            value={quantity}
                            onChange={handleChangeQuantity}
                            onBlur={handleBlur}
                        />
                        {count > 1 && (
                            <div className="input-radio-label">
                                <span className="cross1"></span>
                                <span className="cross2"></span>
                                <p className="input-radio-label-count">{count}</p>
                            </div>
                        )}
                    </div>
                    <AsyncAction
                        action={() => cartAddItem(product, quantity)}
                        render={({ run, loading }) => (
                            <>
                                <button
                                    type="button"
                                    onClick={run}
                                    className={classNames('btn btn-primary product-card__addtocart', {
                                        'btn-loading': loading,
                                    })}
                                >
                                    <FormattedMessage id="Add To Cart" />
                                </button>
                                <button
                                    type="button"
                                    onClick={run}
                                    className={classNames(
                                        'btn btn-secondary product-card__addtocart product-card__addtocart--list',
                                        {
                                            'btn-loading': loading,
                                        }
                                    )}
                                >
                                    <FormattedMessage id="Add To Cart" />
                                </button>
                            </>
                        )}
                    />
                </div>
            </div>
        </div>
    );

    function handleChangeQuantity(e) {
        if (typeof e === 'number') {
            setQuantity(e);
        } else {
            let quantity = e.target.value;
            if (!!quantity) {
                quantity = Number(quantity);
            }
            setQuantity(quantity);
        }
    }

    function handleBlur() {
        if (!quantity) {
            setQuantity(1);
        }
    }
}

ProductCard.propTypes = {
    /**
     * product object
     */
    product: PropTypes.object.isRequired,
    /**
     * product card layout
     * one of ['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']
     */
    layout: PropTypes.oneOf(['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']),
};

const mapStateToProps = ({ locale }) => ({ locale });

const mapDispatchToProps = {
    cartAddItem,
    quickviewOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
