// react
import React, { useState } from 'react';

// application
import PageHeader from '../shared/PageHeader';

// data stubs
import UnipackHelmet from '../shared/UnipackHelmet';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import FormInput from '../shared/FormInput';
import { useFormik } from 'formik';
import { resetCustomError } from '../../redux/ducks/validationDucks';
import { sendFeedbackMessage } from '../../redux/ducks/feedbackDucks';
import useOnUnmount from '../hooks/useOnUnmount';
import DangerAlert from '../account/accountPageLogin/components/DangerAlert';

const contactUsInputData = [
    { label: 'Your Name', type: 'text', placeholder: 'Your Name', id: 'name', error: false, message: '' },
    { label: 'Email address', type: 'email', placeholder: 'Email address', id: 'email', error: false, message: '' },
    { label: 'Subject', type: 'text', placeholder: 'Subject', id: 'subject', error: false, message: '' },
];

function SitePageContactUsAlt({ locale, configs, sendFeedbackMessage, resetCustomError, customErrorMessage }) {
    const { address, email, phone, workingHours, contactUsText } = configs;
    const [message, setMessage] = useState('');
    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'Contact Us', url: '' },
    ];

    useOnUnmount(() => {
        resetCustomError();
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            name: '',
            subject: '',
        },
        onSubmit: (values) => {
            sendFeedbackMessage({ ...values, message, locale });
        },
    });

    return (
        <>
            <UnipackHelmet title="Contact Us" />

            <PageHeader header="Contact Us" breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="card mb-0 contact-us">
                        <div className="contact-us__map">
                            <iframe
                                title="Google Map"
                                src="https://www.google.com/maps/embed?pb=!1m19!1m8!1m3!1d1846.9011856189416!2d44.507212809577865!3d40.120415799954166!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x406ab9682f0fb41b%3A0xd2edaedc5b040bf9!2sYerevan%2C%20Armenia!3m2!1d40.1214593!2d44.508466299999995!5e0!3m2!1sen!2s!4v1602349848915!5m2!1sen!2s"
                                frameBorder="0"
                                scrolling="no"
                                marginHeight="0"
                                marginWidth="0"
                            />
                        </div>
                        {!Object.entries(configs).length ? (
                            <div className="d-flex m-auto spinner-border text-danger" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        ) : (
                            <div className="card-body">
                                <div className="contact-us__container">
                                    <div className="row">
                                        <div className="col-12 col-lg-6 pb-4 pb-lg-0">
                                            <h4 className="contact-us__header card-title">
                                                <FormattedMessage id="Contact Us" />
                                            </h4>

                                            <div className="contact-us__address">
                                                <p>
                                                    {address[locale]}
                                                    <br />
                                                    <FormattedMessage id="Email address" />: {email}
                                                    <br />
                                                    <FormattedMessage id="Phone Number" />: {phone}
                                                </p>

                                                <p>
                                                    <strong>
                                                        <FormattedMessage id="Opening Hours" />
                                                    </strong>
                                                    <br />
                                                    {workingHours[locale]}
                                                </p>

                                                <p>
                                                    <strong>
                                                        <FormattedMessage id="Comment" />
                                                    </strong>
                                                    <br />
                                                    {contactUsText[locale]}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="col-12 col-lg-6">
                                            <h4 className="contact-us__header card-title">
                                                <FormattedMessage id="Leave us a Message" />
                                            </h4>

                                            <form onSubmit={formik.handleSubmit}>
                                                <div className="form-row">
                                                    {contactUsInputData.map((elem) => {
                                                        const { id, label, placeholder, error, message, type } = elem;
                                                        return (
                                                            <FormInput
                                                                {...{
                                                                    id,
                                                                    label,
                                                                    placeholder,
                                                                    type,
                                                                    error,
                                                                    message,
                                                                    onChange: formik.handleChange,
                                                                    value: formik.values[id],
                                                                    key: id,
                                                                    wrapperClass:
                                                                        id !== 'subject' ? 'col-md-6' : 'col-md-12',
                                                                }}
                                                            />
                                                        );
                                                    })}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="form-message">
                                                        <FormattedMessage id="Message" />
                                                    </label>
                                                    <textarea
                                                        id="form-message"
                                                        className="form-control"
                                                        rows="4"
                                                        value={message}
                                                        onChange={(e) => setMessage(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <DangerAlert
                                                        isVisible={!!customErrorMessage}
                                                        message={customErrorMessage}
                                                    />
                                                </div>
                                                <button type="submit" className="btn btn-primary">
                                                    <FormattedMessage id="Send Message" />
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

const mapDispatchToProps = { sendFeedbackMessage, resetCustomError };

const mapStateToProps = ({ locale, configs, customErrorMessage }) => ({
    locale,
    configs,
    customErrorMessage,
});

export default connect(mapStateToProps, mapDispatchToProps)(SitePageContactUsAlt);
