import React from 'react';
import classes from './SwitchGrid.module.css';

export default function SwitchGrid({ setGridType, gridType }) {
    return (
        <div className={classes.wrapper}>
            <div className={classes.container}>
                <div
                    className={gridType !== 'row' ? `${classes.icon} ${classes.active}` : classes.icon}
                    onClick={() => setGridType('grid')}
                >
                    <svg viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em">
                        <path d="M4 11h6a1 1 0 001-1V4a1 1 0 00-1-1H4a1 1 0 00-1 1v6a1 1 0 001 1zm10 0h6a1 1 0 001-1V4a1 1 0 00-1-1h-6a1 1 0 00-1 1v6a1 1 0 001 1zM4 21h6a1 1 0 001-1v-6a1 1 0 00-1-1H4a1 1 0 00-1 1v6a1 1 0 001 1zm10 0h6a1 1 0 001-1v-6a1 1 0 00-1-1h-6a1 1 0 00-1 1v6a1 1 0 001 1z" />
                    </svg>
                </div>
                <div
                    className={
                        gridType === 'row'
                            ? `${classes.icon} ${classes.listIcon} ${classes.active}`
                            : `${classes.icon} ${classes.listIcon}`
                    }
                    onClick={() => setGridType('row')}
                >
                    <svg viewBox="0 0 16 16" fill="currentColor" height="1em" width="1em">
                        <path d="M0 0h4v4H0zm6 1h10v2H6zM0 6h4v4H0zm6 1h10v2H6zm-6 5h4v4H0zm6 1h10v2H6z" />
                    </svg>
                </div>
            </div>
        </div>
    );
}
