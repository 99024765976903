export function getAllSubCategories(categories) {
    return categories.map((category) => {
        const haveSubCategories = category.subCategories.length;
        return {
            ...category,
            subCategories: haveSubCategories
                ? category.subCategories
                : [
                      {
                          ...category,
                          parent_id: category.id,
                      },
                  ],
        };
    });
}

export function getCategoriesBySection(categories, section) {
    if (Number(section) === 3) return categories;
    return categories.filter((category) => {
        return Number(category.menu_section) === Number(section) || Number(category.menu_section) === 3;
    });
}
