// react
import React from 'react';
import { connect } from 'react-redux';

// third-party
import { Link } from 'react-router-dom';

// application
import Menu from './Menu';
import { ArrowRoundedRight6x9Svg } from '../../svg';

// data stubs
import { getTitleLocale } from '../../helpers/general';
import { resetFilteredPrice, resetFilterOptions } from '../../redux/ducks/filtersDucks';

function DepartmentsLinks({ locale, categories, resetFilterOptions, resetFilteredPrice }) {
    const linksList = categories.map((department, index) => {
        let arrow = null;
        let submenu = null;
        let itemClass = '';
        if (!!department.subCategories.length) {
            arrow = <ArrowRoundedRight6x9Svg className="departments__link-arrow" />;
        }

        if (!!department.subCategories.length) {
            itemClass = 'departments__item--menu';
            submenu = (
                <div className="departments__menu">
                    <Menu items={department.subCategories} locale={locale} isCategories={true} />
                </div>
            );
        }

        return (
            <li key={index} className={`departments__item ${itemClass}`}>
                <Link
                    to={`/shop/category/${department.id}`}
                    onClick={() => {
                        resetFilterOptions();
                        resetFilteredPrice();
                    }}
                >
                    {department[getTitleLocale(locale)]}
                    {arrow}
                </Link>
                {submenu}
            </li>
        );
    });

    return <ul className="departments__links">{linksList}</ul>;
}

const mapStateToProps = ({ locale, categories }) => ({
    locale,
    categories,
});

const mapDispatchToProps = {
    resetFilterOptions,
    resetFilteredPrice,
};

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentsLinks);
