import React from 'react';
import { Helmet } from 'react-helmet-async';

function UnipackHelmet({ title }) {
    return (
        <Helmet>
            <title>{`Unipack: ${title}`}</title>
        </Helmet>
    );
}

export default UnipackHelmet;
