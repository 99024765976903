import React from 'react';
import filledStar from '../../svg/starFilled.svg';
import outlinesStar from '../../svg/starOulined.svg';

function VoteStar({ filled, handleClick, id }) {
    return (
        <span className="mr-1">
            <img
                alt=""
                id={id}
                src={filled ? filledStar : outlinesStar}
                className="icon-style"
                onClick={(e) => handleClick(e)}
                style={{ height: '18px' }}
            />
        </span>
    );
}

export default VoteStar;
