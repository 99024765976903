// react
import React from 'react';

// third-party
import { connect } from 'react-redux';
import { IMAGE_URL } from '../config/general';

function BlockBanner(redux) {
    const { locale, banners } = redux;

    const webBanner = banners?.web?.home?.middle?.[locale]?.[0] || '';
    const mobileBanner = banners?.mobile?.home?.middle?.[locale]?.[0] || '';

    return (
        <div className="block block-banner">
            <div className="container">
                {!!banners.web && !!banners.mobile && (
                    <a className="block-banner__body" href={webBanner.link} target="_blank" rel="noopener noreferrer">
                        <div
                            className="block-banner__image block-banner__image--desktop"
                            style={{ backgroundImage: `url(${IMAGE_URL + webBanner.path})` }}
                        />
                        <div
                            rel="noreferrer"
                            className="block-banner__image block-banner__image--mobile"
                            style={{ backgroundImage: `url(${IMAGE_URL + mobileBanner.path})` }}
                        />
                    </a>
                )}
            </div>
        </div>
    );
}

const mapStateToProps = ({ banners, locale }) => ({
    banners,
    locale,
});

export default connect(mapStateToProps)(BlockBanner);
