import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

function EmptyCardPage() {
    return (
        <div className="block block-empty">
            <div className="container">
                <div className="block-empty__body">
                    <div className="block-empty__message">
                        <FormattedMessage id="Your shopping cart is empty!" />
                    </div>
                    <div className="block-empty__actions">
                        <Link to="/" className="btn btn-primary btn-sm">
                            <FormattedMessage id="Continue" />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EmptyCardPage;
