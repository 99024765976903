import React from 'react';
import { FormattedMessage } from 'react-intl';

function FormContainer({ title, children }) {
    return (
        <div className="col-md-6 d-flex">
            <div className="card flex-grow-1 mb-md-0">
                <div className="card-body">
                    <h3 className="card-title">{<FormattedMessage id={title} />}</h3>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default FormContainer;
