import { createAction } from '../../helpers/redux';

const CURRENCY_CHANGE = 'CURRENCY_CHANGE';

export const currencyChange = createAction(CURRENCY_CHANGE);

const initialState = {
    code: 'AMD',
    symbol: 'դր',
    name: 'Դրամ',
};

export function currency(state = initialState, action) {
    if (action.type === CURRENCY_CHANGE && state.code !== action.currency.code) {
        return action.currency;
    }

    return state;
}
