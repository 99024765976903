import React, { useCallback, useEffect, useRef, useState } from 'react';
import ProductCard from '../items/ProductCard';
import classes from './ProductsPage.module.css';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import SwitchGrid from '../switchGrid/SwitchGrid';
import axios from 'axios';
import { GLOBAL_URL, IMAGE_URL } from '../../../config/general';
import Cookies from 'js-cookie';
import CategoryItem from '../items/CategoryItem';
import { getAllSubCategories } from '../../functions';
import { tempQuickViewOpen } from '../../../../redux/ducks/tempQuickViewDucks';
import FetchLoader from '../../../shared/FetchLoader';

function ProductsPage({ categories, locale, tempQuickViewOpen }) {
    const isMobile = window.innerWidth < 768;
    const { id, section } = useParams();
    const [mainCategoryId, setMainCategoryId] = useState();
    const [isContentLoaded, setIsContentLoaded] = useState(false);
    const [productData, setProductData] = useState();
    const [filteredCategories, setFilteredCategories] = useState([]);
    const [gridType, setGridType] = useState(isMobile ? 'row' : 'grid');
    const scrollBarRef = useRef(null);
    const leftRef = useRef(null);
    const rightRef = useRef(null);

    useEffect(() => {
        const scrollBar = scrollBarRef.current;
        const scrollBarWidth = scrollBar.scrollWidth;
        const windowWidth = window.innerWidth;
        if (scrollBarWidth - windowWidth <= 0 || windowWidth < 992) {
            leftRef.current.style.display = 'none';
            rightRef.current.style.display = 'none';
        } else {
            leftRef.current.style.display = 'block';
            rightRef.current.style.display = 'block';
        }
    }, [categories]);

    useEffect(() => {
        getProducts(id);
        setMainCategoryId(Number(id));
    }, []);

    useEffect(() => {
        const allSubCategories = getAllSubCategories(categories);
        allSubCategories.forEach((category) => {
            const current = category.subCategories.find((val) => Number(val.id) === Number(id));
            if (current) {
                const finalCategory = allSubCategories.find((val) => Number(val.id) === Number(current.parent_id));
                setFilteredCategories(finalCategory.subCategories);
            }
        });
    }, [categories]);

    const getProducts = useCallback((categoryId) => {
        axios
            .post(
                GLOBAL_URL + 'products/get',
                {
                    categoryId,
                    isFeatured: false,
                    isNew: false,
                    isSale: false,
                    limit: 1122334455,
                    orderBy: '',
                    page: 1,
                    price: {
                        min: 1,
                        max: 10000000000000,
                    },
                },
                {
                    headers: {
                        authorization: Cookies.get('token'),
                    },
                }
            )
            .then((res) => {
                const { data } = res.data;
                setProductData(data?.products);
                setIsContentLoaded(true);
            })
            .catch((e) => console.log(e));
    }, []);

    const onChangeGridType = (type) => {
        if (type === gridType) return;
        setGridType(type);
    };

    function handleScrollBarScroll(direction) {
        return function () {
            const elem = scrollBarRef.current;
            if (elem) {
                const scrollLength = window.innerWidth / 2;
                if (direction === 'left') {
                    elem.scrollBy({ left: -scrollLength, behavior: 'smooth' });
                } else {
                    elem.scrollBy({ left: scrollLength, behavior: 'smooth' });
                }
            }
        };
    }

    function moveActiveCategoryItemCenter(elem) {
        elem.scrollIntoView({ block: 'nearest', inline: 'center' });
    }

    return (
        <>
            <div className={classes.wrapp}>
                <div ref={leftRef} className={classes.icon} onClick={handleScrollBarScroll('left')}>
                    <svg style={{ color: '#9d9d9d' }} viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em">
                        <path d="M4.431 12.822l13 9A1 1 0 0019 21V3a1 1 0 00-1.569-.823l-13 9a1.003 1.003 0 000 1.645z" />
                    </svg>
                </div>
                <div ref={scrollBarRef} className={classes.scrollBarContainer}>
                    {filteredCategories?.length ? (
                        filteredCategories.map((category) => {
                            return (
                                <CategoryItem
                                    moveCenter={moveActiveCategoryItemCenter}
                                    onClick={() => {
                                        if (category.id !== mainCategoryId) {
                                            setIsContentLoaded(false);
                                            getProducts(category.id);
                                            setMainCategoryId(category.id);
                                            window.history.replaceState(
                                                null,
                                                mainCategoryId,
                                                `menu/${section}/categories/${category.id}/products`
                                            );
                                        }
                                    }}
                                    key={`top_category_${category.id}`}
                                    title={category[`title_${locale}`]}
                                    isActive={Number(category.id) === Number(mainCategoryId)}
                                    id={category.id}
                                />
                            );
                        })
                    ) : (
                        <CategoryItem
                            title={filteredCategories?.find((category) => category?.id === +mainCategoryId)?.title_am}
                        />
                    )}
                </div>
                <div ref={rightRef} className={classes.icon} onClick={handleScrollBarScroll('right')}>
                    <svg className={classes.rightIcon} viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em">
                        <path d="M4.431 12.822l13 9A1 1 0 0019 21V3a1 1 0 00-1.569-.823l-13 9a1.003 1.003 0 000 1.645z" />
                    </svg>
                </div>
            </div>
            <SwitchGrid setGridType={onChangeGridType} gridType={gridType} />
            <FetchLoader
                loaded={isContentLoaded}
                children={
                    <div className={gridType !== 'row' ? `${classes.wrapper} ${classes.grid}` : classes.wrapper}>
                        {productData?.map((product) => {
                            return (
                                <ProductCard
                                    type={gridType}
                                    url={IMAGE_URL + product.avatar}
                                    title={product[`title_${locale}`]}
                                    price={product.price}
                                    discountedPrice={product.discounted_price}
                                    saleDate={product.sale_date}
                                    wholesalePrice={product.wholesale_price}
                                    articleId={product.article_id}
                                    key={`product_${product.id}`}
                                    onClick={() => tempQuickViewOpen(product)}
                                    id={product.id}
                                    description={product[`description_short_${locale}`]}
                                />
                            );
                        })}
                    </div>
                }
            />
        </>
    );
}

const mapStateToProps = ({ categories, locale }) => ({
    categories,
    locale,
});

const mapDispatchToProps = {
    tempQuickViewOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsPage);
