// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';

// application
import { Check9x7Svg } from '../../svg';
import { FormattedMessage } from 'react-intl';

function FilterCheckbox({ items, parentId, onChange }) {
    const itemsList = Object.values(items).map((item) => {
        return (
            <label
                key={item.id}
                className={classNames('filter-list__item', {
                    'filter-list__item--disabled': item.disabled,
                })}
            >
                <span className="filter-list__input input-check">
                    <span className="input-check__body">
                        <input
                            className="input-check__input"
                            type="checkbox"
                            checked={item.checked}
                            disabled={item.disabled}
                            onChange={() => onChange(parentId, item.id)}
                        />
                        <span className="input-check__box" />
                        <Check9x7Svg className="input-check__icon" />
                    </span>
                </span>
                <span className="filter-list__title">{<FormattedMessage id={item.label} />}</span>
            </label>
        );
    });

    return (
        <div className="filter-list">
            <div className="filter-list__list">{itemsList}</div>
        </div>
    );
}

export default FilterCheckbox;
