import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import SubCategoryBlock from './SubCategoryBlock';
import { getAllSubCategories, getCategoriesBySection } from '../../functions';
import { useParams } from 'react-router-dom';
import classes from './SubCategories.module.css';
import CategoryItem from '../items/CategoryItem';

function SubCategories({ categories, locale }) {
    const { id, section } = useParams();
    const [activeCategoryId, setActiveCategoryId] = useState(Number(id) || null);
    const listRef = useRef(null);
    const scrollBarRef = useRef(null);
    const leftRef = useRef(null);
    const rightRef = useRef(null);
    const filteredCategories = useMemo(() => getCategoriesBySection(categories, section), [categories]);
    const allSubCategories = useMemo(() => getAllSubCategories(filteredCategories), [categories]);

    function setActiveId(id) {
        setActiveCategoryId(Number(id));
    }

    useEffect(() => {
        const handleScroll = () => {
            const wrapper = listRef.current;
            const wrapperCoordinates = wrapper.getBoundingClientRect();
            const listItems = listRef.current.children;
            const heightFromTopToBeActive = 100;
            if (Math.floor(wrapperCoordinates.bottom) <= window.innerHeight) {
                setActiveId(categories.at(-1)?.id);
                scrollToActiveCategory(categories.at(-1)?.id);
            } else if (wrapperCoordinates.top >= 0) {
                setActiveId(categories[0]?.id);
                scrollToActiveCategory(categories[0]?.id);
            } else {
                for (let i = 0; i < listItems.length; i++) {
                    const listItem = listItems[i];
                    const rect = listItem.getBoundingClientRect();
                    if (-rect.height + heightFromTopToBeActive < rect.top && rect.top <= heightFromTopToBeActive) {
                        setActiveId(listItem.dataset.id);
                        break;
                    }
                }
            }
        };

        scrollToActiveCategoryOnMount(id);
        handleItemClick(id);
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [categories]);

    const scrollToActiveCategory = (id) => {
        const topBar = document.querySelector(`[data-id="top_category_${id}"]`);
        if (topBar) {
            topBar.scrollIntoView({ block: 'nearest', inline: 'center' });
        }
    };
    const scrollToActiveCategoryOnMount = (id) => {
        const topBar = document.querySelector(`[data-id="top_category_${id}"]`);
        if (topBar) {
            topBar.scrollIntoView({ block: 'center', inline: 'center' });
        }
    };

    const handleItemClick = (id) => {
        const listItem = listRef.current.querySelector(`[data-id="${id}"]`);
        if (listItem) {
            listItem.scrollIntoView({ block: 'start' });
        }
    };

    function moveActiveCategoryItemCenter(elem) {
        elem.scrollIntoView({ block: 'nearest', inline: 'center' });
    }

    function handleScrollBarScroll(direction) {
        return function () {
            const elem = scrollBarRef.current;
            if (elem) {
                const scrollLength = window.innerWidth / 2;
                if (direction === 'left') {
                    elem.scrollBy({ left: -scrollLength, behavior: 'smooth' });
                } else {
                    elem.scrollBy({ left: scrollLength, behavior: 'smooth' });
                }
            }
        };
    }

    return (
        <>
            <div className={classes.wrapp}>
                <div ref={leftRef} className={classes.icon} onClick={handleScrollBarScroll('left')}>
                    <svg style={{ color: '#9d9d9d' }} viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em">
                        <path d="M4.431 12.822l13 9A1 1 0 0019 21V3a1 1 0 00-1.569-.823l-13 9a1.003 1.003 0 000 1.645z" />
                    </svg>
                </div>
                <div ref={scrollBarRef} className={classes.scrollBarContainer}>
                    {categories?.map((category) => {
                        return (
                            <CategoryItem
                                moveCenter={moveActiveCategoryItemCenter}
                                onClick={() => handleItemClick(category.id)}
                                key={`top_category_${category.id}`}
                                title={category[`title_${locale}`]}
                                isActive={Number(category.id) === activeCategoryId}
                                id={category.id}
                            />
                        );
                    })}
                </div>
                <div ref={rightRef} className={classes.icon} onClick={handleScrollBarScroll('right')}>
                    <svg className={classes.rightIcon} viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em">
                        <path d="M4.431 12.822l13 9A1 1 0 0019 21V3a1 1 0 00-1.569-.823l-13 9a1.003 1.003 0 000 1.645z" />
                    </svg>
                </div>
            </div>
            <div ref={listRef} style={{ paddingBottom: '30px', paddingTop: '20px' }}>
                {allSubCategories?.map((category) => (
                    <SubCategoryBlock
                        key={`sub_category_${category.id}`}
                        locale={locale}
                        subCategory={category}
                        title={category[`title_${locale}`]}
                    />
                ))}
            </div>
        </>
    );
}

const mapStateToProps = ({ categories, locale }) => ({
    categories,
    locale,
});

export default connect(mapStateToProps)(SubCategories);
