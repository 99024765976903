export const MOBILE_MENU_OPEN = 'MOBILE_MENU_OPEN';
export const MOBILE_MENU_CLOSE = 'MOBILE_MENU_CLOSE';

export function mobileMenuOpen() {
    return { type: MOBILE_MENU_OPEN };
}

export function mobileMenuClose() {
    return { type: MOBILE_MENU_CLOSE };
}

const initialState = {
    open: false,
};

export function mobileMenu(state = initialState, action) {
    switch (action.type) {
        case MOBILE_MENU_OPEN:
            return {
                ...state,
                open: true,
            };
        case MOBILE_MENU_CLOSE:
            return {
                ...state,
                open: false,
            };
        default:
            return state;
    }
}
