// react
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

// third-party

// data stubs
import { useFormik } from 'formik';
import FormInput from '../shared/FormInput';
import axios from 'axios';
import { GLOBAL_URL } from '../config/general';
import Cookies from 'js-cookie';
import useFormWithErrors from '../hooks/useFormWithErrors';
import { toast } from 'react-toastify';
import { setUserData } from '../../redux/ducks/userDucks';
import UnipackHelmet from '../shared/UnipackHelmet';
import { FormattedMessage } from 'react-intl';
import { TOAST_MESSAGES } from '../../constants/general';

const editProfileInputsData = [
    { label: 'First name', type: 'text', placeholder: 'First name', id: 'first_name', message: '', error: false },
    { label: 'Last name', type: 'text', placeholder: 'Last name', id: 'last_name', message: '', error: false },
    { label: 'Email address', type: 'email', placeholder: 'Enter email', id: 'email', message: '', error: false },
    { label: 'Phone number', type: 'tel', placeholder: 'Phone number', id: 'phone', message: '', error: false },
];

function AccountPageEditProfile(redux) {
    const { user, setUserData } = redux;
    const [formErrors, setFormErrors] = useState([]);
    const { first_name, last_name, email, phone } = user;
    const [areFieldsChanged, setAreFieldsChanged] = useState(false);
    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
        },
        onSubmit: (values) => {
            handleSubmit(values);
        },
    });

    useEffect(() => {
        !!user.id && formik.setValues({ first_name, last_name, email, phone });
    }, [user]);

    useEffect(() => {
        const { first_name, last_name, email, phone } = user;
        const changed = JSON.stringify(formik.values) !== JSON.stringify({ first_name, last_name, email, phone });
        setAreFieldsChanged(changed);
    }, [formik.values]);

    const formInputData = useFormWithErrors(editProfileInputsData, formErrors);

    return (
        <div className="card">
            <UnipackHelmet title="Profile" />

            <div className="card-header">
                <h5>
                    <FormattedMessage id="Edit Profile" />
                </h5>
            </div>
            <div className="card-divider" />
            <div className="card-body">
                <div className="row no-gutters">
                    <div className="col-12 col-lg-7 col-xl-6">
                        <form onSubmit={formik.handleSubmit}>
                            {formInputData.map(({ id, label, type, placeholder, error, message }) => (
                                <FormInput
                                    value={formik.values[id]}
                                    onChange={formik.handleChange}
                                    type={type}
                                    error={error}
                                    message={message}
                                    label={label}
                                    placeholder={placeholder}
                                    id={id}
                                    key={id}
                                />
                            ))}
                            <div className="form-group mt-5 mb-0">
                                <button type="submit" className="btn btn-primary" disabled={!areFieldsChanged}>
                                    <FormattedMessage id="Save" />
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );

    function handleSubmit(values) {
        //prettier-ignore
        console.log(values)
        axios
            .post(
                GLOBAL_URL + 'users/editProfile',
                { ...values, country: 'Armenia' },
                {
                    headers: {
                        authorization: Cookies.get('token'),
                    },
                }
            )
            .then((res) => {
                const { data, error } = res.data;
                if (!error) {
                    toast.success(TOAST_MESSAGES.changeSuccess);
                    setFormErrors([]);
                    setUserData(data);
                } else {
                    setFormErrors(data);
                }
            })
            .catch((e) => console.log(e));
    }
}

const mapDispatchToProps = {
    setUserData,
};

export default connect(null, mapDispatchToProps)(AccountPageEditProfile);
