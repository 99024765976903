// third-party
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

// reducer
import reducers from './';
import { isDevelopmentStage } from '../helpers/general';

export const store = createStore(reducers, compose(applyMiddleware(thunk)));

window.getState = isDevelopmentStage() && store.getState;

export const getStoreState = store.getState;

// function load() {
//     let state;
//
//     try {
//         state = localStorage.getItem('state');
//
//         if (typeof state === 'string') {
//             state = JSON.parse(state);
//         }
//     } catch (error) {
//         // eslint-disable-next-line no-console
//         console.error(error);
//     }
//
//     return state || undefined;
// }
//
// function save() {
//     try {
//         localStorage.setItem('state', JSON.stringify(store.getState()));
//     } catch (error) {
//         // eslint-disable-next-line no-console
//         console.error(error);
//     }
// }

// store.subscribe(() => save());
