// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';

// application
import Footer from './footer';
import Header from './header';
import MobileHeader from './mobile/MobileHeader';
import MobileMenu from './mobile/MobileMenu';
import Quickview from './shared/Quickview';

// pages
import AccountPageLogin from './account/accountPageLogin/AccountPageLogin';
import PageCart from './cart/CartPage';
import ShopPage from './shop/ShopPage';
import ProductPage from './product/ProductPage';
import InfoPage from './site/InfoPage';
import SitePageContactUsAlt from './site/SitePageContactUsAlt';
import SitePageNotFound from './site/SitePageNotFound';
import SitePageTerms from './site/SitePageTerms';
import GiftsPage from './gifts/GiftsPage';
import PartnershipPage from './partnership/PartnershipPage';

// data stubs
import routes from './config/routes';
import PrivateRoute from './hoc/withAuth';
import ForgotPasswordPage from './account/accountPageLogin/ForgotPasswordPage';

function Layout({ match, headerLayout, homeComponent, ...redux }) {
    //from redux
    const { isAuthenticated, locale, configs } = redux;

    //prettier-ignore
    return (
        <>

            <ToastContainer autoClose={4000} hideProgressBar />

            <Quickview />

            <MobileMenu />

            <div className="site">
                <header className="site__header d-lg-none">
                    <MobileHeader />
                </header>

                <header className="site__header d-lg-block d-none">
                    <Header layout={headerLayout} />
                </header>
                <div className="site__body">
                    <Switch>
                        <Route exact path={match.path} component={homeComponent} />
                        <Redirect exact from="/shop" to="/shop/products" />
                        <Redirect exact from="/site" to="/site/about-us" />
                        <Route exact path="/shop/cart" component={PageCart} />
                        {/*<Route exact path="/gifts" component={GiftsPage} />*/}
                        <Route exact path="/site/partnership" component={PartnershipPage} />
                        <Route exact path="/shop/product/:productId" render={(props) => <ProductPage {...props} layout="columnar" />}/>
                        <Route path={['/shop/category/:categoryId', '/shop/opt/:optionId', '/shop/products']} render={(props) => <ShopPage {...props} viewMode="grid" />}/>
                        <Route exact path="/account/login" component={AccountPageLogin} />
                        <Route exact path="/account/forgotPass" component={ForgotPasswordPage} />
                        <Route exact path="/site/about-us" render={(props) => <InfoPage {...props} title="About Us" imageUri={'aboutus'} html={configs[`aboutUsText_${locale}`]} />} />
                        <Route exact path="/site/privacy-policy" render={(props) => <InfoPage {...props} title="Privacy Policy" imageUri={'privacy'} html={configs[`privacyText_${locale}`]} />} />
                        <Route exact path="/site/delivery-info" render={(props) => <InfoPage {...props} title="Delivery Information" imageUri={'delivery'} html={configs[`deliveryText_${locale}`]} />} />
                        <Route exact path="/site/contact-us" component={SitePageContactUsAlt} />
                        <Route exact path="/site/not-found" component={SitePageNotFound} />
                        <Route exact path="/site/terms" component={SitePageTerms} />
                        {routes.authRoutes.map((route) => <PrivateRoute path={route.path} children={route.component} isAuth={isAuthenticated} key={route.path}/>)}
                        <Route component={SitePageNotFound} />
                    </Switch>
                </div>

                <footer className="site__footer">
                    <Footer />
                </footer>
            </div>
        </>
    );
}

const mapStateToProps = ({ isAuthenticated, configs, locale }) => ({
    configs,
    isAuthenticated,
    locale,
});

export default connect(mapStateToProps)(Layout);

Layout.propTypes = {
    /**
     * header layout (default: 'classic')
     * one of ['classic', 'compact']
     */
    headerLayout: PropTypes.oneOf(['default', 'compact']),
    /**
     * home component
     */
    homeComponent: PropTypes.elementType.isRequired,
};

Layout.defaultProps = {
    headerLayout: 'default',
};
