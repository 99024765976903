// react
import React from 'react';
import FormInput from '../shared/FormInput';
import useFormWithErrors from '../hooks/useFormWithErrors';
import { setClass } from '../../helpers/general';
import { FormattedMessage, useIntl } from 'react-intl';

const userData = [
    { label: 'First Name', type: 'text', id: 'first_name', message: '', error: false },
    { label: 'Last Name', type: 'text', id: 'last_name', message: '', error: false },
];

const addressData = [
    {
        label: 'Street Address',
        type: 'text',
        id: 'streetAddress',
        message: '',
        error: false,
    },
    { label: 'Apartment, suite, unit etc.', type: 'text', id: 'apt', message: '', error: false },
    { label: 'Town / City', type: 'text', id: 'city', message: '', error: false },
    { label: 'Postcode / ZIP', type: 'text', id: 'postCode', optional: true, message: '', error: false },
    { label: 'Phone', type: 'text', id: 'phone', message: '', error: false },
];

function FormAddress({ errors, formik }) {
    const userDataWithErrors = useFormWithErrors(userData, errors);
    const addressDataWithErrors = useFormWithErrors(addressData, errors);
    const countryErrorData = errors.find((item) => item.type === 'country');
    const intl = useIntl();

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="form-row">
                {userDataWithErrors.map(({ id, label, type, placeholder, error, message }) => (
                    <FormInput
                        value={formik.values[id]}
                        onChange={formik.handleChange}
                        type={type}
                        error={error}
                        message={message}
                        label={label}
                        placeholder={placeholder}
                        id={id}
                        key={id}
                        wrapperClass={'col-md-6'}
                    />
                ))}
            </div>
            <div className="form-group">
                <label htmlFor="checkout-country">
                    <FormattedMessage id="Country" />
                </label>
                <select
                    name="country"
                    id="country"
                    className={`form-control custom-select form-control-select2 ${setClass(
                        countryErrorData,
                        'is-invalid'
                    )}`}
                    value={formik.values.country}
                    onChange={formik.handleChange}
                >
                    <option value="">{intl.formatMessage({ id: 'Select a country' })}...</option>X
                    <option value="Armenia">Armenia</option>
                </select>
                <div className="invalid-feedback">{countryErrorData && countryErrorData.message}</div>
            </div>
            {addressDataWithErrors.map(({ id, label, type, placeholder, optional, error, message }) => (
                <FormInput
                    value={formik.values[id]}
                    onChange={formik.handleChange}
                    type={type}
                    error={error}
                    message={message}
                    label={label}
                    placeholder={placeholder}
                    id={id}
                    key={id}
                    isOptional={optional}
                />
            ))}
        </form>
    );
}

export default FormAddress;
