import PageCheckout from '../checkout/CheckoutPage';
import PageCheckoutSuccess from '../orderSuccess/PageCheckoutSuccess';
import PageWishlist from '../whishlist/WishlistPage';
import AccountLayout from '../account/AccountLayout';

const routes = {
    authRoutes: [
        {
            path: '/shop/checkout/success',
            name: 'Page checkout success',
            component: PageCheckoutSuccess,
        },
        {
            path: '/shop/checkout',
            name: 'Page checkout',
            component: PageCheckout,
        },
        {
            path: '/shop/wishlist',
            name: 'Page Wishlist',
            component: PageWishlist,
        },
        {
            path: '/account',
            name: 'Account Layout',
            component: AccountLayout,
        },
    ],
};

export default routes;
