// react
import React, { useEffect, useState } from 'react';

// data stubs
import { useFormik } from 'formik';
import axios from 'axios';
import { GLOBAL_URL } from '../config/general';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import FormAddress from '../shared/FormAddress';
import UnipackHelmet from '../shared/UnipackHelmet';
import { FormattedMessage } from 'react-intl';
import { TOAST_MESSAGES } from '../../constants/general';

function AccountPageEditAddress({ address }) {
    const [formErrors, setFormErrors] = useState([]);
    const [areFieldsChanged, setAreFieldsChanged] = useState(false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: address || {
            first_name: '',
            last_name: '',
            country: '',
            streetAddress: '',
            apt: '',
            city: '',
            postCode: '',
            phone: '',
        },
        onSubmit: (values) => {
            handleSubmit(values);
        },
    });

    useEffect(() => {
        const changed = JSON.stringify(formik.values) !== JSON.stringify(address);
        setAreFieldsChanged(changed);
    }, [formik.values]);

    return (
        <div className="card">
            <UnipackHelmet title="Edit Address" />

            <div className="card-header">
                <h5>
                    <FormattedMessage id="Edit Address" />
                </h5>
            </div>
            <div className="card-divider" />
            <div className="card-body">
                <div className="row no-gutters">
                    <div className="col-12 col-lg-10 col-xl-8">
                        <FormAddress errors={formErrors} formik={formik} />
                        <div className="form-group mt-3 mb-0">
                            <button
                                className="btn btn-primary"
                                onClick={formik.submitForm}
                                disabled={!areFieldsChanged}
                            >
                                <FormattedMessage id="Save" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    function handleSubmit(values) {
        //prettier-ignore
        axios.post(
            GLOBAL_URL + 'users/editAddress', values,
            {
                headers: {
                    authorization: Cookies.get('token'),
                },
            }
        ).then((res) => {
            const { data, error } = res.data;
            console.log(res);
            if (!error) {
                toast.success(TOAST_MESSAGES.changeSuccess);
                setFormErrors([]);
            } else {
                setFormErrors(data);
            }
        }).catch(e => console.log(e));
    }
}

const mapStateToProps = ({ user: { shipping_details: address } }) => ({ address });

export default connect(mapStateToProps)(AccountPageEditAddress);
