import { createAction, createReducer } from '../../helpers/redux';
import axios from 'axios';
import { GLOBAL_URL } from '../../components/config/general';
import Cookies from 'js-cookie';
import { setIsAuthenticated } from './isAuthenticatedDucks';
import { deleteUserWishlist, getUserWishlist } from './wishlistDucks';
import { changeLocale } from './localeDucks';
import { deleteCart, getUserCart, setCart } from './cartDucks';
import { getLocalCart, resetLocalCart } from '../../helpers/general';
import {
    resetCustomError,
    resetLoginError,
    resetRegistrationValidationErrors,
    setCustomError,
    setLoginError,
    setRegistrationValidationErrors,
} from './validationDucks';
import { setIsUserLoaded, setIsUserSignedIn } from './loadersDucks';
const SET_USER = 'SET_USER';
const SET_USER_ADDRESS = 'SET_USER_ADDRESS';

export const setUserData = createAction(SET_USER);
export const setUserAddress = createAction(SET_USER_ADDRESS);

function setUser(user) {
    return (dispatch) => {
        Cookies.set('token', user.token);
        dispatch(setUserData(user));
        dispatch(setIsAuthenticated(true));
        dispatch(getUserWishlist());
        dispatch(changeLocale(user.locale));
        dispatch(getUserCart())
            .then(() => resetLocalCart())
            .catch((e) => console.log(e));
    };
}

function removeUser() {
    return (dispatch) => {
        Cookies.remove('token');
        dispatch(setUserData({}));
        dispatch(setIsAuthenticated(false));
        dispatch(deleteUserWishlist());
        dispatch(deleteCart());
    };
}

export function getUser() {
    //prettier-ignore
    return (dispatch) => {
        dispatch(setIsUserLoaded(false))
        const authorization = Cookies.get('token');
        if(!!authorization){
            return axios.post(GLOBAL_URL + 'users/getUserAccount', {}, {
                headers: {
                    authorization,
                }
            }).then((res) => {
                const { data, error } = res.data;
                if (!error) {
                    dispatch(setUser(data));
                } else {
                    dispatch(removeUser());
                }
                dispatch(setIsUserLoaded(true))
            }).catch(e => console.log(e));
        }else {
            dispatch(setCart(JSON.parse(getLocalCart())))
            dispatch(setIsUserLoaded(true))
        }
    };
}

export function signIn(data, loginLocation) {
    return (dispatch) => {
        dispatch(setIsUserSignedIn(false));
        return new Promise((resolve) => {
            axios.post(GLOBAL_URL + 'users/signIn', data).then((res) => {
                const { data, error, message } = res.data;
                dispatch(resetCustomError());
                dispatch(resetRegistrationValidationErrors());
                if (!error) {
                    dispatch(setUser(data));
                    dispatch(resetLoginError());
                    resolve();
                } else {
                    console.log(error, message);
                    dispatch(setLoginError({ location: loginLocation, message }));
                }
                dispatch(setIsUserSignedIn(true));
            });
        });
    };
}

export function signUp(data) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axios.post(GLOBAL_URL + 'users/signUp', data).then((res) => {
                const {
                    data: { error, message, data },
                    status,
                } = res;
                dispatch(resetLoginError());
                if (!error) {
                    dispatch(setUser(data));
                    resolve(data);
                } else {
                    dispatch(setRegistrationValidationErrors(data));
                    if (!data.length) {
                        dispatch(setCustomError(message));
                    }
                }
                if (status !== 200) {
                    reject(message);
                }
            });
        });
    };
}

export function signOut() {
    //prettier-ignore
    return (dispatch) => {
        axios.post(GLOBAL_URL + 'users/signOut', {}, {
            headers: {
                authorization: Cookies.get('token'),
            }
        }).then((res) => {
            const { data, error } = res.data;
            console.log({ error, data });
            if (!error) {
                dispatch(removeUser());
            }
        });
    };
}

export const user = createReducer({}, (state, { value }) => ({
    [SET_USER]: () => value,
    [SET_USER_ADDRESS]: () => ({ ...state, shipping_details: value }),
}));
