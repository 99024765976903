import React from 'react';
import classes from './MainCategories.module.css';
import CategoryCard from '../items/CategoryCard';
import { connect } from 'react-redux';
import { IMAGE_URL } from '../../../config/general';
import { useParams } from 'react-router-dom';
import { getCategoriesBySection } from '../../functions';

function MainCategories({ match, locale, categories }) {
    const { section } = useParams();
    const filteredCategories = getCategoriesBySection(categories, section); //filteredCategories

    //Logic
    return (
        <div className={classes.wrapper}>
            {filteredCategories.map((category) => {
                return (
                    <CategoryCard
                        id={category.id}
                        title={category[`title_${locale}`]}
                        url={IMAGE_URL + category.icon}
                        key={category.id}
                        match={match}
                    />
                );
            })}
        </div>
    );
}

const mapStateToProps = ({ categories, locale }) => ({
    categories,
    locale,
});

export default connect(mapStateToProps)(MainCategories);
