import { createAction, createReducer } from '../../helpers/redux';

const SET_IS_USER_LOADED = 'SET_IS_USER_LOADED';
const SET_IS_CONFIGS_LOADED = 'SET_IS_CONFIGS_LOADED';
const SET_IS_PRODUCT_LOADED = 'SET_IS_PRODUCT_LOADED';
const SET_IS_SHOP_PRODUCTS_LOADED = 'SET_IS_SHOP_PRODUCTS_LOADED';
const SET_IS_CART_LOADED = 'SET_IS_CART_LOADED';
const SET_IS_GIFTS_LOADED = 'SET_IS_GIFTS_LOADED';
const SET_IS_ORDER_HISTORY_LOADED = 'SET_IS_ORDER_HISTORY_LOADED';
const SET_IS_USER_SIGNED_IN = 'SET_IS_USER_SIGNED_IN';
const SET_IS_ORDER_PLACED = 'SET_IS_ORDER_PLACED';

export const setIsUserLoaded = createAction(SET_IS_USER_LOADED);
export const setIsConfigsLoaded = createAction(SET_IS_CONFIGS_LOADED);
export const setIsProductLoaded = createAction(SET_IS_PRODUCT_LOADED);
export const setIsShopProductsLoaded = createAction(SET_IS_SHOP_PRODUCTS_LOADED);
export const setIsCartLoaded = createAction(SET_IS_CART_LOADED);
export const setIsGiftsLoaded = createAction(SET_IS_GIFTS_LOADED);
export const setIsOrderHistoryLoaded = createAction(SET_IS_ORDER_HISTORY_LOADED);
export const setIsUserSignedIn = createAction(SET_IS_USER_SIGNED_IN);
export const setIsOrderPlaced = createAction(SET_IS_ORDER_PLACED);

const defaultState = {
    isUserLoaded: false,
    isConfigsLoaded: false,
    isProductLoaded: false,
    isShopProductsLoaded: false,
    isOrderHistoryLoaded: false,
    isGiftsLoaded: true,
    isCartLoaded: true,
    isUserSignedIn: true,
    isOrderPlaced: true,
};

export const loaders = createReducer(defaultState, (state, { value }) => ({
    [SET_IS_USER_LOADED]: () => ({ ...state, isUserLoaded: value }),
    [SET_IS_CONFIGS_LOADED]: () => ({ ...state, isConfigsLoaded: value }),
    [SET_IS_PRODUCT_LOADED]: () => ({ ...state, isProductLoaded: value }),
    [SET_IS_SHOP_PRODUCTS_LOADED]: () => ({ ...state, isShopProductsLoaded: value }),
    [SET_IS_CART_LOADED]: () => ({ ...state, isCartLoaded: value }),
    [SET_IS_GIFTS_LOADED]: () => ({ ...state, isGiftsLoaded: value }),
    [SET_IS_ORDER_HISTORY_LOADED]: () => ({ ...state, isOrderHistoryLoaded: value }),
    [SET_IS_USER_SIGNED_IN]: () => ({ ...state, isUserSignedIn: value }),
    [SET_IS_ORDER_PLACED]: () => ({ ...state, isOrderPlaced: value }),
}));
