// react
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';

// third-party
import PropTypes from 'prop-types';

// application
import PageHeader from '../shared/PageHeader';
import Product from '../shared/Product';
import ProductTabs from './components/ProductTabs';

// blocks
import BlockProductsCarousel from '../blocks/BlockProductsCarousel';

// data stubs
import { getTitleLocale } from '../../helpers/general';
import { getRelatedProducts, getSingleProduct } from '../../helpers/api';
import { FormattedMessage } from 'react-intl';
import { setIsProductLoaded } from '../../redux/ducks/loadersDucks';
import { useLocation } from 'react-router-dom';

function ProductPage({ layout, sidebarPosition, match, ...redux }) {
    const { locale, setIsProductLoaded } = redux;
    const [productWithReviews, setProductWithReviews] = useState({ product: {}, reviews: [] });
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [isProductAvailable, setIsProductAvailable] = useState(true);
    const location = useLocation();

    useLayoutEffect(() => {
        if (!!location.product) {
            setProductWithReviews({
                product: { ...location.product, pictures: [{ url: location.product.avatar }] },
                reviews: [],
            });
        }
    }, [location.product]);

    useEffect(() => {
        if (match.params.productId) {
            setIsProductLoaded(false);
            getSingleProduct(match.params.productId, setIsProductLoaded).then((product) => {
                setIsProductAvailable(!!product.product.id);
                setProductWithReviews(product);
                setIsProductLoaded(true);
            });
        }
    }, [match.params.productId, setIsProductLoaded]);

    useEffect(() => {
        if (productWithReviews.product.id) {
            getRelatedProducts(productWithReviews.product, setIsProductLoaded).then((products) =>
                setRelatedProducts(products)
            );
        }
    }, [productWithReviews, setIsProductLoaded]);

    const breadcrumb = [
        { title: 'Home', url: '' },
        {
            title: productWithReviews.product[getTitleLocale(locale)],
            url: '',
        },
    ];

    return (
        <>
            {!!productWithReviews.product.id && (
                <>
                    <PageHeader breadcrumb={breadcrumb} />
                    <div className="block">
                        <div className="container">
                            <Product product={productWithReviews.product} layout={layout} />
                            <ProductTabs product={productWithReviews.product} reviews={productWithReviews.reviews} />
                        </div>
                    </div>
                    <BlockProductsCarousel
                        title={<FormattedMessage id="Related Products" />}
                        layout="grid-4"
                        rows={1}
                        products={relatedProducts}
                    />
                </>
            )}
            {!isProductAvailable && (
                <div className="block">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-5 col-lg-6 col-md-8">
                                <div className="card flex-grow-1 mb-0 mt-lg-4 mt-md-3 mt-2">
                                    <div className="card-body">
                                        <div className="card-title text-center">
                                            <h1 className="pt-lg-0 pt-2">
                                                <FormattedMessage id="Sorry" />
                                            </h1>
                                        </div>
                                        <p className="mb-4 pt-2" style={{ textAlign: 'center' }}>
                                            <FormattedMessage id="This product is not available" />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

ProductPage.propTypes = {
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(['standard', 'sidebar', 'columnar', 'quickview']),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(['start', 'end']),
};

ProductPage.defaultProps = {
    layout: 'standard',
    sidebarPosition: 'start',
};

const mapStateToProps = ({ locale }) => ({
    locale,
});

const mapDispatchToProps = {
    setIsProductLoaded,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductPage);
