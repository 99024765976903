// react
import React from 'react';

// application
import FooterContacts from './FooterContacts';
import FooterLinks from './FooterLinks';
import FooterNewsletter from './FooterNewsletter';

// data stubs
import { FormattedMessage } from 'react-intl';

export default function Footer() {
    const informationLinks = [
        { title: <FormattedMessage id="About Us" />, url: '/site/about-us' },
        { title: <FormattedMessage id="Delivery Information" />, url: '/site/delivery-info' },
        { title: <FormattedMessage id="Privacy Policy" />, url: '/site/privacy-policy' },
        { title: <FormattedMessage id="Contact Us" />, url: '/site/contact-us' },
    ];

    const accountLinks = [
        { title: <FormattedMessage id="Store Location" />, url: '/site/contact-us' },
        { title: <FormattedMessage id="Order History" />, url: '/account/orders' },
        { title: <FormattedMessage id="Wish List" />, url: '/shop/wishlist' },
        // { title: <FormattedMessage id="Gifts" />, url: '/gifts' },
    ];

    return (
        <div className="site-footer">
            <div className="container">
                <div className="site-footer__widgets">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4">
                            <FooterContacts />
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <FooterLinks title=<FormattedMessage id="Information" /> items={informationLinks} />
                        </div>
                        <div className="col-12 col-md-12 col-lg-4">
                            <FooterNewsletter />
                        </div>
                    </div>
                </div>

                <div className="site-footer__bottom">
                    <div className="site-footer__copyright">
                        <FormattedMessage id="Created by" /> Switch
                    </div>
                </div>
            </div>
        </div>
    );
}
