import React from 'react';
import classes from './ProductCard.module.css';
import Currency from './../ProductsPage/Currency';
import CurrencyGlobal from '../../../shared/Currency';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

export default function ProductCard({
    url,
    description,
    title,
    id,
    price,
    onClick,
    type,
    articleId,
    saleDate,
    discountedPrice,
    wholesalePrice,
}) {
    let prices;
    let pricesWeb;
    let wPrice;

    if (wholesalePrice?.length) {
        const wholeSaleValues = wholesalePrice.split('/');
        wPrice = (
            <span style={{ fontWeight: 'bold' }}>
                {wholeSaleValues[0]}
                <FormattedMessage id="AndMore" />
                <Currency classNames={'letterSpacing0'} value={wholeSaleValues[1]} />
            </span>
        );
    }

    if (!!Number(discountedPrice)) {
        prices = (
            <>
                <span className="product__new-price">
                    <Currency value={discountedPrice} />
                </span>{' '}
                <span className="product__old-price">
                    <Currency value={price} />
                </span>
            </>
        );
        pricesWeb = (
            <button className={classes.priceButton} style={{ backgroundColor: '#e1dfdf' }}>
                <span className={classes.price}>
                    <span className="product__new-price">
                        {!!saleDate && (
                            <p className="product__sale-until-date" style={{ fontSize: '12px', marginBottom: '0px' }}>
                                <FormattedMessage id="SaleUntil" />
                                {': '}
                                {moment(saleDate).format('DD-MM-YYYY')}
                            </p>
                        )}
                        <CurrencyGlobal value={discountedPrice} />
                    </span>{' '}
                    <span className="product__old-price" style={{ fontSize: '14px' }}>
                        <CurrencyGlobal value={price} />
                    </span>
                </span>
            </button>
        );
    } else {
        prices = (
            <span style={{ fontWeight: 'bold' }}>
                <Currency value={price} />
            </span>
        );
        pricesWeb = (
            <button className={classes.priceButton}>
                <span className={classes.price}>
                    <CurrencyGlobal value={price} />
                </span>
            </button>
        );
    }
    if (type === 'row') {
        return (
            <div id={`product_${id}`} className={classes.containerRow} onClick={onClick} style={{ cursor: 'pointer' }}>
                <div className={classes.imageWrapperRow}>
                    <div style={{ backgroundImage: `url(${url})` }} className={classes.backgroundImageRow}></div>
                </div>
                <div className={classes.textContainerRow}>
                    <div>
                        <span className={classes.articleId}>
                            <span style={{ color: '#ff6632' }}>
                                <FormattedMessage id="SKU" /> :{' '}
                            </span>{' '}
                            <span style={{ fontWeight: 'bold', color: 'black' }}>{articleId}</span>
                        </span>
                        <span className={classes.titleRow}>{title}</span>
                        <span className={classes.descriptionRow}>{description} </span>
                    </div>
                    <div className={classes.priceContainerRow}>
                        <div className={classes.priceRow}>{prices}</div>
                    </div>
                    <span className={classes.wholePriceRow}> {wPrice}</span>
                </div>
            </div>
        );
    }
    return (
        <div id={`product_${id}`} className={classes.container} onClick={onClick}>
            <div className={classes.imageWrapper}>
                <div style={{ backgroundImage: `url(${url})` }} className={classes.backgroundImage}></div>
            </div>
            <div className={classes.textContainer}>
                <span className={classes.articleId}>
                    <span style={{ color: '#ff6632' }}>
                        <FormattedMessage id="SKU" /> :{' '}
                    </span>{' '}
                    <span style={{ fontWeight: 'bold', color: 'black' }}>{articleId}</span>
                </span>
                <span className={classes.title}>{title}</span>
                <span className={classes.description}>{description}</span>
                <span className={classes.priceText}>{prices}</span>
                <span className={classes.wholePrice}>{wPrice}</span>
            </div>
            {pricesWeb}
        </div>
    );
}
