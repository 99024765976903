// react
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

// third-party
import PropTypes from 'prop-types';

// data stubs
import BlockProductsCarousel from './BlockProductsCarousel';

export default function BlockTabbedProductsCarousel(props) {
    const {
        homePageProducts: { is_featured, is_new, is_sale },
    } = props;

    const [products, setProducts] = useState([]);

    const [groups, setGroups] = useState([
        { id: 'all', name: <FormattedMessage id="All products" />, current: false },
        { id: 'is_new', name: <FormattedMessage id="New" />, current: false },
        { id: 'is_sale', name: <FormattedMessage id="Sale" />, current: true },
        { id: 'is_featured', name: <FormattedMessage id="Featured" />, current: false },
    ]);

    useEffect(() => {
        const currentGroup = groups.find((group) => group.current);
        props.setProductCarouselTitle(currentGroup.name);

        let newItemsArray = [];
        if (currentGroup.id === 'all') {
            newItemsArray = [...is_featured, ...is_new, ...is_sale];
        } else {
            newItemsArray = props.homePageProducts[currentGroup.id];
        }
        setProducts(newItemsArray);
    }, [groups, props.homePageProducts]);

    return <BlockProductsCarousel {...props} {...{ products, groups }} onGroupClick={handleChangeGroup} />;

    function handleChangeGroup(newCurrentGroup) {
        const currentGroup = groups.find((group) => group.current);

        if (currentGroup && currentGroup.id === newCurrentGroup.id) {
            return;
        }
        setGroups(groups.map((group) => ({ ...group, current: group.id === newCurrentGroup.id })));
    }
}

BlockTabbedProductsCarousel.propTypes = {
    layout: PropTypes.oneOf(['grid-4', 'grid-4-sm', 'grid-5', 'horizontal']),
    rows: PropTypes.number,
    withSidebar: PropTypes.bool,
};

BlockTabbedProductsCarousel.defaultProps = {
    layout: 'grid-4',
    rows: 1,
    withSidebar: false,
};
