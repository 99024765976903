// react
import React, { useState, useEffect } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

// application
import AsyncAction from './AsyncAction';
import Currency from './Currency';
import InputNumber from './InputNumber';
import ProductGallery from './ProductGallery';
import Rating from './Rating';
import { cartAddItem } from '../../redux/ducks/cartDucks';
import { wishlistAddItem } from '../../redux/ducks/wishlistDucks';
import { getTitleLocale } from '../../helpers/general';
import WishlistButton from './wishlistButton';
import { FormattedMessage } from 'react-intl';

function Product({ product, layout, ...redux }) {
    const { cartAddItem, locale } = redux;
    const [quantity, setQuantity] = useState(1);

    useEffect(() => {
        setQuantity(1);
    }, [product.id]);

    let prices;

    if (!!Number(product.discounted_price)) {
        prices = (
            <>
                <span className="product__new-price">
                    {!!product.sale_date && (
                        <p className="product__sale-until-date">
                            <FormattedMessage id="SaleUntil" />
                            {': '}
                            {moment(product.sale_date).format('DD-MM-YYYY')}
                        </p>
                    )}
                    <Currency value={product.discounted_price} />
                </span>{' '}
                <span className="product__old-price">
                    <Currency value={product.price} />
                </span>
            </>
        );
    } else {
        prices = <Currency value={product.price} />;
    }

    let count = !!product.params && product.params.Count ? Math.round(product.params.Count) : 1;

    return (
        <div className={`product product--layout--${layout}`}>
            <div className="product__content">
                <ProductGallery layout={layout} images={product.pictures || []} />

                <div className="product__info">
                    <div className="product__wishlist-compare">
                        <WishlistButton product={product} />
                    </div>
                    <h1 className="product__name">{product[getTitleLocale(locale)]}</h1>
                    <div className="product__rating">
                        <div className="product__rating-stars">
                            <Rating value={product.rate} />
                        </div>
                        <div className="product__rating-legend">
                            <span>
                                {product.reviews} {<FormattedMessage id="Reviews" />}
                            </span>
                        </div>
                    </div>
                    <div className="product__description">{product[`description_short_${locale}`]}</div>
                    <ul className="product__features">
                        {!!product.params ? (
                            Object.entries(product.params).map(([key, value], i) => {
                                if (key === 'Count') {
                                    return (
                                        <li key={i}>
                                            <FormattedMessage id="Min Count" />: {Math.round(value)}
                                        </li>
                                    );
                                }
                                if (key === 'Name') {
                                    return (
                                        <li key={i}>
                                            <FormattedMessage id="Type" /> : {value}
                                        </li>
                                    );
                                }
                                return '';
                            })
                        ) : (
                            <>
                                <li>
                                    <FormattedMessage id="Type" />: <FormattedMessage id="Single" />
                                </li>
                                <li>
                                    <FormattedMessage id="Count" />: 1
                                </li>
                            </>
                        )}
                    </ul>
                    <ul className="product__meta">
                        <li className="product__meta-availability">
                            <FormattedMessage id="Availability" />:{' '}
                            <span className="text-success">
                                <FormattedMessage id="In Stock" />
                            </span>
                        </li>
                        {!!product.company && (
                            <li>
                                <FormattedMessage id="Company" />:<Link to="/">{product.company}</Link>
                            </li>
                        )}
                        <li>
                            <FormattedMessage id="SKU" />: {product.article_id}
                        </li>
                    </ul>
                </div>

                <div className="product__sidebar">
                    <div className="product__availability">
                        <FormattedMessage id="Availability" />:{' '}
                        <span className="text-success">
                            <FormattedMessage id="In Stock" />
                        </span>
                    </div>

                    <div className="product__prices">{prices}</div>

                    <form className="product__options">
                        <div className="form-group product__option">
                            <div className="product__option-label">
                                <FormattedMessage id="Type" />
                            </div>
                            <div className="input-radio-label">
                                <div className="input-radio-label__list">
                                    <label>
                                        <input type="text" name="material" disabled={true} />
                                        <span>
                                            {product.params && product.params.Name ? (
                                                product.params.Name
                                            ) : (
                                                <FormattedMessage id="Single" />
                                            )}
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group product__option">
                            <div className="product__option-label">
                                <FormattedMessage id="Min Count" />
                            </div>
                            <div className="input-radio-label">
                                <div className="input-radio-label__list">
                                    <label>
                                        <input type="text" name="material" disabled={true} />
                                        <span>{count}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group product__option">
                            <label htmlFor="product-quantity" className="product__option-label">
                                <FormattedMessage id="Quantity" />
                            </label>
                            <div className="product__actions">
                                <div className="product__actions-item" style={{ display: 'flex' }}>
                                    <InputNumber
                                        id="product-quantity"
                                        aria-label="Quantity"
                                        className="product__quantity"
                                        size="lg"
                                        min={1}
                                        value={quantity}
                                        onChange={handleChangeQuantity}
                                        onBlur={handleBlur}
                                    />
                                    {count > 1 && (
                                        <div className="input-radio-label chosen">
                                            <span className="cross1"></span>
                                            <span className="cross2"></span>
                                            <p className="input-radio-label-count">{count}</p>
                                        </div>
                                    )}
                                </div>
                                <div className="product__actions-item product__actions-item--addtocart">
                                    <AsyncAction
                                        action={() => cartAddItem(product, quantity)}
                                        render={({ run, loading }) => (
                                            <button
                                                type="button"
                                                onClick={run}
                                                className={classNames('btn btn-primary btn-lg', {
                                                    'btn-loading': loading,
                                                })}
                                                disabled={Number(quantity) < 1}
                                            >
                                                <FormattedMessage id="Add To Cart" />
                                            </button>
                                        )}
                                    />
                                </div>
                                <div className="product__actions-item product__actions-item--wishlist">
                                    <WishlistButton product={product} />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );

    function handleChangeQuantity(e) {
        if (typeof e === 'number') {
            setQuantity(e);
        } else {
            let quantity = e.target.value;
            if (!!quantity) {
                quantity = Number(quantity);
            }
            setQuantity(quantity);
        }
    }

    function handleBlur() {
        if (!quantity) {
            setQuantity(1);
        }
    }
}

Product.propTypes = {
    /** product object */
    product: PropTypes.object.isRequired,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(['standard', 'sidebar', 'columnar', 'quickview']),
};

Product.defaultProps = {
    layout: 'standard',
};

const mapStateToProps = ({ locale }) => ({
    locale,
});

const mapDispatchToProps = {
    cartAddItem,
    wishlistAddItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);
