import axios from 'axios';
import { GLOBAL_URL } from '../components/config/general';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

export function changeLanguage(locale) {
    axios
        .post(
            GLOBAL_URL + 'users/updateUserLocale',
            { locale },
            {
                headers: {
                    authorization: Cookies.get('token'),
                },
            }
        )
        .then((res) => {
            const { error, message } = res.data;
            if (error) {
                toast.error(message);
            }
        })
        .catch((e) => console.log(e));
}
