import React, { useEffect } from 'react';
import GiftItem from '../shared/GiftItem';
import { connect } from 'react-redux';
import { getGifts } from '../../redux/ducks/giftsDucks';
import { FormattedMessage } from 'react-intl';

function GiftsPage({ currency, gifts, bonus, isAuthenticated, getGifts }) {
    const { symbol } = currency;

    useEffect(() => {
        getGifts();
    }, []);

    return (
        <>
            {!!gifts.length ? (
                <div className="container">
                    {/*<h3 className="row justify-content-center align-items-center mt-4 color-bonus">*/}
                    {/*    <FormattedMessage id="Bonus" />: {isAuthenticated ? bonus : 0}*/}
                    {/*</h3>*/}
                    <div className="row mb-4">
                        {gifts.map(({ image }) => {
                            return (
                                <div className="col-md-6 mt-3 mb-3">
                                    <GiftItem imagePath={image} key={image} />
                                </div>
                            );
                        })}
                    </div>
                </div>
            ) : (
                <div style={{ position: 'absolute', left: '50%', top: '35%' }}>
                    <div className="mt-5 spinner-grow text-dark d-flex m-auto" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )}
        </>
    );
}

const mapStateToProps = ({ currency, gifts, user: { bonus }, isAuthenticated }) => ({
    currency,
    gifts,
    bonus,
    isAuthenticated,
});

const mapDispatchToProps = {
    getGifts,
};

export default connect(mapStateToProps, mapDispatchToProps)(GiftsPage);
