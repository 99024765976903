// react
import React from 'react';

// third-party
import InputRange from 'react-input-range';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// application
import Currency from '../shared/Currency';
import { updateFilterPrice } from '../../redux/ducks/filtersDucks';
import { FormattedMessage } from 'react-intl';

export function FilterPrice(props) {
    //redux
    const { updateFilterPrice } = props;

    const { step, from: propsFrom, to: propsTo, min, max } = props;

    let from = Math.max(propsFrom || min, min);
    let to = Math.min(propsTo || max, max);

    return (
        <div className="filter-price">
            <div className="filter-price__slider" dir="ltr">
                <InputRange
                    minValue={min}
                    maxValue={max}
                    value={{ min: from, max: to }}
                    step={step}
                    onChange={handleChange}
                />
            </div>
            <div className="filter-price__title">
                <FormattedMessage id="Price" />:{' '}
                <span className="filter-price__min-value">
                    <Currency value={from} />
                </span>
                {' – '}
                <span className="filter-price__max-value">
                    <Currency value={to} />
                </span>
            </div>
        </div>
    );

    function handleChange(value) {
        const { min: from, max: to } = value;
        updateFilterPrice({ from, to });
    }
}

FilterPrice.propTypes = {
    from: PropTypes.number,
    to: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    /** current locale */
    locale: PropTypes.string,
};

FilterPrice.defaultProps = {
    from: undefined,
    to: undefined,
    min: 0,
    max: 100,
    step: 1,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

const mapDispatchToProps = {
    updateFilterPrice,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterPrice);
