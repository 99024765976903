import { createAction, createReducer } from '../../helpers/redux';

const SET_REGISTRATION_VALIDATION_SCHEMA = 'SET_REGISTRATION_VALIDATION_SCHEMA';
const RESET_REGISTRATION_VALIDATION_SCHEMA = 'RESET_REGISTRATION_VALIDATION_SCHEMA';
const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR';
const RESET_LOGIN_ERROR = 'RESET_LOGIN_ERROR';
const SET_CUSTOM_ERROR_MESSAGE = 'SET_CUSTOM_ERROR_MESSAGE';
const RESET_CUSTOM_ERROR_MESSAGE = 'RESET_CUSTOM_ERROR_MESSAGE';
const SET_RECOVERY_ERROR = 'SET_RECOVERY_ERROR';
const RESET_RECOVERY_ERROR = 'RESET_RECOVERY_ERROR';

export const setRegistrationValidationErrors = createAction(SET_REGISTRATION_VALIDATION_SCHEMA);
export const resetRegistrationValidationErrors = createAction(RESET_REGISTRATION_VALIDATION_SCHEMA);
export const setLoginError = createAction(SET_LOGIN_ERROR);
export const resetLoginError = createAction(RESET_LOGIN_ERROR);
export const setCustomError = createAction(SET_CUSTOM_ERROR_MESSAGE);
export const resetCustomError = createAction(RESET_CUSTOM_ERROR_MESSAGE);
export const setRecoveryError = createAction(SET_RECOVERY_ERROR);
export const resetRecoveryError = createAction(RESET_RECOVERY_ERROR);

export const registrationErrors = createReducer([], (state, { value }) => ({
    [SET_REGISTRATION_VALIDATION_SCHEMA]: () => value,
    [RESET_REGISTRATION_VALIDATION_SCHEMA]: () => [],
}));

export const loginError = createReducer('', (state, { value }) => ({
    [SET_LOGIN_ERROR]: () => value,
    [RESET_LOGIN_ERROR]: () => '',
}));

export const recoveryError = createReducer({}, (state, { value }) => ({
    [SET_RECOVERY_ERROR]: () => value,
    [RESET_RECOVERY_ERROR]: () => '',
}));

export const customErrorMessage = createReducer('', (state, { value }) => ({
    [SET_CUSTOM_ERROR_MESSAGE]: () => value,
    [RESET_CUSTOM_ERROR_MESSAGE]: () => '',
}));
