import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

function NewAddressCard() {
    return (
        <div className="dashboard__address card address-card address-card--featured">
            <Link to={'/account/edit-address'} style={{ display: 'contents' }}>
                <div className="card-body profile-card__body">
                    <div className="addresses-list__plus" />
                    <div className="btn btn-secondary btn-sm">
                        <FormattedMessage id="Add New Address" />
                    </div>
                </div>
            </Link>
        </div>
    );
}

export default NewAddressCard;
