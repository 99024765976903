// react
import React, { useEffect, useState } from 'react';

// third-party

// application
import PageHeader from '../../shared/PageHeader';
import UnipackHelmet from '../../shared/UnipackHelmet';
import axios from 'axios';
import { GLOBAL_URL } from '../../config/general';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { TOAST_MESSAGES } from '../../../constants/general';
import { resetRecoveryError, setRecoveryError } from '../../../redux/ducks/validationDucks';
import FormInput from '../../shared/FormInput';
import DangerAlert from './components/DangerAlert';
import useOnUnmount from '../../hooks/useOnUnmount';

function ForgotPasswordPage({ history, ...redux }) {
    const { locale, isAuthenticated, recoveryError, setRecoveryError, resetRecoveryError } = redux;
    useEffect(() => {
        if (isAuthenticated) {
            history.push('/');
        }
    }, [isAuthenticated]);
    const [email, setEmail] = useState('');
    const [isChangePassSuccess, setIsChangePassSuccess] = useState(false);
    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'Track Order', url: '' },
    ];

    useOnUnmount(resetRecoveryError);

    return (
        <>
            <UnipackHelmet title="Forgot Password" />

            <PageHeader breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-5 col-lg-6 col-md-8">
                            <div className="card flex-grow-1 mb-0 mt-lg-4 mt-md-3 mt-2">
                                <div className="card-body">
                                    <div className="card-title text-center">
                                        <h1 className="pt-lg-0 pt-2">
                                            {isChangePassSuccess ? (
                                                <FormattedMessage id="Success" />
                                            ) : (
                                                <FormattedMessage id="Forgot Password" />
                                            )}
                                        </h1>
                                    </div>
                                    <p className="mb-4 pt-2" style={{ textAlign: isChangePassSuccess && 'center' }}>
                                        {isChangePassSuccess ? (
                                            <FormattedMessage id="Please check your email for your password" />
                                        ) : (
                                            <FormattedMessage id="Enter the email address associated with your Unipack account." />
                                        )}
                                    </p>
                                    {!isChangePassSuccess && (
                                        <form>
                                            <FormInput
                                                id={'track-email'}
                                                placeholder={'Email address'}
                                                label={'Email address'}
                                                value={email}
                                                type={'email'}
                                                error={false}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                            <DangerAlert
                                                isVisible={!!recoveryError.message}
                                                message={recoveryError.message}
                                            />
                                            <div className="pt-3">
                                                <button
                                                    className="btn btn-primary btn-lg btn-block"
                                                    onClick={handleContinueButton}
                                                >
                                                    <FormattedMessage id="Continue" />
                                                </button>
                                            </div>
                                        </form>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

    function handleContinueButton(e) {
        e.preventDefault();
        axios
            .post(GLOBAL_URL + 'users/passwordForgot', { email, locale })
            .then((res) => {
                const { error, data } = res.data;
                if (!error) {
                    setIsChangePassSuccess(true);
                    toast.success(TOAST_MESSAGES.changeSuccess);
                } else {
                    setRecoveryError(...data);
                }
            })
            .catch((e) => console.log(e));
    }
}

const mapDispatchToProps = {
    resetRecoveryError,
    setRecoveryError,
};

const mapStateToProps = ({ locale, isAuthenticated, recoveryError }) => ({
    locale,
    isAuthenticated,
    recoveryError,
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);
