import { combineReducers } from 'redux';

// reducers
import { cart } from './ducks/cartDucks';
import { currency } from './ducks/currencyDucks';
import { locale } from './ducks/localeDucks';
import { mobileMenu } from './ducks/mobileMenuDucks';
import { quickview } from './ducks/quickviewDucks';
import { tempQuickView } from './ducks/tempQuickViewDucks';
import { banners, categories, categoriesMenu, configs } from './ducks/configsDucks';
import { homePageProducts } from './ducks/homePageDataDucks';
import { user } from './ducks/userDucks';
import { isAuthenticated } from './ducks/isAuthenticatedDucks';
import { shopProductsData } from './ducks/shopProductsDucks';
import { filters } from './ducks/filtersDucks';
import { loaders } from './ducks/loadersDucks';
import { wishlist } from './ducks/wishlistDucks';
import { customErrorMessage, loginError, recoveryError, registrationErrors } from './ducks/validationDucks';
import { sidebar } from './ducks/sidebarDucks';
import { gifts } from './ducks/giftsDucks';

export default combineReducers({
    banners,
    categories,
    categoriesMenu,
    cart,
    configs,
    currency,
    customErrorMessage,
    filters,
    gifts,
    homePageProducts,
    isAuthenticated,
    loaders,
    locale,
    loginError,
    mobileMenu,
    quickview,
    tempQuickView,
    recoveryError,
    registrationErrors,
    shopProductsData,
    sidebar,
    user,
    wishlist,
});
