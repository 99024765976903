import classes from './CategoryCard.module.css';
import React from 'react';
import { Link } from 'react-router-dom';

export default function CategoryCard({ url, title, id, match }) {
    const section = match.params.section;
    return (
        <Link to={`/menu/${section}/categories/${id}`}>
            <div className={classes.container}>
                {/*<img className={classes.backgroundImage} src={url} alt="Card Background"/>*/}
                <div style={{ backgroundImage: `url(${url})` }} className={classes.backgroundImage}></div>
                <div className={classes.textContainer}>
                    <span className={classes.text}>{title}</span>
                </div>
            </div>
        </Link>
    );
}
