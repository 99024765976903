// react
import React from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { changeLanguage } from '../../../../../api/requests';
import { FormattedMessage } from 'react-intl';
import { Cross20Svg } from '../../../../../svg';
import MobileLinks from '../../../../mobile/MobileLinks';
import { mobileMenuClose } from '../../../../../redux/ducks/mobileMenuDucks';
import { changeLocale } from '../../../../../redux/ducks/localeDucks';

const mobileMenuLinks = [
    {
        type: 'button',
        label: 'Language',
        icons: [
            'images/languages/languageButton-3.png',
            'images/languages/languageButton-1.png',
            'images/languages/languageButton-2.png',
        ],
        children: [
            { type: 'button', label: 'Armenian', data: { type: 'language', locale: 'am' } },
            { type: 'button', label: 'English', data: { type: 'language', locale: 'en' } },
            { type: 'button', label: 'Russian', data: { type: 'language', locale: 'ru' } },
        ],
    },
];

function MobileMenu({ ...redux }) {
    const { locale, mobileMenuState, closeMobileMenu, changeLocale, isAuthenticated } = redux;

    const classes = classNames('mobilemenu', {
        'mobilemenu--open': mobileMenuState.open,
    });

    const handleItemClick = (item) => {
        if (item.data) {
            if (item.data.type === 'language') {
                isAuthenticated && changeLanguage(item.data.locale);
                changeLocale(item.data.locale);
                closeMobileMenu();
            }
        }
        if (item.type === 'link') {
            closeMobileMenu();
        }
    };

    return (
        <div className={classes}>
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
            <div className="mobilemenu__backdrop" onClick={closeMobileMenu} />
            <div className="mobilemenu__body">
                <div className="mobilemenu__header">
                    <div className="mobilemenu__title">
                        <FormattedMessage id={'Menu'} />
                    </div>
                    <button type="button" className="mobilemenu__close" onClick={closeMobileMenu}>
                        <Cross20Svg />
                    </button>
                </div>
                <div className="mobilemenu__content">
                    <MobileLinks
                        links={mobileMenuLinks}
                        locale={locale}
                        onItemClick={handleItemClick}
                        mobileMenuState={mobileMenuState}
                    />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({ mobileMenu, locale }) => ({
    mobileMenuState: mobileMenu,
    locale,
});

const mapDispatchToProps = {
    closeMobileMenu: mobileMenuClose,
    changeLocale: changeLocale,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MobileMenu));
