// react
import React from 'react';

// third-party
import { Link } from 'react-router-dom';

// data stubs
import UnipackHelmet from '../shared/UnipackHelmet';
import { FormattedMessage } from 'react-intl';

function SitePageNotFound() {
    return (
        <div className="block">
            <UnipackHelmet title="404 Page Not Found" />

            <div className="container">
                <div className="not-found">
                    <div className="not-found__404">
                        <FormattedMessage id="Oops! Error 404" />
                    </div>

                    <div className="not-found__content">
                        <h1 className="not-found__title">
                            <FormattedMessage id="Page Not Found" />
                        </h1>

                        <p className="not-found__text">
                            <FormattedMessage id="We can't seem to find the page you're looking for." />
                        </p>

                        <Link to="/" className="btn btn-secondary btn-sm">
                            <FormattedMessage id="Go To Home Page" />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SitePageNotFound;
