// react
import React from 'react';

// data stubs
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

function FooterContacts({ locale, configs }) {
    const { address, email, phone, workingHours, contactUsText } = configs;

    return (
        <div className="site-footer__widget footer-contacts">
            <h5 className="footer-contacts__title">
                <FormattedMessage id="Contact Us" />
            </h5>
            {!Object.entries(configs).length ? (
                <div className="d-flex m-auto spinner-border text-danger" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            ) : (
                <>
                    <div className="footer-contacts__text">
                        {contactUsText[locale]}
                    </div>

                    <ul className="footer-contacts__contacts">
                        <li>
                            <i className="footer-contacts__icon fas fa-globe-americas" />
                            {address[locale]}
                        </li>
                        <li>
                            <a href={`mailto:${email}`}>
                                <i className="footer-contacts__icon far fa-envelope" />
                                {email}
                           </a>
                        </li>
                        <li>
                           <a href={`tel:${phone}`}>
                               <i className="footer-contacts__icon fas fa-mobile-alt" />
                               {phone}
                           </a>
                        </li>
                        <li>
                            <i className="footer-contacts__icon far fa-clock" />
                            {workingHours[locale]}
                        </li>
                    </ul>
                </>
            )}
        </div>
    );
}

const mapStateToProps = ({ locale, configs }) => ({
    locale,
    configs,
});

export default connect(mapStateToProps)(FooterContacts);
