// react
import React from 'react';

// third-party
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';

// application
import Product from './Product';
import { tempQuickViewClose } from '../../../../redux/ducks/tempQuickViewDucks';
import { Cross20Svg } from '../../../../svg';

function ProductPreview({ product, open, tempQuickViewClose }) {
    let productView;

    if (product !== null) {
        productView = <Product product={product} layout="quickview" />;
    }

    return (
        <Modal isOpen={open} toggle={tempQuickViewClose} centered size="xl">
            <div className="quickview">
                <button className="quickview__close" type="button" onClick={tempQuickViewClose}>
                    <Cross20Svg />
                </button>

                {productView}
            </div>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    open: state.tempQuickView.open,
    product: state.tempQuickView.product,
});

const mapDispatchToProps = {
    tempQuickViewClose,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductPreview);
