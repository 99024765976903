// react
import React, { useEffect } from 'react';

// application
import PageHeader from '../../shared/PageHeader';

// data stubs
import LoginForm from './components/LoginForm';
import RegisterForm from './components/RegisterForm';
import { connect } from 'react-redux';
import UnipackHelmet from '../../shared/UnipackHelmet';

function AccountPageLogin({ isAuthenticated, history }) {
    useEffect(() => {
        if (isAuthenticated) {
            history.push('/');
        }
    }, [isAuthenticated]);
    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'My Account', url: '' },
    ];

    return (
        <>
            <UnipackHelmet title="Login" />

            <PageHeader header="My Account" breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="row">
                        <LoginForm />
                        <RegisterForm />
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = ({ isAuthenticated }) => ({
    isAuthenticated,
});

export default connect(mapStateToProps)(AccountPageLogin);
