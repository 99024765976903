import React from 'react';
import { IMAGE_URL } from '../config/general';

function GiftItem({ imagePath }) {
    return (
        <div className="card border-0">
            <img className="card-img-top" alt="Card image cap" src={IMAGE_URL + imagePath} />
        </div>
    );
}

export default GiftItem;
