import { createAction, createReducer } from '../../helpers/redux';
import axios from 'axios';
import { GLOBAL_URL } from '../../components/config/general';
import { setIsShopProductsLoaded } from './loadersDucks';

const SET_SHOP_PRODUCTS_DATA = 'SET_SHOP_PRODUCTS_DATA';
export const setShopProductsData = createAction(SET_SHOP_PRODUCTS_DATA);

export function getShopProductsData(options) {
    return (dispatch) => {
        dispatch(setIsShopProductsLoaded(false));
        axios
            .post(GLOBAL_URL + 'products/get', options)
            .then((res) => {
                let { error, data } = res.data;
                if (!error) {
                    dispatch(setShopProductsData(data));
                }
                dispatch(setIsShopProductsLoaded(true));
            })
            .catch((e) => console.log(e));
    };
}

const initialState = { products: [], count: 0 };

export const shopProductsData = createReducer(initialState, (state, { value }) => ({
    [SET_SHOP_PRODUCTS_DATA]: () => value,
}));
