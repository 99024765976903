// react
import React, { useState } from 'react';
import { connect } from 'react-redux';

// third-party
import classNames from 'classnames';

// application
import ProductTabDescription from './ProductTabDescription';
import ProductTabReviews from './ProductTabReviews';
import { FormattedMessage } from 'react-intl';

function ProductTabs({ withSidebar, product, reviews, ...redux }) {
    const { locale } = redux;
    const [currentTab, setCurrentTab] = useState('description');

    const classes = classNames('product-tabs', {
        'product-tabs--layout--sidebar': withSidebar,
    });

    const tabs = [
        {
            key: 'description',
            title: <FormattedMessage id="Description" />,
            content: <ProductTabDescription description={product[`description_${locale}`]} />,
        },
        // { key: 'specification', title:<FormattedMessage id ='Specification' /> content: <ProductTabSpecification /> },
        {
            key: 'reviews',
            title: <FormattedMessage id="Reviews" />,
            content: <ProductTabReviews reviews={reviews} productId={product.id} />,
        },
    ];

    const tabsButtons = tabs.map((tab) => {
        const classes = classNames('product-tabs__item', {
            'product-tabs__item--active': currentTab === tab.key,
        });

        return (
            <button key={tab.key} type="button" onClick={() => setTab(tab.key)} className={classes}>
                {tab.title}
            </button>
        );
    });

    const tabsContent = tabs.map((tab) => {
        const classes = classNames('product-tabs__pane', {
            'product-tabs__pane--active': currentTab === tab.key,
        });

        return (
            <div key={tab.key} className={classes}>
                {tab.content}
            </div>
        );
    });

    return (
        <div className={classes}>
            <div className="product-tabs__list">{tabsButtons}</div>
            <div className="product-tabs__content">{tabsContent}</div>
        </div>
    );

    function setTab(newTab) {
        setCurrentTab(newTab);
    }
}

const mapStateToProps = ({ locale }) => ({
    locale,
});

export default connect(mapStateToProps)(ProductTabs);
