import React from 'react';

import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import TopBar from '../../../header/Topbar';
import { LogoSvg } from '../../../../svg';
import PropTypes from 'prop-types';
import TempSearch from '../search/TempSearch';

function Header({ sectionNumber, ...redux }) {
    const { phone } = redux;

    return (
        <div className="site-header">
            <TopBar />
            <div className="site-header__middle container">
                <div className="site-header__logo">
                    <Link to={`/menu/${sectionNumber}/categories`}>
                        <LogoSvg width={200} height={120} />
                    </Link>
                </div>
                <div className="site-header__search">
                    <TempSearch context="header" />
                </div>
                <div className="site-header__phone">
                    <div className="site-header__phone-title">
                        <FormattedMessage id="Customer Service" defaultMessage="Customer Service" />
                    </div>
                    <a href={`tel:${phone}`} className="site-header__phone-number">
                        {phone}
                    </a>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({ configs: { phone } }) => ({
    phone,
});

Header.propTypes = {
    sectionNumber: PropTypes.number,
};

Header.defaultProps = {
    sectionNumber: 3,
};

export default connect(mapStateToProps)(Header);
