// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

function Currency({ value, currency, isDiscounted, classNames, nearPrice, ...redux }) {
    const { currentCurrency } = redux;
    const { symbol } = currency || currentCurrency;

    return typeof Number(value) === 'number' ? (
        <>
            {isDiscounted && !nearPrice && <br />}
            <div className={`custom-currency ${isDiscounted ? 'discount-currency' : ''} `.concat(classNames)}>
                {Number(value)}
                <span> </span>
                {symbol}
            </div>
        </>
    ) : null;
}

Currency.propTypes = {
    /** currency object, specify to override currentCurrency */
    currency: PropTypes.object,
    /** currency object */
    currentCurrency: PropTypes.object,
};

const mapStateToProps = ({ currency }) => ({
    currentCurrency: currency,
});

export default connect(mapStateToProps)(Currency);
