import axios from 'axios';
import { GLOBAL_URL } from '../../components/config/general';

export const QUICKVIEW_OPEN = 'QUICKVIEW_OPEN';
export const QUICKVIEW_CLOSE = 'QUICKVIEW_CLOSE';

export function quickviewOpenSuccess(product) {
    return {
        type: QUICKVIEW_OPEN,
        product,
    };
}

export function quickviewClose() {
    return {
        type: QUICKVIEW_CLOSE,
    };
}

export function quickviewOpen(product) {
    return (dispatch) => {
        if (product) {
            const newProduct = { ...product, pictures: [{ url: 'product.avatar' }] };
            dispatch(quickviewOpenSuccess(newProduct));
            axios
                .post(GLOBAL_URL + 'products/getSingle', {
                    productID: product.id,
                })
                .then((res) => dispatch(quickviewOpenSuccess(res.data.data.product)))
                .catch((e) => console.log(e));
        }
        return Promise.resolve();
    };
}

const initialState = {
    open: false,
    product: null,
};

export function quickview(state = initialState, action) {
    let newState = state;

    if (action.type === QUICKVIEW_OPEN) {
        newState = {
            ...state,
            open: true,
            product: action.product,
        };
    } else if (action.type === QUICKVIEW_CLOSE) {
        newState = {
            ...state,
            open: false,
        };
    }

    return newState;
}
