import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { sendPartnership } from '../../redux/ducks/partnershipDucks';
import { resetCustomError } from '../../redux/ducks/validationDucks';
import useOnUnmount from '../hooks/useOnUnmount';
import { useFormik } from 'formik';
import FormInput from '../shared/FormInput';
import DangerAlert from '../account/accountPageLogin/components/DangerAlert';

const partnershipInputData = [
    { label: 'Company name', type: 'text', placeholder: 'Company name', id: 'company_name', error: false, message: '' },

    {
        label: 'Legal Address',
        type: 'text',
        placeholder: 'Legal Address',
        id: 'legal_address',
        error: false,
        message: '',
    },
    { label: 'TIN', type: 'text', placeholder: 'TIN', id: 'tin', error: false, message: '' },
    {
        label: 'Account Number',
        type: 'text',
        placeholder: 'Account Number',
        id: 'account_number',
        error: false,
        message: '',
    },
    { label: 'Bank', type: 'text', placeholder: 'Bank', id: 'bank', error: false, message: '' },
    {
        label: 'Email address',
        type: 'email',
        placeholder: 'Email address',
        id: 'email',
        error: false,
        message: '',
    },
    { label: 'Phone', type: 'text', placeholder: 'Phone', id: 'phone', error: false, message: '' },
    {
        label: 'Company Director',
        type: 'phone',
        placeholder: 'Company Director',
        id: 'company_director',
        error: false,
        message: '',
    },
];

function PartnershipPage({ locale, sendPartnership, resetCustomError, customErrorMessage, configs }) {
    useOnUnmount(() => {
        resetCustomError();
    });

    const intl = useIntl();

    const formik = useFormik({
        initialValues: {
            company_name: '',
            company_type: 'LLC',
            legal_address: '',
            tin: '',
            account_number: '',
            bank: '',
            phone: '',
            email: '',
            company_director: '',
            vat_type: '1',
            brand_name: '',
            barcode: '1',
        },
        onSubmit: (values, { resetForm }) => {
            sendPartnership({ ...values, locale });
            resetForm();
        },
    });

    return (
        <div className="container mb-4">
            <p className="partnership-section">
                <FormattedMessage id="Partnership" />
            </p>
            <p className="partnership-section-join">
                <FormattedMessage id="Join Us" />
            </p>
            <p className="partnership-section-join">
                <FormattedMessage id="Become a partner" />
            </p>
            <h3 className="row justify-content-center align-items-center mt-2 color-bonus">
                <a href={`tel:${configs.phone}`}>{configs.phone}</a>
            </h3>
            <h5 className="row ml-0 mt-5">
                <FormattedMessage id="Company information" />
            </h5>
            <form onSubmit={formik.handleSubmit} className="mt-3">
                <div className="form-row">
                    {partnershipInputData.map((elem) => {
                        const { id, label, placeholder, error, message, type } = elem;
                        return (
                            <FormInput
                                {...{
                                    id,
                                    label,
                                    placeholder,
                                    type,
                                    error,
                                    message,
                                    onChange: formik.handleChange,
                                    value: formik.values[id],
                                    key: id,
                                    wrapperClass: 'col-md-6',
                                }}
                            />
                        );
                    })}
                    <div className="form-group col-md-6" key="input_company_type">
                        <label htmlFor="form-message">
                            <FormattedMessage id="Company Type" />
                        </label>
                        <select
                            name="company_type"
                            id="company_type"
                            className={`form-control custom-select form-control-select2`}
                            value={formik.values.company_type}
                            onChange={formik.handleChange}
                        >
                            <option value="LLC">{intl.formatMessage({ id: 'LLC' })}</option>
                            <option value="SP">{intl.formatMessage({ id: 'SP' })}</option>
                        </select>
                    </div>
                    <div className="form-group col-md-6" key="input_vat_type">
                        <label htmlFor="form-message">
                            <FormattedMessage id="Vat Type" />
                        </label>
                        <select
                            name="vat_type"
                            id="vat_type"
                            className={`form-control custom-select form-control-select2`}
                            value={formik.values.vat_type}
                            onChange={formik.handleChange}
                        >
                            <option value="1">{intl.formatMessage({ id: 'Paid' })}</option>
                            <option value="0">{intl.formatMessage({ id: 'Unpaid' })}</option>
                        </select>
                    </div>
                </div>
                <h5 className="row ml-0 mt-3">
                    <FormattedMessage id="Product information" />
                </h5>
                <div className="form-row">
                    <FormInput
                        {...{
                            id: 'brand_name',
                            label: 'Brand Name',
                            placeholder: 'Brand Name',
                            type: 'text',
                            error: false,
                            message: '',
                            onChange: formik.handleChange,
                            value: formik.values.brand_name,
                            key: 'brand_name',
                            wrapperClass: 'col-md-6',
                        }}
                    />
                    <div className="form-group col-md-6" key="input_barcode">
                        <label htmlFor="form-message">
                            <FormattedMessage id="Barcode" />
                        </label>
                        <select
                            name="barcode"
                            id="barcode"
                            className={`form-control custom-select form-control-select2`}
                            value={formik.values.barcode}
                            onChange={formik.handleChange}
                        >
                            <option value="1">{intl.formatMessage({ id: 'Yes' })}</option>
                            <option value="0">{intl.formatMessage({ id: 'No' })}</option>
                        </select>
                    </div>
                </div>
                <div className="form-group">
                    <DangerAlert isVisible={!!customErrorMessage} message={customErrorMessage} />
                </div>
                <button type="submit" className="btn btn-primary d-flex m-auto">
                    <FormattedMessage id="Send" />
                </button>
            </form>
        </div>
    );
}

const mapDispatchToProps = { sendPartnership, resetCustomError };

const mapStateToProps = ({ locale, customErrorMessage, configs }) => ({
    locale,
    configs,
    customErrorMessage,
});

export default connect(mapStateToProps, mapDispatchToProps)(PartnershipPage);
