// react
import React from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import AsyncAction from '../shared/AsyncAction';
import Currency from '../shared/Currency';
import { Cart16Svg } from '../../svg';
import { cartAddItem } from '../../redux/ducks/cartDucks';
import { IMAGE_URL } from '../config/general';
import { FormattedMessage } from 'react-intl';

function Suggestions({ context, className, products, ...redux }) {
    const { cartAddItem, locale } = redux;
    const rootClasses = classNames(`suggestions suggestions--location--${context}`, className);

    const list = !!products.length ? (
        products.map((product) => (
            <li key={product.id} className="suggestions__item">
                <div className="suggestions__item-image">
                    <img src={IMAGE_URL + product.avatar} alt="" />
                </div>
                <div className="suggestions__item-info">
                    <Link
                        className="suggestions__item-name"
                        to={{ pathname: `/shop/product/${product.id}`, product: product }}
                    >
                        {product[`title_${locale}`]}
                    </Link>
                    <div className="suggestions__item-meta">
                        <FormattedMessage id="SKU" />: {product.article_id}
                    </div>
                </div>
                <div className="suggestions__item-price">
                    <Currency value={product.price} />
                </div>
                {context === 'header' && (
                    <div className="suggestions__item-actions">
                        <AsyncAction
                            action={() => cartAddItem(product)}
                            render={({ run, loading }) => (
                                <button
                                    type="button"
                                    onClick={run}
                                    title="Add to cart"
                                    className={classNames('btn btn-primary btn-sm btn-svg-icon', {
                                        'btn-loading': loading,
                                    })}
                                >
                                    <Cart16Svg />
                                </button>
                            )}
                        />
                    </div>
                )}
            </li>
        ))
    ) : (
        <li className="suggestions__item">
            <div className="suggestions__item-info">No such product</div>
        </li>
    );

    return (
        <div className={rootClasses}>
            <ul className="suggestions__list">{list}</ul>
        </div>
    );
}

const mapStateToProps = ({ locale }) => ({ locale });

const mapDispatchToProps = {
    cartAddItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(Suggestions);
