// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

// application
import { FormattedMessage } from 'react-intl';
import Currency from './../ProductsPage/Currency';
import ProductGallery from '../../../shared/ProductGallery';
import { getTitleLocale } from '../../../../helpers/general';

function Product({ product, layout, ...redux }) {
    const { locale } = redux;

    let wPrice;

    if (product?.wholesale_price?.length) {
        const wholeSaleValues = product.wholesale_price.split('/');
        wPrice = (
            <span style={{ fontWeight: 'bold' }}>
                {wholeSaleValues[0]}
                <FormattedMessage id="AndMore" />
                <Currency classNames={'letterSpacing0'} value={wholeSaleValues[1]} />
            </span>
        );
    }

    let prices;

    if (!!Number(product.discounted_price)) {
        prices = (
            <>
                <span className="product__new-price">
                    {!!product.sale_date && (
                        <p className="product__sale-until-date">
                            <FormattedMessage id="SaleUntil" />
                            {': '}
                            {moment(product.sale_date).format('DD-MM-YYYY')}
                        </p>
                    )}
                    <Currency value={product.discounted_price} />
                </span>{' '}
                <span className="product__old-price">
                    <Currency value={product.price} />
                </span>
            </>
        );
    } else {
        prices = <Currency value={product.price} />;
    }

    let count = !!product.params && product.params.Count ? Math.round(product.params.Count) : 1;

    return (
        <div className={`product product--layout--${layout}`}>
            <div className="product__content">
                <ProductGallery layout={layout} images={product.pictures || []} />
                <div className="product__info">
                    <h1 className="product__name" style={{ fontSize: '18px' }}>
                        {product[getTitleLocale(locale)]}
                    </h1>
                    <div className="product__description" style={{ fontSize: '14px' }}>
                        {product[`description_short_${locale}`]}
                    </div>
                    <ul className="product__features">
                        {!!product.params ? (
                            Object.entries(product.params).map(([key, value], i) => {
                                if (key === 'Count') {
                                    return (
                                        <li key={i}>
                                            <FormattedMessage id="Min Count" />: {Math.round(value)}
                                        </li>
                                    );
                                }
                                if (key === 'Name') {
                                    return (
                                        <li key={i}>
                                            <FormattedMessage id="Type" /> : {value}
                                        </li>
                                    );
                                }
                                return '';
                            })
                        ) : (
                            <>
                                <li>
                                    <FormattedMessage id="Type" />: <FormattedMessage id="Single" />
                                </li>
                                <li>
                                    <FormattedMessage id="Count" />: 1
                                </li>
                            </>
                        )}
                    </ul>
                    <ul className="product__meta">
                        <li className="product__meta-availability">
                            <FormattedMessage id="Availability" />:{' '}
                            <span className="text-success">
                                <FormattedMessage id="In Stock" />
                            </span>
                        </li>
                        {!!product.company && (
                            <li>
                                <FormattedMessage id="Company" />:<Link to="/">{product.company}</Link>
                            </li>
                        )}
                        <li>
                            <FormattedMessage id="SKU" />:{' '}
                            <span
                                style={{
                                    paddingLeft: '5px',
                                    fontWeight: 'bold',
                                    color: '#f10000',
                                }}
                            >
                                {product.article_id}
                            </span>
                        </li>
                    </ul>
                </div>

                <div className="product__sidebar">
                    <div className="product__availability">
                        <FormattedMessage id="Availability" />:{' '}
                        <span className="text-success">
                            <FormattedMessage id="In Stock" />
                        </span>
                    </div>

                    <div className="product__prices">{prices}</div>
                    <span className="wholePriceRowPreview">{wPrice}</span>
                    <form className="product__options">
                        <div
                            className="form-group product__option"
                            style={{ display: 'flex', gap: '20px', alignSelf: 'center', alignItems: 'center' }}
                        >
                            <div className="product__option-label">
                                <FormattedMessage id="Type" />:
                                <span style={{ paddingLeft: '5px', fontWeight: 'bold' }}>
                                    {product.params && product.params.Name ? (
                                        product.params.Name
                                    ) : (
                                        <FormattedMessage id="Single" />
                                    )}
                                </span>
                            </div>
                            <div className="product__option-label">
                                <FormattedMessage id="Min Count" />
                                <span
                                    style={{
                                        paddingLeft: '5px',
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                    }}
                                >
                                    {count}
                                </span>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

Product.propTypes = {
    /** product object */
    product: PropTypes.object.isRequired,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(['standard', 'sidebar', 'columnar', 'quickview']),
};

Product.defaultProps = {
    layout: 'standard',
};

const mapStateToProps = ({ locale }) => ({
    locale,
});

export default connect(mapStateToProps)(Product);
