// react
import React, { useEffect, useState } from 'react';

// data stubs
import { connect } from 'react-redux';
import axios from 'axios';
import { GLOBAL_URL } from '../config/general';
import { toast } from 'react-toastify';
import { FormattedMessage, useIntl } from 'react-intl';
import { TOAST_MESSAGES } from '../../constants/general';

const socialLinksData = [
    {
        key: 'facebook',
        url: '',
        iconClass: 'fab fa-facebook-f',
    },
    {
        key: 'instagram',
        url: '',
        iconClass: 'fab fa-instagram',
    },
];

function FooterNewsletter(redux) {
    const { locale, links, subscribeText } = redux;
    const [socialLinks, setSocialLinks] = useState(socialLinksData);
    const [email, setEmail] = useState('');
    const intl = useIntl();

    useEffect(() => {
        if (!!links) {
            let socialLinksData = socialLinks.map((linkData) => ({ ...linkData, url: links[linkData.key] }));
            setSocialLinks(socialLinksData);
        }
    }, [links]);

    const socialLinksList = socialLinks.map((item) => (
        <li key={item.key} className={`footer-newsletter__social-link footer-newsletter__social-link--${item.key}`}>
            <a href={item.url} target="_blank" rel="noopener noreferrer">
                <i className={item.iconClass} />
            </a>
        </li>
    ));

    const emailPlaceholder = intl.formatMessage({ id: 'Email Address' });

    return (
        <div className="site-footer__widget footer-newsletter">
            <h5 className="footer-newsletter__title">
                <FormattedMessage id="Newsletter" />
            </h5>
            {!subscribeText ? (
                <div className="d-flex m-auto spinner-border text-danger" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            ) : (
            <div className="footer-newsletter__text">
                {subscribeText[locale]}
            </div>)}

            <form action="" className="footer-newsletter__form">
                <div>
                    <label className="sr-only" htmlFor="footer-newsletter-address">
                        <FormattedMessage id="Email Address" />
                    </label>
                    {/* TODO Add 'is-invalid' class to the input to make borders red and to show the error message below*/}
                    <input
                        type="email"
                        className="footer-newsletter__form-input form-control"
                        id="footer-newsletter-address"
                        placeholder={`${emailPlaceholder}...`}
                        required
                        value={email}
                        onChange={handleEmailChange}
                    />
                    <div className="invalid-feedback">Your error message here</div>
                </div>
                <button type="submit" className="footer-newsletter__form-button btn btn-primary" onClick={handleSubmit}>
                    <FormattedMessage id="Subscribe" />
                </button>
            </form>

            <div className="footer-newsletter__text footer-newsletter__text--social">
                <FormattedMessage id="Follow us on social networks" />
            </div>

            <ul className="footer-newsletter__social-links">{socialLinksList}</ul>
        </div>
    );

    function handleEmailChange(e) {
        setEmail(e.target.value);
    }

    function handleSubmit(e) {
        e.preventDefault();
        axios
            .post(GLOBAL_URL + 'users/newsLetter', { email, locale })
            .then((res) => {
                const { error } = res.data;
                if (!error) {
                    setEmail('');
                    toast.success(TOAST_MESSAGES.subscribeSuccess);
                }
            })
            .catch((e) => console.log(e));
    }
}

const mapStateToProps = ({ configs: { socialLinks: links, subscribeText }, locale }) => ({
    links,
    locale,
    subscribeText,
});

export default connect(mapStateToProps)(FooterNewsletter);
