import { useEffect, useState } from 'react';
import { getDataWithErrors } from '../../helpers/general';

const useFormWithErrors = (inputsData, errorsData) => {
    const [fromInputData, setFormInputData] = useState(inputsData);
    useEffect(() => {
        let dataWithErrors = getDataWithErrors(fromInputData, errorsData);
        setFormInputData(dataWithErrors);
    }, [errorsData]);
    return fromInputData;
};

export default useFormWithErrors;
