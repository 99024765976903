import React from 'react';
import { Link } from 'react-router-dom';
import AsyncAction from '../../shared/AsyncAction';
import classNames from 'classnames';
import { Cross12Svg } from '../../../svg';
import { getTitleLocale } from '../../../helpers/general';
import Currency from '../../shared/Currency';
import InputNumber from '../../shared/InputNumber';
import { IMAGE_URL } from '../../config/general';
import { useIntl } from 'react-intl';

function ItemCartPage({ product, locale, onRemoveClick, onQuantityClick }) {
    const intl = useIntl();

    const { discounted_price, price } = product;
    const productPrice = !!Number(discounted_price) ? discounted_price : price;

    return (
        <tr key={product.id} className="cart-table__row">
            <td className="cart-table__column cart-table__column--image">
                <Link to={{ pathname: `/shop/product/${product.id}`, product: product }}>
                    <img src={IMAGE_URL + product.avatar} alt="" />
                </Link>
            </td>
            <td className="cart-table__column cart-table__column--product">
                <Link
                    to={{ pathname: `/shop/product/${product.id}`, product: product }}
                    className="cart-table__product-name"
                >
                    {product[getTitleLocale(locale)]}
                </Link>
            </td>
            <td
                className="cart-table__column cart-table__column--price"
                data-title={intl.formatMessage({ id: 'Price' })}
            >
                <Currency value={productPrice} />
                {!!Number(discounted_price) && <Currency value={price} isDiscounted={true} />}
            </td>
            <td
                className="cart-table__column cart-table__column--quantity"
                data-title={intl.formatMessage({ id: 'Quantity' })}
            >
                <InputNumber onChange={handleChangeQuantity} value={product.product_count} min={1} id={product.id} />
            </td>
            <td
                className="cart-table__column cart-table__column--total"
                data-title={intl.formatMessage({ id: 'Total' })}
            >
                <Currency value={product.product_count * productPrice} />
            </td>
            <td className="cart-table__column cart-table__column--remove">
                <AsyncAction
                    action={() => onRemoveClick(product)}
                    render={({ run, loading }) => {
                        const classes = classNames('btn btn-light btn-sm btn-svg-icon', {
                            'btn-loading': loading,
                        });

                        return (
                            <button type="button" onClick={run} className={classes}>
                                <Cross12Svg />
                            </button>
                        );
                    }}
                />
            </td>
        </tr>
    );

    function handleChangeQuantity(e, direction) {
        if (typeof e === 'number') {
            onQuantityClick(product, direction);
        }
    }
}

export default ItemCartPage;
