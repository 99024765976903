//prettier-ignore
import axios from 'axios';
import { GLOBAL_URL } from '../../components/config/general';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { resetCustomError, setCustomError } from './validationDucks';

export function sendFeedbackMessage(values) {
    return (dispatch) => {
        return axios
            .post(GLOBAL_URL + 'message/sendMessage', values, {
                headers: {
                    authorization: Cookies.get('token'),
                },
            })
            .then((res) => {
                const { error, message } = res.data;
                if (!error) {
                    toast.success(message);
                    dispatch(resetCustomError());
                } else {
                    dispatch(setCustomError(message));
                }
            });
    };
}
