// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';

// application
import Collapse from '../shared/Collapse';
import FilterCategories from '../filters/FilterCategories';
import FilterCheckbox from '../filters/FilterCheckbox';
import FilterColor from '../filters/FilterColor';
import FilterPrice from '../filters/FilterPrice';
import FilterRadio from '../filters/FilterRadio';
import { ArrowRoundedDown12x7Svg } from '../../svg';
import { FormattedMessage } from 'react-intl';
import FetchLoader, { LOADER_SIZES } from '../shared/FetchLoader';

function WidgetFilters({
    title,
    filters,
    offcanvas,
    handleCheckboxChange,
    onPriceFilterAndResetClicks,
    selectedCategoryId,
    areCategoriesLoaded,
}) {
    const filtersList = filters.map((filter) => {
        let filterView;

        if (filter.type === 'categories') {
            filterView = <FilterCategories categories={filter.items} selectedCategoryId={selectedCategoryId} />;
        } else if (filter.type === 'checkbox') {
            filterView = <FilterCheckbox items={filter.items} parentId={filter.id} onChange={handleCheckboxChange} />;
        } else if (['checkbox', 'radio'].includes(filter.type)) {
            filterView = <FilterRadio items={filter.options.items} name={filter.name} />;
        } else if (filter.type === 'color') {
            filterView = <FilterColor items={filter.options.items} />;
        } else if (filter.type === 'price') {
            filterView = <FilterPrice from={filter.from} to={filter.to} min={filter.min} max={filter.max} step={1} />;
        }

        return (
            <div key={filter.id} className="widget-filters__item">
                <FetchLoader loaded={filter.type !== 'categories' || areCategoriesLoaded} size={LOADER_SIZES.SMALL}>
                    <Collapse
                        toggleClass="filter--opened"
                        render={({ toggle, setItemRef, setContentRef }) => (
                            <div className="filter filter--opened" ref={setItemRef}>
                                <button type="button" className="filter__title" onClick={toggle}>
                                    {<FormattedMessage id={filter.name} />}
                                    <ArrowRoundedDown12x7Svg className="filter__arrow" />
                                </button>
                                <div className="filter__body" ref={setContentRef}>
                                    <div className="filter__container">{filterView}</div>
                                </div>
                            </div>
                        )}
                    />
                </FetchLoader>
            </div>
        );
    });

    const classes = classNames('widget-filters widget', {
        'widget-filters--offcanvas--always': offcanvas === 'always',
        'widget-filters--offcanvas--mobile': offcanvas === 'mobile',
    });

    return (
        <div className={classes}>
            <h4 className="widget-filters__title widget__title">{<FormattedMessage id={title} />}</h4>

            <div className="widget-filters__list">{filtersList}</div>

            <div className="widget-filters__actions d-flex">
                <button type="button" className="btn btn-primary btn-sm" onClick={onPriceFilterAndResetClicks}>
                    <FormattedMessage id="Filter" />
                </button>
                <button
                    type="button"
                    className="btn btn-secondary btn-sm ml-2"
                    id="reset"
                    onClick={onPriceFilterAndResetClicks}
                >
                    <FormattedMessage id="Reset" />
                </button>
            </div>
        </div>
    );
}

WidgetFilters.propTypes = {
    /**
     * widget title
     */
    title: PropTypes.node,
    /**
     * array of filters
     */
    filters: PropTypes.array,
    /**
     * indicates when sidebar bar should be off canvas
     */
    offcanvas: PropTypes.oneOf(['always', 'mobile']),
};

WidgetFilters.defaultProps = {
    filters: [],
    offcanvas: 'mobile',
};

export default WidgetFilters;
