// react
import React from 'react';

// third-party
import classNames from 'classnames';
import { Link, matchPath, Redirect, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

// application
import PageHeader from '../shared/PageHeader';

// pages
import AccountPageProfile from './accountPageProfile/AccountPageProfile';
import AccountPageEditAddress from './AccountPageEditAddress';
import AccountPageOrderDetails from './AccountPageOrderDetails';
import AccountPageOrders from './AccountPageOrders';
import AccountPagePassword from './AccountPagePassword';
import AccountPageEditProfile from './AccountPageEditProfile';
import { signOut } from '../../redux/ducks/userDucks';
import { FormattedMessage } from 'react-intl';
import AccountPageEditCompany from './AccountPageEditCompany';

function AccountLayout({ match, location, ...redux }) {
    const { user, signOut } = redux;
    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'My Account', url: '' },
    ];

    const links = [
        { title: <FormattedMessage id="Profile" />, url: 'profile' },
        { title: <FormattedMessage id="Edit Profile" />, url: 'edit-profile' },
        { title: <FormattedMessage id="Order History" />, url: 'orders' },
        { title: <FormattedMessage id="Edit Address" />, url: 'edit-address' },
        { title: <FormattedMessage id="Password" />, url: 'password' },
        { title: <FormattedMessage id="Logout" />, url: 'login', onClick: signOut },
    ].map((link) => {
        const url = `${match.url}/${link.url}`;
        const isActive = matchPath(location.pathname, { path: url, exact: true });
        const classes = classNames('account-nav__item', {
            'account-nav__item--active': isActive,
        });

        return (
            <li key={link.url} className={classes}>
                <Link to={url} onClick={link.onClick}>
                    {link.title}
                </Link>
            </li>
        );
    });

    const editProfile =
        user.type === 1 ? <AccountPageEditProfile user={user} /> : <AccountPageEditCompany user={user} />;
    //prettier-ignore
    return (
        <>
            <PageHeader header="My Account" breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-3 d-flex">
                            <div className="account-nav flex-grow-1">
                                <h4 className="account-nav__title"><FormattedMessage id="Navigation" /></h4>
                                <ul>{links}</ul>
                            </div>
                        </div>
                        <div className="col-12 col-lg-9 mt-4 mt-lg-0">
                            <Switch>
                                <Redirect exact from={match.path} to={`${match.path}/profile`} />
                                <Route
                                    exact
                                    path={`${match.path}/profile`}
                                    render={() => <AccountPageProfile user={user} />}
                                />
                                <Route
                                    exact
                                    path={`${match.path}/edit-profile`}
                                    render={() => editProfile}
                                />
                                <Route exact path={`${match.path}/orders`} component={AccountPageOrders} />
                                <Route
                                    exact
                                    path={`${match.path}/orders/:orderId`}
                                    component={AccountPageOrderDetails}
                                />
                                <Route exact path={`${match.path}/edit-address`} component={AccountPageEditAddress} />
                                <Route exact path={`${match.path}/password`} component={AccountPagePassword} />
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
const mapStateToProps = ({ user }) => ({
    user,
});

const mapDispatchToProps = {
    signOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountLayout);
