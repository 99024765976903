// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// application
import { getTitleLocale } from '../../helpers/general';
import { SHOP_ALL_CATEGORIES_ID } from '../../constants/general';
import { FormattedMessage } from 'react-intl';

function FilterCategories({ categories, selectedCategoryId, ...redux }) {
    const { locale } = redux;

    const newCategories = selectedCategories(categories, Number(selectedCategoryId));

    return (
        <div className="filter-categories">
            <ul className="filter-categories__list">
                <li
                    className={`filter-categories__item filter-categories__item--${
                        Number(selectedCategoryId) === SHOP_ALL_CATEGORIES_ID ? 'current' : ''
                    }`}
                >
                    <Link to={`/shop/category/${SHOP_ALL_CATEGORIES_ID}`}>
                        <FormattedMessage id="All Categories" />
                    </Link>
                </li>
                {newCategories.map((category) => {
                    let type = '';
                    type = Number(selectedCategoryId) === Number(category.id) && 'current';

                    return (
                        (category.nest_level === 0 || category.selected) && (
                            <li
                                style={{ paddingLeft: category.nest_level * 14 + 'px' }}
                                key={category.id}
                                className={`filter-categories__item filter-categories__item--${type}`}
                            >
                                <Link to={`/shop/category/${category.id}`}>{category[getTitleLocale(locale)]}</Link>
                            </li>
                        )
                    );
                })}
            </ul>
        </div>
    );
}

FilterCategories.propTypes = {
    categories: PropTypes.array,
};

const mapStateToProps = ({ locale }) => ({
    locale,
});

export default connect(mapStateToProps)(FilterCategories);

function selectedCategories(allData, id) {
    function findElement(id) {
        return allData.find((val) => val.id === id) || {};
    }
    let el = findElement(id);

    let temp = [];
    let nestLevel = 0;

    if (el.subCategories) {
        temp = el.subCategories;
    }

    if (el.nest_level) {
        nestLevel = el.nest_level;
    }

    for (let i = 0; i < nestLevel; i++) {
        const parent = findElement(el.parent_id);
        temp = [...temp, ...parent.subCategories];
        el = parent;
    }

    const ids = temp.map((elem) => {
        return elem.id;
    });

    for (let j = 0; j < allData.length; j++) {
        allData[j].selected = ids.includes(allData[j].id);
    }

    return allData;
}
