// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';

// application
import AppLink from '../shared/AppLink';
import { ArrowRoundedRight6x9Svg } from '../../svg';
import { getTitleLocale } from '../../helpers/general';
import { connect } from 'react-redux';
import { resetFilteredPrice, resetFilterOptions } from '../../redux/ducks/filtersDucks';

function Menu({ layout, withIcons, items, onClick, isCategories, locale, resetFilterOptions, resetFilteredPrice }) {
    const renderLink = (item, content) => {
        let link;

        if (isCategories) {
            link = (
                <AppLink {...item.props} to={`/shop/category/${item.id}`} onClick={() => onClick(item)}>
                    {content}
                </AppLink>
            );
            return link;
        }

        if (item.url) {
            link = (
                <AppLink {...item.props} to={item.url} onClick={() => onClick(item)}>
                    {content}
                </AppLink>
            );
        } else {
            link = (
                <button type="button" onClick={() => onClick(item)}>
                    {content}
                </button>
            );
        }

        return link;
    };

    const itemsList = items.map((item, index) => {
        let arrow;
        let submenu;
        let icon;

        if ((item.submenu && item.submenu.length) || (item.subCategories && item.subCategories.length)) {
            arrow = <ArrowRoundedRight6x9Svg className="menu__arrow" />;
        }

        if ((item.submenu && item.submenu.length) || (item.subCategories && item.subCategories.length)) {
            submenu = (
                <div className="menu__submenu">
                    <Menu
                        items={item.submenu || item.subCategories}
                        isCategories={!!item.subCategories.length}
                        locale={locale}
                    />
                </div>
            );
        }

        if (withIcons && item.icon) {
            icon = (
                <div className="menu__icon">
                    <img src={item.icon} srcSet={item.icon_srcset} alt="" />
                </div>
            );
        }

        return (
            <li
                key={index}
                onClick={() => {
                    resetFilterOptions();
                    resetFilteredPrice();
                }}
            >
                {renderLink(
                    item,
                    <>
                        {icon}
                        {item.title || item[getTitleLocale(locale)]}
                        {arrow}
                    </>
                )}
                {submenu}
            </li>
        );
    });

    const classes = classNames(`menu menu--layout--${layout}`, {
        'menu--with-icons': withIcons,
    });

    return <ul className={classes}>{itemsList}</ul>;
}

Menu.propTypes = {
    /** one of ['classic', 'topbar'] (default: 'classic') */
    layout: PropTypes.oneOf(['classic', 'topbar']),
    /** default: false */
    withIcons: PropTypes.bool,
    /** array of menu items */
    items: PropTypes.array,
    /** callback function that is called when the item is clicked */
    onClick: PropTypes.func,
};

Menu.defaultProps = {
    layout: 'classic',
    withIcons: false,
    items: [],
    onClick: () => {},
};

const mapDispatchToProps = {
    resetFilterOptions,
    resetFilteredPrice,
};

export default connect(null, mapDispatchToProps)(Menu);
