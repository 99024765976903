import { createAction, createReducer } from '../../helpers/redux';

const SET_FILTER_CATEGORIES = 'SET_FILTER_CATEGORIES';
const UPDATE_FILTER_CATEGORIES = 'UPDATE_FILTER_CATEGORIES';

const UPDATE_FILTER_OPTIONS = 'UPDATE_FILTER_OPTIONS';
const RESET_FILTER_OPTIONS = 'RESET_FILTER_OPTIONS';

const UPDATE_FILTER_PRICE = 'UPDATE_FILTER_PRICE';
const RESET_FILTER_PRICE = 'RESET_FILTER_PRICE';
const SET_FILTER_PRICE = 'SET_FILTER_PRICE';

export const setFilterCategories = createAction(SET_FILTER_CATEGORIES);
export const updateFilterCategories = createAction(UPDATE_FILTER_CATEGORIES);
export const resetFilterOptions = createAction(RESET_FILTER_OPTIONS);
export const updateFilterOptions = createAction(UPDATE_FILTER_OPTIONS);
export const updateFilterPrice = createAction(UPDATE_FILTER_PRICE);
export const resetFilteredPrice = createAction(RESET_FILTER_PRICE);
export const setFilteredPrice = createAction(SET_FILTER_PRICE);

const initialState = {
    categories: {
        id: 'categories',
        name: 'Categories',
        type: 'categories',
        items: [],
    },
    options: {
        id: 'options',
        name: 'Options',
        type: 'checkbox',
        items: {
            newProductOption: {
                id: 'newProductOption',
                label: 'New',
                checked: false,
                disabled: false,
            },
            saleProductOption: {
                id: 'saleProductOption',
                label: 'Sale',
                checked: false,
                disabled: false,
            },
            featuredProductOption: {
                id: 'featuredProductOption',
                label: 'Featured',
                checked: false,
                disabled: false,
            },
        },
    },
    price: {
        id: 'price',
        name: 'Price',
        type: 'price',
        min: 1,
        max: 100000,
        from: 1,
        to: 100000,
        filteredPrice: { from: 1, to: 100000 },
    },
};

export const filters = createReducer(initialState, (state, { value }) => ({
    [SET_FILTER_CATEGORIES]: () => ({
        ...state,
        categories: {
            ...state.categories,
            items: collectData(value),
        },
    }),
    [UPDATE_FILTER_CATEGORIES]: () => ({
        ...state,
        categories: {
            ...state.categories,
            items: {
                ...state.categories.items,
                [value]: { ...state.categories.items[value], type: 'current' },
            },
        },
    }),
    [UPDATE_FILTER_OPTIONS]: () => {
        if (value === 'reset') {
            return {
                ...state,
                options: initialState.options,
            };
        }
        return {
            ...state,
            options: {
                ...state.options,
                items: {
                    ...state.options.items,
                    [value]: { ...state.options.items[value], checked: !state.options.items[value].checked },
                },
            },
        };
    },
    [RESET_FILTER_OPTIONS]: () => ({ ...state, options: initialState.options }),
    [UPDATE_FILTER_PRICE]: () => ({
        ...state,
        price: {
            ...state.price,
            from: value.from,
            to: value.to,
        },
    }),
    [RESET_FILTER_PRICE]: () => ({ ...state, price: initialState.price }),
    [SET_FILTER_PRICE]: () => ({ ...state, price: { ...state.price, filteredPrice: value } }),
}));

function collectData(categories) {
    const resultData = [];
    for (let i = 0; i < categories.length; i++) {
        resultData.push(categories[i]);
        if (categories[i].subCategories && categories[i].subCategories.length) {
            const subData = collectData(categories[i].subCategories);
            resultData.push(...subData);
        }
    }
    return resultData;
}
