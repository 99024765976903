import React from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import { signIn } from '../../../../redux/ducks/userDucks';
import { useLocation, useHistory } from 'react-router-dom';
import { setClass } from '../../../../helpers/general';
import { FormattedMessage, useIntl } from 'react-intl';

function UserLogin({ signIn, location: loginLocation }) {
    const location = useLocation();
    const history = useHistory();
    const intl = useIntl();
    const emailPlaceholder = intl.formatMessage({ id: 'Email address' });
    const passwordPlaceholder = intl.formatMessage({ id: 'Password' });

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
            isSave: false,
        },
        onSubmit: async (values) => {
            await signIn(values, 'popup');
            if (location.pathname === '/account/login') {
                history.push('/');
            }
        },
    });
    return (
        <form className="account-menu__form" onSubmit={formik.handleSubmit}>
            <div className="account-menu__form-title">
                <FormattedMessage id="Log In to Your Account" />
            </div>
            <div className="form-group">
                <label htmlFor="header-signin-email" className="sr-only">
                    Email address
                </label>
                <input
                    id="username"
                    type="email"
                    autoComplete="username"
                    className={`form-control form-control-sm ${setClass(loginLocation === 'popup', 'is-invalid')}`}
                    placeholder={emailPlaceholder}
                    onChange={formik.handleChange}
                />
            </div>
            <div className="form-group">
                <label htmlFor="header-signin-password" className="sr-only">
                    Password
                </label>
                <div className="account-menu__form-forgot">
                    <input
                        id="password"
                        type="password"
                        autoComplete="current-password"
                        className={`form-control form-control-sm ${setClass(loginLocation === 'popup', 'is-invalid')}`}
                        placeholder={passwordPlaceholder}
                        onChange={formik.handleChange}
                    />
                    <Link to="/account/forgotPass" className="account-menu__form-forgot-link">
                        <FormattedMessage id="Forgot?" />
                    </Link>
                </div>
            </div>
            <div className="form-group account-menu__form-button">
                <button type="submit" className="btn btn-primary btn-sm">
                    <FormattedMessage id="Login" />
                </button>
            </div>
            <div className="account-menu__form-link">
                <Link to="/account/login">
                    <FormattedMessage id="Create An Account" />
                </Link>
            </div>
        </form>
    );
}

const mapDispatchToProps = {
    signIn,
};

const mapStateToProps = ({ loginError: { location } }) => ({ location });

export default connect(mapStateToProps, mapDispatchToProps)(UserLogin);
