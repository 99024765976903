import { LS } from '../constants/localStorageConst';

export function isDevelopmentStage() {
    return process.env.NODE_ENV === 'development';
}

export function getTitleLocale(locale) {
    return `title_${locale}`;
}

export function getLocalLanguage() {
    return localStorage.getItem(LS.LOCALE);
}

export function setLocalLanguage(language) {
    localStorage.setItem(LS.LOCALE, language);
}

export function getLocalCart() {
    return localStorage.getItem(LS.CART);
}

export function setLocalCart(cart) {
    localStorage.setItem(LS.CART, cart);
}

export function resetLocalCart() {
    localStorage.setItem(LS.CART, JSON.stringify({ products: [] }));
}

export function setClass(condition, name, failed = '') {
    return !!condition ? name : failed;
}

export function getDateFromIso(iso) {
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    const parsed = new Date(iso);
    const minutes = parsed.getMinutes();
    const hours = parsed.getHours();
    const seconds = parsed.getSeconds();
    const year = parsed.getFullYear();

    let monthNum = parsed.getMonth();
    monthNum = monthNum < 10 ? `0${monthNum}` : monthNum;
    let time = '';
    time += (hours < 10 ? '0' : '') + hours + ':';
    time += (minutes < 10 ? '0' : '') + minutes;

    let day = parsed.getDate();
    day = day < 10 ? `0${day}` : day;

    const monthText = months[parsed.getMonth()];

    return { time, day, year, monthNum, monthText, seconds };
}

export function getDataWithErrors(formData, errorsData) {
    return formData.map((item) => {
        let invalidElement = errorsData.find((elem) => item.id === elem.type);
        if (invalidElement) {
            return { ...item, message: invalidElement.message, error: true };
        }
        return { ...item, error: false };
    });
}

export default function isPhoneNumberValid(phone) {
    let re = /^[\+]?([0-9]{1})?[( ]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{5}$/;
    return re.test(String(phone).toLowerCase()) && phone.length < 13;
}
