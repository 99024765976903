// react
import React, { useEffect, useState } from 'react';

// third-party
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

// application
import PageHeader from '../shared/PageHeader';
import { cartRemoveItem, getUserCart, updateCart } from '../../redux/ducks/cartDucks';

// data stubs
import EmptyCardPage from './components/EmptyCardPage';
import ItemCartPage from './components/ItemCartPage';
import AsyncAction from '../shared/AsyncAction';
import UnipackHelmet from '../shared/UnipackHelmet';
import { FormattedMessage } from 'react-intl';
import RowTableTotals from '../shared/RowTableTotals';

export function CartPage({ updateCart, cart = {}, cartRemoveItem, locale, getUserCart }) {
    const [products, setProducts] = useState(cart.products || []);
    const [cartNeedUpdate, setCartNeedUpdate] = useState(false);

    useEffect(() => {
        getUserCart();
    }, []);

    useEffect(() => {
        if (!!cart.products) {
            setProducts(cart.products);
        }
    }, [cart]);

    function handleChangeQuantity(product, direction) {
        let updatedProducts = products.map((item) => {
            if (item.id === product.id) {
                const newCount = product.product_count + product.params.Count * direction;
                if (newCount > 0) {
                    return { ...product, product_count: product.product_count + product.params.Count * direction };
                }
            }
            return item;
        });
        setProducts(updatedProducts);
    }

    useEffect(() => {
        if (JSON.stringify(cart.products) !== JSON.stringify(products)) {
            const isQuantityValid = products.every((product) => product.product_count >= product.params.Count);
            if (isQuantityValid) {
                setCartNeedUpdate(true);
            } else {
                setCartNeedUpdate(false);
            }
        } else {
            setCartNeedUpdate(false);
        }
    }, [products, cart]);

    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'Shopping Cart', url: '' },
    ];

    const updateCartButton = (
        <AsyncAction
            action={() => updateCart(products)}
            render={({ run, loading }) => {
                const classes = classNames('btn btn-primary cart__update-button', {
                    'btn-loading': loading,
                });

                return (
                    <button type="button" onClick={run} className={classes} disabled={!cartNeedUpdate}>
                        <FormattedMessage id="Update Cart" />
                    </button>
                );
            }}
        />
    );

    return (
        <>
            <UnipackHelmet title="Shopping Cart" />

            <PageHeader header="Shopping Cart" breadcrumb={breadcrumb} />

            {!!products.length ? (
                <div className="cart block">
                    <div className="container">
                        <table className="cart__table cart-table">
                            <thead className="cart-table__head">
                                <tr className="cart-table__row">
                                    <th className="cart-table__column cart-table__column--image">
                                        <FormattedMessage id="Image" />
                                    </th>
                                    <th className="cart-table__column cart-table__column--product">
                                        <FormattedMessage id="Product" />
                                    </th>
                                    <th className="cart-table__column cart-table__column--price">
                                        <FormattedMessage id="Price" />
                                    </th>
                                    <th className="cart-table__column cart-table__column--quantity">
                                        <FormattedMessage id="Quantity" />
                                    </th>
                                    <th className="cart-table__column cart-table__column--total">
                                        <FormattedMessage id="Total" />
                                    </th>
                                    <th className="cart-table__column cart-table__column--remove" aria-label="Remove" />
                                </tr>
                            </thead>
                            <tbody className="cart-table__body">
                                {products.map((product) => (
                                    <ItemCartPage
                                        key={product.id}
                                        product={product}
                                        locale={locale}
                                        onQuantityClick={handleChangeQuantity}
                                        onRemoveClick={cartRemoveItem}
                                    />
                                ))}
                            </tbody>
                        </table>
                        <div className="cart__actions">
                            <form className="cart__coupon-form" style={{ visibility: 'hidden' }}>
                                <label htmlFor="input-coupon-code" className="sr-only">
                                    Password
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="input-coupon-code"
                                    placeholder="Coupon Code"
                                />
                                <button type="submit" className="btn btn-primary">
                                    Apply Coupon
                                </button>
                            </form>
                            <div className="cart__buttons">
                                <Link to="/shop/products" className="btn btn-light">
                                    <FormattedMessage id="Continue Shopping" />
                                </Link>
                                {updateCartButton}
                            </div>
                        </div>
                        <div className="row justify-content-end pt-md-5 pt-4">
                            <div className="col-12 col-md-7 col-lg-6 col-xl-5">
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="card-title">
                                            <FormattedMessage id="Cart Totals" />
                                        </h3>
                                        <table className="cart__totals">
                                            <thead className="cart__totals-header">
                                                <RowTableTotals name="Subtotal" amount={cart.subTotal} />
                                            </thead>
                                            <tbody className="cart__totals-body">
                                                <RowTableTotals name="Shipping" amount={cart.shippingAmount} />
                                                <RowTableTotals
                                                    name="Discount"
                                                    amount={cart.discount}
                                                    className={'color-discount'}
                                                />
                                                {/*<RowTableTotals*/}
                                                {/*    name="Bonus"*/}
                                                {/*    amount={cart.bonus}*/}
                                                {/*    className={'color-bonus'}*/}
                                                {/*/>*/}
                                            </tbody>
                                            <tfoot className="cart__totals-footer">
                                                <RowTableTotals name="Total" amount={cart.totalAmount} />
                                            </tfoot>
                                        </table>
                                        <Link
                                            to="/shop/checkout"
                                            className="btn btn-primary btn-xl btn-block cart__checkout-button"
                                        >
                                            <FormattedMessage id="Proceed to checkout" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <EmptyCardPage />
            )}
        </>
    );
}

const mapStateToProps = ({ cart, locale }) => ({
    cart,
    locale,
});

const mapDispatchToProps = {
    cartRemoveItem,
    updateCart,
    getUserCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(CartPage);
