import classes from './SubCategoryBlock.module.css';
import SubCategoryCard from '../items/SubCategoryCard';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { IMAGE_URL } from '../../../config/general';

export default function SubCategoryBlock({ subCategory, locale, title }) {
    const { section } = useParams();
    return (
        <div data-id={subCategory.id} className={classes.wrapper}>
            <h2 className={classes.title}>{title}</h2>
            <div className={classes.container}>
                {subCategory.subCategories.map((subCategory) => (
                    <Link
                        key={`sub_category_${subCategory.id}`}
                        to={`/menu/${section}/categories/${subCategory.id}/products`}
                    >
                        <SubCategoryCard url={IMAGE_URL + subCategory.icon} title={subCategory[`title_${locale}`]} />
                    </Link>
                ))}
            </div>
        </div>
    );
}
