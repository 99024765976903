// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { IMAGE_URL } from '../config/general';
import { connect } from 'react-redux';

function BlockFeatures(props) {
    const { layout, locale, banners } = props;

    const leftBanner = banners?.web?.home?.left?.[locale]?.[0] || '';
    const leftBannerMobile = banners?.mobile?.home?.left?.[locale]?.[0] || '';
    const rightBanner = banners?.web?.home?.right?.[locale]?.[0] || '';
    const rightBannerMobile = banners?.mobile?.home?.right?.[locale]?.[0] || '';

    return (
        <div className={`block block-features block-features--layout--${layout}`}>
            <div className="container">
                <div className="block-features__list">
                    <a
                        className="block-features__item left block-features__item--desktop"
                        href={String(leftBanner.link)}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={IMAGE_URL + leftBanner.path} alt="" />
                    </a>
                    <a
                        className="block-features__item left block-features__item--mobile"
                        href={String(leftBannerMobile.link)}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={IMAGE_URL + leftBannerMobile.path} alt="" />
                    </a>
                    <div className="block-features__divider" />
                    <a
                        className="block-features__item right block-features__item--desktop"
                        href={String(rightBanner.link)}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={IMAGE_URL + rightBanner.path} alt="" />
                    </a>
                    <a
                        className="block-features__item right block-features__item--mobile"
                        href={String(rightBannerMobile.link)}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={IMAGE_URL + rightBannerMobile.path} alt="" />
                    </a>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({ locale, banners }) => ({ locale, banners });

export default connect(mapStateToProps)(BlockFeatures);

BlockFeatures.propTypes = {
    layout: PropTypes.oneOf(['classic', 'boxed']),
};

BlockFeatures.defaultProps = {
    layout: 'classic',
};
