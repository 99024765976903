// react
import React, { useRef, useState } from 'react';

// third-party
import PropTypes from 'prop-types';

// application
import Menu from './Menu';
import { ArrowRoundedDown7x5Svg } from '../../svg';
import { setClass } from '../../helpers/general';
import useOnClickOutside from '../hooks/useOnClickOutside';

function Dropdown({ onClick, title, withIcons, items }) {
    const [open, setOpen] = useState(false);
    const wrapperRef = useRef(null);
    useOnClickOutside(wrapperRef, () => setOpen(false));

    function handleButtonClick() {
        setOpen((open) => !open);
    }

    function handleItemClick(item) {
        setOpen(false);

        if (onClick) {
            onClick(item);
        }
    }

    return (
        <div className={`topbar-dropdown ${setClass(open, 'topbar-dropdown--opened')}`} ref={wrapperRef}>
            <button className="topbar-dropdown__btn" type="button" onClick={handleButtonClick}>
                {title}
                <ArrowRoundedDown7x5Svg />
            </button>

            <div className="topbar-dropdown__body">
                <Menu layout="topbar" withIcons={withIcons} items={items} onClick={handleItemClick} />
            </div>
        </div>
    );
}

Dropdown.propTypes = {
    /** title */
    title: PropTypes.node.isRequired,
    /** array of menu items */
    items: PropTypes.array.isRequired,
    /** default: false */
    withIcons: PropTypes.bool,
    /** callback function that is called when the item is clicked */
    onClick: PropTypes.func,
};

Dropdown.defaultProps = {
    withIcons: false,
    onClick: undefined,
};

export default Dropdown;
