import { FormattedMessage } from 'react-intl';
import Currency from './Currency';
import React from 'react';

function RowTableTotals({ name, amount, className }) {
    return (
        <tr className={className}>
            <th>
                <FormattedMessage id={name} />
            </th>
            <td>{className === 'color-bonus' ? amount : <Currency value={amount} />}</td>
        </tr>
    );
}

export default RowTableTotals;
