import React from 'react';

function FetchLoader({ children, loaded, size = '' }) {
    let minHeight = '';
    if (size === LOADER_SIZES.SMALL) {
        minHeight = '40px';
    } else {
        minHeight = '70px';
    }

    return loaded ? (
        children
    ) : (
        <span style={{ minHeight }}>
            <div className={`fetch-loader ${size}`} />
        </span>
    );
}

export default FetchLoader;

export const LOADER_SIZES = {
    SMALL: 'small',
    BIG: 'medium',
};
