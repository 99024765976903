export const SIDEBAR_OPEN = 'SIDEBAR_OPEN';
export const SIDEBAR_CLOSE = 'SIDEBAR_CLOSE';

export function sidebarOpen() {
    return { type: SIDEBAR_OPEN };
}

export function sidebarClose() {
    return { type: SIDEBAR_CLOSE };
}

const initialState = {
    open: false,
};

export function sidebar(state = initialState, action) {
    switch (action.type) {
        case SIDEBAR_OPEN:
            return {
                ...state,
                open: true,
            };
        case SIDEBAR_CLOSE:
            return {
                ...state,
                open: false,
            };
        default:
            return state;
    }
}
