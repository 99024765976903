// react
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

// third-party
import { Link } from 'react-router-dom';

// data stubs
import Currency from '../shared/Currency';
import axios from 'axios';
import { GLOBAL_URL, IMAGE_URL } from '../config/general';
import Cookies from 'js-cookie';
import { getDateFromIso } from '../../helpers/general';
import AddressCard from './accountPageProfile/components/AddressCard';
import UnipackHelmet from '../shared/UnipackHelmet';
import { FormattedMessage, useIntl } from 'react-intl';
import FetchLoader from '../shared/FetchLoader';

function AccountPageOrderDetails({ match, ...redux }) {
    const { locale } = redux;
    const [order, setOrder] = useState({ orders: [], additionalLines: [] });
    const [orderLoaded, setOrderLoaded] = useState(false);
    const intl = useIntl();

    useEffect(() => {
        axios
            .post(
                GLOBAL_URL + 'order/getOrderSingleHistory',
                { orderId: match.params.orderId, isAdmin: false },
                {
                    headers: {
                        authorization: Cookies.get('token'),
                    },
                }
            )
            .then((res) => {
                const { data, error } = res.data;
                if (!error) {
                    setOrderLoaded(true);
                    setOrder(data);
                }
            })
            .catch((e) => console.log(e));
    }, [match.params.orderId]);

    //TODO product details/params
    const items = order.orders.map((product) => {
        const options = (product.options || []).map((option, i) => (
            <li className="order-list__options-item" key={i}>
                <span className="order-list__options-label">
                    {option.label}
                    {': '}
                </span>
                <span className="order-list__options-value">{option.value}</span>
            </li>
        ));

        return (
            <tr key={product.id}>
                <td className="order-list__column-image">
                    <Link to={{ pathname: `/shop/product/${product.id}`, product: product }}>
                        <img src={IMAGE_URL + product.avatar} alt="" />
                    </Link>
                </td>
                <td className="order-list__column-product">
                    <Link to={{ pathname: `/shop/product/${product.id}`, product: product }}>
                        {product[`description_short_${locale}`]}
                    </Link>
                    {options.length > 0 && (
                        <div className="order-list__options">
                            <ul className="order-list__options-list">{options}</ul>
                        </div>
                    )}
                </td>
                <td className="order-list__column-quantity" data-title={`${intl.formatMessage({ id: 'Qty' })}:`}>
                    {product.product_count}
                </td>
                <td className="order-list__column-total">
                    <Currency value={product.product_count * product.price} />
                </td>
            </tr>
        );
    });

    const orderDate = getDateFromIso(order.created_at);
    let orderDateText = '';
    if (!!orderDate.monthText) {
        orderDateText = `${orderDate.day} ${intl.formatMessage({ id: orderDate.monthText })} ${orderDate.year}`;
    }
    return (
        <FetchLoader loaded={orderLoaded}>
            {order.id ? (
                <>
                    <UnipackHelmet title="Order Details" />

                    <div className="order-success__meta">
                        <ul className="order-success__meta-list">
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">
                                    <FormattedMessage id="Order number" />:
                                </span>
                                <span className="order-success__meta-value">{`#${order.order_id}`}</span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">
                                    <FormattedMessage id="Created at" />:
                                </span>
                                <span className="order-success__meta-value">{orderDateText}</span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">
                                    <FormattedMessage id="Total" />:
                                </span>
                                <span className="order-success__meta-value">
                                    <Currency value={order.total_amount} />
                                </span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">
                                    <FormattedMessage id="Payment method" />:
                                </span>
                                {/*TODO will cash stay fixed ?*/}
                                <span className="order-success__meta-value">
                                    <FormattedMessage id="Cash" />
                                </span>
                            </li>
                        </ul>
                    </div>

                    <div className="card">
                        <div className="order-list">
                            <table>
                                <thead className="order-list__header">
                                    <tr>
                                        <th className="order-list__column-label" colSpan="2">
                                            <FormattedMessage id="Product" />
                                        </th>
                                        <th className="order-list__column-quantity">
                                            <FormattedMessage id="Qty" />
                                        </th>
                                        <th className="order-list__column-total">
                                            <FormattedMessage id="Total" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="order-list__products">{items}</tbody>
                                <tbody className="order-list__subtotals">
                                    <tr>
                                        <th className="order-list__column-label" colSpan="3">
                                            <FormattedMessage id="Subtotal" />
                                        </th>
                                        <td className="order-list__column-total">
                                            <Currency value={order.sub_total} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="order-list__column-label" colSpan="3">
                                            <FormattedMessage id="Shipping" />
                                        </th>
                                        <td className="order-list__column-total">
                                            <Currency value={order.shipping_price} />
                                        </td>
                                    </tr>
                                    <tr className="color-discount">
                                        <th className="order-list__column-label" colSpan="3">
                                            <FormattedMessage id="Discount" />
                                        </th>
                                        <td className="order-list__column-total">
                                            {<Currency value={order.discount} />}
                                        </td>
                                    </tr>
                                    {/*<tr className="color-bonus">*/}
                                    {/*    <th className="order-list__column-label" colSpan="3">*/}
                                    {/*        <FormattedMessage id="Bonus" />*/}
                                    {/*    </th>*/}
                                    {/*    <td className="order-list__column-total">{order.bonus}</td>*/}
                                    {/*</tr>*/}
                                </tbody>
                                <tfoot className="order-list__footer">
                                    <tr>
                                        <th className="order-list__column-label" colSpan="3">
                                            <FormattedMessage id="Total" />
                                        </th>
                                        <td className="order-list__column-total">
                                            <Currency value={order.total_amount} />
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    <div className="row mt-3 no-gutters mx-n2">
                        <div className="col-sm-12 col-12 px-2">
                            <AddressCard address={order.shipping_details} editable={false} />
                        </div>
                    </div>
                </>
            ) : (
                <div className="block block-empty">
                    <div className="container">
                        <div className="block-empty__body">
                            <div className="block-empty__message">
                                <FormattedMessage id="No order with this id!" />
                            </div>
                            <div className="block-empty__actions">
                                <Link to="/account/orders" className="btn btn-primary btn-sm">
                                    <FormattedMessage id="Continue" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </FetchLoader>
    );
}

const mapStateToProps = ({ locale }) => ({
    locale,
});

export default connect(mapStateToProps)(AccountPageOrderDetails);
