import axios from 'axios';
import { GLOBAL_URL } from '../../components/config/general';
import Cookies from 'js-cookie';
import { createAction, createReducer } from '../../helpers/redux';
import { setIsGiftsLoaded } from './loadersDucks';

export const SET_GIFTS = 'SET_GIFTS';

export const setGifts = createAction(SET_GIFTS);

//prettier-ignore
export function getGifts() {
    return (dispatch) => {
        dispatch(setIsGiftsLoaded(false));
        return axios.post(GLOBAL_URL + 'gift/get',{}, {
            headers: {
                authorization: Cookies.get('token'),
            }}).then((res) => {
            const { data, error } = res.data;
            if (!error) {
                dispatch(setGifts(data));
            }
            dispatch(setIsGiftsLoaded(true));
        });
    };
}

const initialState = [];

export const gifts = createReducer(initialState, (state, { value }) => ({
    [SET_GIFTS]: () => value,
}));
