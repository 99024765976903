// react
import { useEffect, useState } from 'react';

// third-party
import PropTypes from 'prop-types';

function AsyncAction({ action, render }) {
    const [loading, setLoading] = useState(false);
    const [canceled, setCanceled] = useState(false);
    useEffect(() => {
        return () => setCanceled(true);
    }, []);

    function run() {
        if (loading || !action) {
            return;
        }

        setLoading(true);

        action()
            .then(() => {
                if (canceled) {
                    return;
                }

                setLoading(false);
            })
            .catch((e) => console.log(e));
    }

    return !!render ? render({ run: run, loading }) : null;
}

AsyncAction.propTypes = {
    action: PropTypes.func,
    render: PropTypes.func,
};

export default AsyncAction;
