import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

function CompanyCard({ companyName, email, bonus }) {
    return (
        <div className="dashboard__profile card profile-card">
            <div className="card-body profile-card__body">
                <div className="profile-card__avatar">
                    <img src="images/avatars/avatar-3.jpg" alt="" />
                </div>
                <div className="profile-card__name">{companyName}</div>
                <div className="profile-card__email">{email}</div>
                {/*<div className="profile-card__bonus color-bonus">*/}
                {/*    <FormattedMessage id="Bonus" />: {Number(bonus || 0).toFixed(2)}*/}
                {/*</div>*/}
                <div className="profile-card__edit">
                    <Link to="edit-profile" className="btn btn-secondary btn-sm">
                        <FormattedMessage id="Edit Profile" />
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default CompanyCard;
