import { useHistory } from 'react-router-dom';
import { getStoreState } from '../../redux/store';

export default function useAuthRedirect(onClick) {
    let history = useHistory();
    return () => {
        if (getStoreState().isAuthenticated) {
            return onClick();
        } else {
            history.push('/account/login');
            return Promise.resolve();
        }
    };
}
