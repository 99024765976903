import axios from 'axios';
import { GLOBAL_URL } from '../components/config/general';
import Cookies from 'js-cookie';

export function getOrderHistory(limit, page) {
    return new Promise((resolve) => {
        axios
            .post(
                GLOBAL_URL + 'order/getUserOrderHistory',
                { limit, page, isAdmin: false, type: 'all' },
                {
                    headers: {
                        authorization: Cookies.get('token'),
                    },
                }
            )
            .then((res) => {
                const { data, error } = res.data;
                if (!error) {
                    resolve(data);
                }
            })
            .catch((e) => console.log(e));
    });
}

export function getSingleProduct(productID) {
    return new Promise((resolve) => {
        axios
            .post(GLOBAL_URL + 'products/getSingle', {
                productID,
            })
            .then((res) => {
                const { reviews, product } = res.data.data;
                resolve({ product, reviews });
            })
            .catch((e) => console.log(e));
    });
}

export function getRelatedProducts(product) {
    return new Promise((resolve) => {
        if (!!product.id) {
            axios
                .post(
                    GLOBAL_URL + 'products/getRelatedProducts',
                    {
                        category_id: product.category_id,
                    },
                    {
                        headers: {
                            authorization: Cookies.get('token'),
                        },
                    }
                )
                .then((res) => {
                    const { data } = res.data;
                    resolve(data);
                });
        }
    });
}
