import { SliderLeftArrow } from '../../svg';
import React from 'react';

export default function SliderPrevArrow({ className, style, onClick, customClass }) {
    return (
        <span style={{ ...style }} className={`${className} slider-arrow arrow-left ${customClass}`} onClick={onClick}>
            <SliderLeftArrow />
        </span>
    );
}
