// react
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

// blocks
import BlockBanner from '../blocks/BlockBanner';
import BlockFeatures from '../blocks/BlockFeatures';
import BlockProducts from '../blocks/BlockProducts';
import BlockSlideShow from '../blocks/BlockSlideShow';
import BlockMobileMenu from '../blocks/BlockMobileMenu';
import BlockTabbedProductsCarousel from '../blocks/BlockTabbedProductsCarousel';

import { getHomePageProducts } from '../../redux/ducks/homePageDataDucks';
import UnipackHelmet from '../shared/UnipackHelmet';

function HomePage({ getHomePageProducts, homePageProducts, banners, locale }) {
    const [productCarouselTitle, setProductCarouselTitle] = useState('');
    const webBanners = banners?.web?.home?.top?.[locale] || [];
    const mobileBanners = banners?.mobile?.home?.top?.[locale] || [];

    const newBanners = mobileBanners.reduce((acc, el, i) => {
        acc.push({ mobile: el, web: webBanners[i] });
        return acc;
    }, []);

    useEffect(() => {
        getHomePageProducts();
    }, [getHomePageProducts]);
    return (
        <>
            <UnipackHelmet title="Home" />

            <BlockSlideShow withDepartments slides={newBanners} />

            <BlockMobileMenu />

            <BlockFeatures />

            <BlockTabbedProductsCarousel
                title={productCarouselTitle}
                layout="grid-4"
                rows={1}
                setProductCarouselTitle={setProductCarouselTitle}
                homePageProducts={homePageProducts}
            />

            <BlockBanner />

            <BlockProducts
                title="Featured"
                layout="large-first"
                featuredProduct={
                    !!homePageProducts && !!homePageProducts.is_featured && homePageProducts.is_featured[0]
                }
                products={homePageProducts.is_featured.slice(1, 7)}
            />

            {/*<BlockCategories title="Popular Categories" layout="classic" categories={categories} />*/}

            {/*<BlockTabbedProductsCarousel title="New Arrivals" layout="horizontal" rows={2} />*/}

            {/*<BlockPosts title="Latest News" layout="list-sm" posts={posts} />*/}

            {/*<BlockBrands />*/}

            {/*<BlockProductColumns columns={columns} />*/}
        </>
    );
}

const mapStateToProps = ({ homePageProducts, banners, locale }) => ({
    homePageProducts,
    banners,
    locale,
});

const mapDispatchToProps = { getHomePageProducts };

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
