// react
import React, { useEffect, useState } from 'react';

// third-party
import PropTypes from 'prop-types';
import { BrowserRouter, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { IntlProvider } from 'react-intl';

// application
import languages from '../i18n';
import { changeLocale } from '../redux/ducks/localeDucks';

// pages
import Layout from './Layout';
import HomePage from './home/HomePage';
import { getConfigs } from '../redux/ducks/configsDucks';
import { getUser } from '../redux/ducks/userDucks';
import { getLocalLanguage } from '../helpers/general';
import PreLoader from './PreLoader';
import TempMenuLayout from './tempMenu/TempMenuLayout';

export function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({
            top: 0,
        });
    }, [pathname]);

    return null;
}

function App({ locale, getConfigs, getUser, changeLocale, loaders }) {
    const { messages } = languages[locale];

    useEffect(() => {
        if (!getLocalLanguage()) {
            changeLocale('am');
        }
        getUser();
        getConfigs();
    }, []);

    const [isContentLoaded, setIsContentLoaded] = useState(false);

    useEffect(() => {
        const { isUserLoaded, isConfigsLoaded, isUserSignedIn, isCartLoaded, isOrderPlaced } = loaders;
        const areLoaded = [isUserLoaded, isConfigsLoaded, isUserSignedIn, isCartLoaded, isOrderPlaced].every(
            (loader) => !!loader
        );
        setIsContentLoaded(areLoaded);
    }, [loaders]);

    return (
        <>
            {!isContentLoaded && <PreLoader />}
            <IntlProvider locale={locale} messages={messages}>
                <BrowserRouter basename={process.env.PUBLIC_URL}>
                    <ScrollToTop />
                    <HelmetProvider>
                        <Helmet htmlAttributes={{ lang: locale }} />
                        <Switch>
                            <Route path="/menu" render={(props) => <TempMenuLayout {...props} />} />
                            <Route
                                path="/"
                                render={(props) => (
                                    <Layout {...props} headerLayout="default" homeComponent={HomePage} />
                                )}
                            />
                            <Redirect to="/" />
                        </Switch>
                    </HelmetProvider>
                </BrowserRouter>
            </IntlProvider>
        </>
    );
}

App.propTypes = {
    /** current locale */
    locale: PropTypes.string,
};

const mapStateToProps = ({ locale, loaders }) => ({
    locale,
    loaders,
});

const mapDispatchToProps = {
    changeLocale: changeLocale,
    getConfigs,
    getUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
