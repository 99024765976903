// react
import React, { useState } from 'react';

// data stubs
import FormInput from '../shared/FormInput';
import { useFormik } from 'formik';
import axios from 'axios';
import { GLOBAL_URL } from '../config/general';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import useFormWithErrors from '../hooks/useFormWithErrors';
import UnipackHelmet from '../shared/UnipackHelmet';
import { useAreFieldsEmpty } from '../hooks/useAreFieldsEmpty';
import { FormattedMessage } from 'react-intl';
import { TOAST_MESSAGES } from '../../constants/general';

const changePasswordData = [
    {
        label: 'Current Password',
        type: 'password',
        placeholder: 'Current Password',
        id: 'password_current',
        message: '',
        error: false,
    },
    {
        label: 'New Password',
        type: 'password',
        placeholder: 'New Password',
        id: 'password_new',
        message: '',
        error: false,
    },
    {
        label: 'Repeat Password',
        type: 'password',
        placeholder: 'Password',
        id: 'password_confirm',
        message: '',
        error: false,
    },
];

export default function AccountPagePassword() {
    const [formErrors, setFormErrors] = useState([]);
    const formik = useFormik({
        initialValues: {
            password_current: '',
            password_new: '',
            password_confirm: '',
        },
        onSubmit: (values) => {
            handleChangePassword(values);
        },
    });

    const areFieldsEmpty = useAreFieldsEmpty(formik.values);

    const formInputData = useFormWithErrors(changePasswordData, formErrors);

    return (
        <div className="card">
            <UnipackHelmet title="Change Password" />

            <div className="card-header">
                <h5>
                    <FormattedMessage id="Change Password" />
                </h5>
            </div>
            <div className="card-divider" />
            <div className="card-body">
                <div className="row no-gutters">
                    <div className="col-12 col-lg-7 col-xl-6">
                        <form onSubmit={formik.handleSubmit}>
                            {formInputData.map(({ id, label, type, placeholder, error, message }) => (
                                <FormInput
                                    value={formik.values[id]}
                                    onChange={formik.handleChange}
                                    type={type}
                                    message={message}
                                    error={error}
                                    label={label}
                                    placeholder={placeholder}
                                    id={id}
                                    key={id}
                                />
                            ))}

                            <div className="form-group mt-5 mb-0">
                                <button type="submit" className="btn btn-primary" disabled={areFieldsEmpty}>
                                    <FormattedMessage id="Change" />
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
    function handleChangePassword(values) {
        const { password_current, password_new, password_confirm } = values;
        console.log(values);
        //prettier-ignore
        axios.post(
            GLOBAL_URL + 'users/editPassword',
            { password: password_current, newPassword: password_new, confirmPassword: password_confirm },
            {
                headers: {
                    authorization: Cookies.get('token'),
                },
            }
        ).then((res) => {
            const { data, error } = res.data;
            if (!error) {
                toast.success(TOAST_MESSAGES.changeSuccess);
                setFormErrors([]);
            } else {
                setFormErrors(data);
            }
        });
    }
}
