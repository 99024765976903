import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Wishlist16Svg } from '../../svg';
import AsyncAction from './AsyncAction';
import { wishlistAddItem, wishlistRemoveItem } from '../../redux/ducks/wishlistDucks';
import useAuthRedirect from '../hooks/useAuthRedirect';

function WishlistButton({ product, ...redux }) {
    const { wishlist, wishlistAddItem, wishlistRemoveItem } = redux;
    const isInWishlist = wishlist.some((item) => item.id === product.id);
    const handleClick = useAuthRedirect(
        isInWishlist ? () => wishlistRemoveItem(product) : () => wishlistAddItem(product)
    );

    return (
        <AsyncAction
            action={handleClick}
            render={({ run, loading }) => (
                <button
                    type="button"
                    onClick={run}
                    className={classNames('btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist', {
                        'btn-loading': loading,
                    })}
                >
                    <Wishlist16Svg style={{ fill: isInWishlist ? 'red' : '' }} />
                </button>
            )}
        />
    );
}

const mapStateToProps = ({ wishlist }) => ({ wishlist });

const mapDispatchToProps = { wishlistRemoveItem, wishlistAddItem };

export default connect(mapStateToProps, mapDispatchToProps)(WishlistButton);
