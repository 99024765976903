import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { signOut } from '../../../../redux/ducks/userDucks';
import useRedirectOnClick from '../../../hooks/useRedirectOnClick';
import { FormattedMessage } from 'react-intl';

function UserDetails({ signOut, user }) {
    const { first_name, last_name, email, type, company_name } = user;
    const handleLogoutClick = useRedirectOnClick(signOut, '/account/login');
    return (
        <>
            <div className="account-menu__divider" />
            <Link to="/account/profile" className="account-menu__user">
                <div className="account-menu__user-avatar">
                    <img src="images/avatars/avatar-3.jpg" alt="" />
                </div>
                <div className="account-menu__user-info">
                    <div className="account-menu__user-name">
                        {type === 1 ? `${first_name} ${last_name}` : `${company_name}`}
                    </div>
                    <div className="account-menu__user-email">{email}</div>
                </div>
            </Link>
            <div className="account-menu__divider" />
            <ul className="account-menu__links">
                <li>
                    <Link to="/account/edit-profile">
                        <FormattedMessage id="Edit Profile" />
                    </Link>
                </li>
                <li>
                    <Link to="/account/orders">
                        <FormattedMessage id="Order History" />
                    </Link>
                </li>
                <li>
                    <Link to="/account/password">
                        <FormattedMessage id="Password" />
                    </Link>
                </li>
            </ul>
            <div className="account-menu__divider" />
            <ul className="account-menu__links">
                <li>
                    <Link to="#" onClick={handleLogoutClick}>
                        <FormattedMessage id="Logout" />
                    </Link>
                </li>
            </ul>
        </>
    );
}

const mapStateToProps = ({ user }) => ({
    user,
});

const mapDispatchToProps = {
    signOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
