// react
import React, { useState, useEffect } from 'react';

// third-party
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { setIsOrderHistoryLoaded } from '../../redux/ducks/loadersDucks';

// application
import Pagination from '../shared/Pagination';
import { getDateFromIso } from '../../helpers/general';
import Currency from '../shared/Currency';
import { ORDER_STATUS_NAMES } from '../../constants/names';
import { getOrderHistory } from '../../helpers/api';
import UnipackHelmet from '../shared/UnipackHelmet';
import { FormattedMessage, useIntl } from 'react-intl';
import FetchLoader from '../shared/FetchLoader';
import { Cart20Svg } from '../../svg';
import { cartAddOrder } from '../../redux/ducks/cartDucks';
const ORDER_COUNT_ON_PAGE = 6;

function AccountPageOrders(redux) {
    const { setIsOrderHistoryLoaded, isOrderHistoryLoaded, cartAddOrder } = redux;
    const [ordersData, setOrdersData] = useState({ count: 0, orders: [] });
    const [page, setPage] = useState(1);
    const totalPages = Math.ceil(ordersData.count / ORDER_COUNT_ON_PAGE);
    const intl = useIntl();

    useEffect(() => {
        setIsOrderHistoryLoaded(false);
        getOrderHistory(ORDER_COUNT_ON_PAGE, page).then((res) => {
            setOrdersData(res);
            setIsOrderHistoryLoaded(true);
        });

        return () => setIsOrderHistoryLoaded(false);
    }, [page]);

    return (
        <FetchLoader loaded={isOrderHistoryLoaded}>
            <div className="card">
                {!!ordersData.orders.length ? (
                    <>
                        <UnipackHelmet title="Order History" />

                        <div className="card-header">
                            <h5>
                                <FormattedMessage id="Order History" />
                            </h5>
                        </div>
                        <div className="card-divider" />
                        <div className="card-table">
                            <div className="table-responsive-sm">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>
                                                <FormattedMessage id="Order" />
                                            </th>
                                            <th>
                                                <FormattedMessage id="Date" />
                                            </th>
                                            <th>
                                                <FormattedMessage id="Status" />
                                            </th>
                                            <th>
                                                <FormattedMessage id="Total" />
                                            </th>
                                            <th>{'    '}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ordersData.orders.map((order) => {
                                            const date = getDateFromIso(order.created_at);
                                            const dateText = `${date.day} ${intl.formatMessage({
                                                id: date.monthText,
                                            })} ${date.year}`;
                                            return (
                                                <tr key={order.id}>
                                                    <td>
                                                        <Link
                                                            to={`/account/orders/${order.order_id}`}
                                                        >{`#${order.order_id}`}</Link>
                                                    </td>
                                                    <td>{dateText}</td>
                                                    <td>
                                                        {
                                                            <FormattedMessage
                                                                id={ORDER_STATUS_NAMES[order.order_status]}
                                                            />
                                                        }
                                                    </td>
                                                    <td>
                                                        <Currency value={order.total_amount} />
                                                    </td>
                                                    <td>
                                                        <button
                                                            className="order-history-cart-button"
                                                            onClick={() => onCartButtonClick(order.order_id)}
                                                        >
                                                            <Cart20Svg />
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card-divider" />
                        <div className="card-footer">
                            <Pagination current={page} total={totalPages} onPageChange={handlePageChange} />
                        </div>
                    </>
                ) : (
                    <div className="block-empty__body" style={{ padding: '18px 0 18px' }}>
                        <div className="block-empty__message">
                            <FormattedMessage id="Your order history is empty!" />
                        </div>
                        <div className="block-empty__actions">
                            <Link to="/" className="btn btn-primary btn-sm">
                                <FormattedMessage id="Continue" />
                            </Link>
                        </div>
                    </div>
                )}
            </div>
        </FetchLoader>
    );

    function handlePageChange(page) {
        setPage(page);
    }

    function onCartButtonClick(orderId) {
        cartAddOrder(orderId);
    }
}

const mapStateToProps = ({ loaders: { isOrderHistoryLoaded } }) => ({ isOrderHistoryLoaded });

const mapDispatchToProps = { setIsOrderHistoryLoaded, cartAddOrder };

export default connect(mapStateToProps, mapDispatchToProps)(AccountPageOrders);
