// react
import React, { useEffect, useState } from 'react';

// third-party
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// application
import CategorySidebar from './components/CategorySidebar';
import PageHeader from '../shared/PageHeader';
import ProductsView from './components/ProductsView';
import { sidebarClose } from '../../redux/ducks/sidebarDucks';

// data stubs
import { LS } from '../../constants/localStorageConst';
import { getShopProductsData } from '../../redux/ducks/shopProductsDucks';
import { resetFilteredPrice, resetFilterOptions, updateFilterOptions } from '../../redux/ducks/filtersDucks';
import UnipackHelmet from '../shared/UnipackHelmet';
import { usePrevious } from '../hooks/usePrevious';
import FetchLoader from '../shared/FetchLoader';

const PRODUCT_VIEW_LIMIT_DEFAULT = 12;
const FIRST_PAGE = 1;

function ShopPage({ viewMode, match, location, ...redux }) {
    const {
        filters,
        shopProductsData,
        getShopProductsData,
        updateFilterOptions,
        resetFilterOptions,
        isShopProductsLoaded,
        resetFilteredPrice,
    } = redux;

    const breadcrumb = [{ title: 'Home', url: '/' }];

    const [page, setPage] = useState(FIRST_PAGE);
    const [viewLimit, setViewLimit] = useState(
        localStorage.getItem(LS.PRODUCT_VIEW_LIMIT) || PRODUCT_VIEW_LIMIT_DEFAULT
    );
    const [sortValue, setSortValue] = useState('');
    const totalPages = Math.ceil(shopProductsData.count / viewLimit);
    const prevPage = usePrevious(page);
    const prevUrl = usePrevious(window.location.href);

    useEffect(() => {
        const [sort, order] = sortValue.split('-');
        const { from, to } = filters.price.filteredPrice;
        const price = { min: from, max: to };
        const isFirstPage = page === FIRST_PAGE;
        const isPageChanged = prevPage !== page;

        if (!isPageChanged && !isFirstPage) {
            setPage(FIRST_PAGE);
        } else {
            let filterOptions = filters.options.items;
            const { optionId } = match.params;
            const splitPrevUrl = prevUrl?.split('/') || [];
            const prevOptionId = splitPrevUrl[splitPrevUrl.length - 1];
            const isUrlChanged = optionId && prevOptionId !== optionId;
            if (isUrlChanged) {
                const isSomethingChecked = Object.values(filters.options.items).some((item) => !!item.checked);
                if (isSomethingChecked) {
                    resetFilterOptions();
                }
                if (!!optionId) {
                    filterOptions = getFilterOptionByParam(optionId);
                    const filterOptionFromUrl = Object.values(filterOptions).find((item) => !!item.checked);
                    if (filterOptionFromUrl?.option) {
                        updateFilterOptions(filterOptionFromUrl.option);
                    }
                }
                resetFilteredPrice();
            }

            getShopProductsData({
                orderBy: sort,
                order,
                limit: viewLimit,
                categoryId: match.params.categoryId,
                isNew: filterOptions.newProductOption.checked,
                isSale: filterOptions.saleProductOption.checked,
                isFeatured: filterOptions.featuredProductOption.checked,
                page,
                price,
                search: location?.search.split('=')[1],
            });
        }
    }, [
        viewLimit,
        sortValue,
        match.params.categoryId,
        filters.options.items,
        page,
        filters.price.filteredPrice,
        location.search,
        match.params.optionId,
    ]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [page]);

    useEffect(() => {
        setPage(1);
    }, [match.params.categoryId]);

    return (
        <>
            <UnipackHelmet title="Shop" />

            <PageHeader header="" breadcrumb={breadcrumb} />

            <div className="container">
                <div className={'shop-layout shop-layout--sidebar--start'}>
                    <div className="shop-layout__sidebar">
                        <CategorySidebar offcanvas={'mobile'} match={match} />
                    </div>
                    <div className="shop-layout__content">
                        <div className="block">
                            <FetchLoader loaded={isShopProductsLoaded}>
                                <ProductsView
                                    layout={viewMode}
                                    grid="grid-3-sidebar"
                                    limit={1}
                                    offcanvas={'mobile'}
                                    shopProductsData={shopProductsData}
                                    viewLimit={viewLimit}
                                    page={page}
                                    sortValue={sortValue}
                                    totalPages={totalPages}
                                    onSortByDropdown={handleSortByDropdown}
                                    onViewLimitDropdown={handleViewLimitDropdown}
                                    onPageChange={handlePageChange}
                                />
                            </FetchLoader>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

    function handlePageChange(page) {
        setPage(page);
    }

    function handleViewLimitDropdown({ target: { value } }) {
        setViewLimit(value);
        localStorage.setItem(LS.PRODUCT_VIEW_LIMIT, value);
    }

    function handleSortByDropdown({ target: { value } }) {
        setSortValue(value);
    }
}

function getFilterOptionByParam(optionId) {
    return {
        newProductOption: { checked: optionId === 'new', option: 'newProductOption' },
        saleProductOption: { checked: optionId === 'sale', option: 'saleProductOption' },
        featuredProductOption: { checked: false, option: '' },
        generalProductOption: { checked: optionId === 'products', option: 'reset' },
    };
}

ShopPage.propTypes = {
    /**
     * mode of viewing the list of products (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    viewMode: PropTypes.oneOf(['grid', 'grid-with-features', 'list']),
};

ShopPage.defaultProps = {
    columns: 3,
    viewMode: 'grid',
    sidebarPosition: 'start',
};

const mapStateToProps = ({ shopProductsData, filters, loaders: { isShopProductsLoaded } }) => ({
    shopProductsData,
    filters,
    isShopProductsLoaded,
});

const mapDispatchToProps = {
    sidebarClose,
    getShopProductsData,
    updateFilterOptions,
    resetFilterOptions,
    resetFilteredPrice,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPage);
