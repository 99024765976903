// react
import React, { useState, useRef, useEffect } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import useOnClickOutside from '../hooks/useOnClickOutside';

function Indicator(props) {
    const [isOpen, setIsOpen] = useState(false);
    const wrapperRef = useRef(null);
    const {
        history: {
            location: { pathname },
        },
    } = props;

    useOnClickOutside(wrapperRef, () => isOpen && setIsOpen(false));

    useEffect(() => {
        if (isOpen) {
            if (typeof props.onOpen === 'function') {
                props.onOpen();
            }
        }
    }, [isOpen]);

    useEffect(() => {
        setIsOpen(false);
    }, [pathname]);

    const { url, className, icon } = props;
    let { value, dropdown } = props;
    let button;
    if (value !== undefined) {
        value = <span className="indicator__value">{value}</span>;
    }

    const handleButtonClick = (event) => {
        const { onClick, dropdown } = props;

        if (dropdown) {
            event.preventDefault();
        }

        setIsOpen(!isOpen);

        if (onClick) {
            onClick(event);
        }
    };

    const title = (
        <span className="indicator__area">
            {icon}
            {value}
        </span>
    );

    if (url) {
        button = (
            <Link to={url} className="indicator__button" onClick={handleButtonClick}>
                {title}
            </Link>
        );
    } else {
        button = (
            <button type="button" className="indicator__button" onClick={handleButtonClick}>
                {title}
            </button>
        );
    }

    if (dropdown) {
        dropdown = <div className="indicator__dropdown">{dropdown}</div>;
    }

    const classes = classNames(`indicator indicator--trigger--click ${className}`, {
        'indicator--opened': isOpen,
    });

    return (
        <div className={classes} ref={wrapperRef}>
            {button}
            {dropdown}
        </div>
    );
}

Indicator.propTypes = {
    /** indicator value */
    value: PropTypes.number,
    /** the component that will be shown in the dropdown */
    dropdown: PropTypes.node,
    /** indicator icon */
    icon: PropTypes.node,
    /** indicator url */
    url: PropTypes.string,
    /** callback function that is called when the dropdown is opened */
    onOpen: PropTypes.func,
    /** callback function that is called when the dropdown is closed */
    onClose: PropTypes.func,
};

export default withRouter(Indicator);
