// react
import React from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import AsyncAction from '../shared/AsyncAction';
import Currency from '../shared/Currency';
import Indicator from './Indicator';
import { Cart20Svg, Cross10Svg } from '../../svg';
import { cartRemoveItem, getUserCart } from '../../redux/ducks/cartDucks';
import { getTitleLocale } from '../../helpers/general';
import { IMAGE_URL } from '../config/general';
import { FormattedMessage } from 'react-intl';
import RowTableTotals from '../shared/RowTableTotals';

function IndicatorCart({ cart, cartRemoveItem, locale, getUserCart }) {
    let dropdown;

    const items = cart.products.map((product) => {
        let image;

        if (!!product.avatar) {
            image = (
                <div className="dropcart__product-image">
                    <Link to={`/shop/product/${product.id}`}>
                        <img src={IMAGE_URL + product.avatar} alt="" />
                    </Link>
                </div>
            );
        }

        const removeButton = (
            <AsyncAction
                action={() => cartRemoveItem(product)}
                render={({ run, loading }) => {
                    const classes = classNames('dropcart__product-remove btn btn-light btn-sm btn-svg-icon', {
                        'btn-loading': loading,
                    });

                    return (
                        <button type="button" onClick={run} className={classes}>
                            <Cross10Svg />
                        </button>
                    );
                }}
            />
        );

        const { discounted_price, price } = product;
        const productPrice = !!Number(discounted_price) ? discounted_price : price;

        return (
            <div key={product.id} className="dropcart__product">
                {image}
                <div className="dropcart__product-info">
                    <div className="dropcart__product-name">
                        <Link to={{ pathname: `/shop/product/${product.id}`, product: product }}>
                            {product[getTitleLocale(locale)]}
                        </Link>
                    </div>
                    <div className="dropcart__product-meta">
                        <span className="dropcart__product-quantity">{product.product_count}</span>
                        {' × '}
                        <span className="dropcart__product-price">
                            <Currency value={productPrice} />
                            {!!Number(discounted_price) && (
                                <Currency value={product.price} classNames={'small'} isDiscounted={true} />
                            )}
                        </span>
                    </div>
                </div>
                {removeButton}
            </div>
        );
    });

    if (!!cart.products.length) {
        dropdown = (
            <div className="dropcart">
                <div className="dropcart__products-list">{items}</div>

                <div className="dropcart__totals">
                    <table>
                        <tbody>
                            <RowTableTotals name="Subtotal" amount={cart.subTotal} />
                            <RowTableTotals name="Shipping" amount={cart.shippingAmount} />
                            <RowTableTotals name="Discount" amount={cart.discount} className={'color-discount'} />
                            {/*<RowTableTotals name="Bonus" amount={cart.bonus} className={'color-bonus'} />*/}
                            <RowTableTotals name="Total" amount={cart.totalAmount} />
                        </tbody>
                    </table>
                </div>

                <div className="dropcart__buttons">
                    <Link className="btn btn-secondary" to="/shop/cart">
                        <FormattedMessage id="View Cart" />
                    </Link>
                    <Link className="btn btn-primary" to="/shop/checkout">
                        <FormattedMessage id="Checkout" />
                    </Link>
                </div>
            </div>
        );
    } else {
        dropdown = (
            <div className="dropcart">
                <div className="dropcart__empty">
                    <FormattedMessage id="Your shopping cart is empty!" />
                </div>
            </div>
        );
    }

    return (
        <Indicator
            url="/shop/cart"
            dropdown={dropdown}
            value={cart.products.length}
            icon={<Cart20Svg />}
            onOpen={() => getUserCart(true)}
        />
    );
}

const mapStateToProps = ({ cart, locale }) => ({
    cart,
    locale,
});

const mapDispatchToProps = {
    cartRemoveItem,
    getUserCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorCart);
