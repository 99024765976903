import { SliderRightArrow } from '../../svg';
import React from 'react';

export default function SliderNextArrow({ className, style, onClick, customClass }) {
    return (
        <span style={{ ...style }} className={`${className} slider-arrow arrow-right ${customClass}`} onClick={onClick}>
            <SliderRightArrow />
        </span>
    );
}
