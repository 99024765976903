import { useEffect, useState } from 'react';

export function useAreFieldsEmpty(values) {
    const [areFieldsEmpty, setAreFieldsEmpty] = useState(true);

    useEffect(() => {
        const areEmpty = !Object.values(values).every((field) => !!field);
        setAreFieldsEmpty(areEmpty);
    }, [values]);

    return areFieldsEmpty;
}
