import { toast } from 'react-toastify';
import axios from 'axios';
import { GLOBAL_URL } from '../../components/config/general';
import Cookies from 'js-cookie';
import { createAction, createReducer } from '../../helpers/redux';
import { getLocalLanguage, getTitleLocale } from '../../helpers/general';
import { TOAST_MESSAGES } from '../../constants/general';

export const SET_USER_WISHLIST = 'SET_USER_WISHLIST';
export const DELETE_USER_WISHLIST = 'DELETE_USER_WISHLIST';

export const setUserWishlist = createAction(SET_USER_WISHLIST);
export const deleteUserWishlist = createAction(DELETE_USER_WISHLIST);

//prettier-ignore
export function getUserWishlist() {
    return (dispatch) => {
        return axios.post(GLOBAL_URL + 'wishlist/get',{}, {
            headers: {
                authorization: Cookies.get('token'),
            }}).then((res) => {
            const { data, error } = res.data;
            if (!error) {
                dispatch(setUserWishlist(data));
            }
        });
    };
}

//prettier-ignore
export function wishlistAddItem(product) {
    return (dispatch) => {
        return axios.post(GLOBAL_URL + 'wishlist/add', {productID: product.id}, {
                headers: {
                    authorization: Cookies.get('token'),
                }
            }).then((res) => {
            const { data, error } = res.data;
                if (!error) {
                    dispatch(setUserWishlist(data));
                    toast.success(TOAST_MESSAGES.wishlistAddSuccess(product[getTitleLocale(getLocalLanguage())]));
                }
            });
    };
}

//prettier-ignore
export function wishlistRemoveItem(product) {
    return (dispatch) => {
        return axios.post(GLOBAL_URL + 'wishlist/delete', {productID: product.id}, {
            headers: {
                authorization: Cookies.get('token'),
            }
        }).then((res) => {
            const { data, error } = res.data;
            if (!error) {
                dispatch(setUserWishlist(data || []));
                toast.info(TOAST_MESSAGES.wishlistRemoveSuccess(product[getTitleLocale(getLocalLanguage())]));
            }
        });
    }
}

const initialState = [];

export const wishlist = createReducer(initialState, (state, { value }) => ({
    [SET_USER_WISHLIST]: () => value,
    [DELETE_USER_WISHLIST]: () => initialState,
}));
