import { createAction, createReducer } from '../../helpers/redux';
import axios from 'axios';
import { GLOBAL_URL } from '../../components/config/general';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { getLocalCart, getLocalLanguage, getTitleLocale, resetLocalCart, setLocalCart } from '../../helpers/general';
import { TOAST_MESSAGES } from '../../constants/general';
import { setIsCartLoaded } from './loadersDucks';

const SET_CART = 'SET_CART';
const DELETE_CART = 'DELETE_CART';
const GUEST_ADD_CART_ITEM = 'GUEST_ADD_CART_ITEM';
const GUEST_REMOVE_CART_ITEM = 'GUEST_REMOVE_CART_ITEM';
const GUEST_UPDATE_CART = 'GUEST_UPDATE_CART';
const SET_CART_DEFAULT_PROPS = 'SET_CART_DEFAULT_PROPS';

export const setCart = createAction(SET_CART);
export const deleteCart = createAction(DELETE_CART);

const guestAddCartItem = createAction(GUEST_ADD_CART_ITEM);
const guestRemoveCartItem = createAction(GUEST_REMOVE_CART_ITEM);
const guestUpdateCart = createAction(GUEST_UPDATE_CART);
export const setCartDefaultProps = createAction(SET_CART_DEFAULT_PROPS);

//prettier-ignore
export function getUserCart(withoutLoading = false) {
    return (dispatch) => {
        !withoutLoading && dispatch(setIsCartLoaded(false));
        let products = [];
        try{
            products = JSON.parse(getLocalCart()).products;
        }catch (e) {
            resetLocalCart()
        }
        return axios.post(GLOBAL_URL + 'basket/merge',{basket: products}, {
            headers: {
                authorization: Cookies.get('token'),
            }}).then((res) => {
            const { data, error } = res.data;
            if (!error) {
                dispatch(setCart({ ...data, loaded: true }));
            }else {
                dispatch(setCart({loaded: true}))
            }
            !withoutLoading && dispatch(setIsCartLoaded(true));
        }).catch(e => console.log(e));
    };
}

//prettier-ignore
export function cartAddItem(product, quantity) {
    quantity = Number(quantity) || 1;
    return (dispatch, getState) => {
        if (getState().isAuthenticated) {
            return axios.post(GLOBAL_URL + 'basket/add', {productID: product.id, productCount: quantity * product.params.Count}, {
                headers: {
                    authorization: Cookies.get('token'),
                }
            }).then((res) => {
                const { data, error, message } = res.data;
                if (!error) {
                    dispatch(setCart(data));
                    toast.success(TOAST_MESSAGES.cartAddSuccess(product[getTitleLocale(getLocalLanguage())], quantity * product.params.Count));
                }else {
                    toast.error(message);
                }
            }).catch(e => console.log(e));
        }else {
            dispatch(guestAddCartItem({ ...product, product_count:  quantity * product.params.Count }))
            toast.success(TOAST_MESSAGES.cartAddSuccess(product[getTitleLocale(getLocalLanguage())], quantity * product.params.Count));
            return Promise.resolve();
        }
    };
}

//prettier-ignore
export function cartRemoveItem(product) {
    return (dispatch, getState) => {
        if (getState().isAuthenticated){
            return axios.post(GLOBAL_URL + 'basket/delete', {productID: product.product_id}, {
                headers: {
                    authorization: Cookies.get('token'),
                }
            }).then((res) => {
                const { data, error, message } = res.data;
                if (!error) {
                    dispatch(setCart({ products: [], ...data }));
                    toast.info(TOAST_MESSAGES.cartRemoveSuccess(product[getTitleLocale(getLocalLanguage())]));
                }else {
                    toast.error(message)
                }
            }).catch(e => console.log(e));
        }else {
            dispatch(guestRemoveCartItem(product.id));
            toast.info(TOAST_MESSAGES.cartRemoveSuccess(product[getTitleLocale(getLocalLanguage())]));
            return Promise.resolve();
        }
    }
}

//prettier-ignore
export function updateCart(products) {
    return (dispatch, getState) => {
        if (getState().isAuthenticated) {
            const productsForRequest = products.map(({ id, product_count }) => ({ product_count, product_id: id }))
            return axios.post(GLOBAL_URL + 'basket/update', { products: productsForRequest }, {
                headers: {
                    authorization: Cookies.get('token'),
                }
            }).then((res) => {
                const { data, error, message } = res.data;
                if (!error) {
                    if (message === 'notAvailable'){
                        res.data.notInStock.forEach(el => {
                            toast.error(TOAST_MESSAGES.cartUpdateFail(el[getTitleLocale(getLocalLanguage())], el.available));
                        })
                    }
                    dispatch(setCart(data));
                    toast.success(TOAST_MESSAGES.updateCartSuccess);
                }else {
                    if (message === 'notAvailable'){
                        data.forEach(el => {
                            toast.error(TOAST_MESSAGES.cartUpdateFail(el[getTitleLocale(getLocalLanguage())], el.available));
                        })
                    }else {
                        toast.error(message);
                    }
                }
            }).catch(e => console.log(e));
        }else {
            toast.success(TOAST_MESSAGES.cartAddOrderSuccess);
            dispatch(guestUpdateCart(products))
            return Promise.resolve();
        }
    };
}

export function cartAddOrder(orderId) {
    return (dispatch) => {
        axios
            .post(
                GLOBAL_URL + 'basket/copyProduct',
                { order_id: orderId },
                {
                    headers: {
                        authorization: Cookies.get('token'),
                    },
                }
            )
            .then((res) => {
                const { error, data, message } = res.data;
                if (!error) {
                    dispatch(setCart(data));
                    toast.success(TOAST_MESSAGES.updateCartSuccess);
                } else {
                    toast.error(message);
                }
            })
            .catch((e) => console.log(e));
    };
}

const initialState = {
    products: [],
    discount: 0,
    subTotal: 0,
    totalAmount: 0,
    notAvailableProducts: [],
};

export const cart = createReducer(initialState, (state, { value }) => ({
    [SET_CART_DEFAULT_PROPS]: () => ({
        ...state,
        ...value,
    }),
    [SET_CART]: () => ({
        ...initialState,
        ...state,
        ...value,
    }),
    [DELETE_CART]: () => ({
        ...state,
        ...initialState,
    }),
    [GUEST_ADD_CART_ITEM]: () => {
        const { defaultShippingAmount, defaultBonusAmount, defaultShippingOptions } = state;

        const itemIndex = state.products.findIndex((product) => product.id === value.id);
        if (itemIndex === -1) {
            const products = [...state.products, value];
            const newCart = createCalculatedCart(
                products,
                defaultShippingAmount,
                defaultBonusAmount,
                defaultShippingOptions
            );
            setLocalCart(JSON.stringify(newCart));
            return { ...state, ...newCart };
        } else {
            const product = state.products[itemIndex];
            const products = [
                ...state.products.slice(0, itemIndex),
                {
                    ...product,
                    product_count: product.product_count + value.product_count,
                },
                ...state.products.slice(itemIndex + 1),
            ];

            const newCart = createCalculatedCart(
                products,
                defaultShippingAmount,
                defaultBonusAmount,
                defaultShippingOptions
            );
            setLocalCart(JSON.stringify(newCart));

            return { ...state, ...newCart };
        }
    },
    [GUEST_REMOVE_CART_ITEM]: () => {
        const { defaultShippingAmount, defaultBonusAmount, defaultShippingOptions } = state;

        const products = state.products.filter((product) => product.id !== value);
        const newCart = createCalculatedCart(
            products,
            defaultShippingAmount,
            defaultBonusAmount,
            defaultShippingOptions
        );

        setLocalCart(JSON.stringify(newCart));
        return { ...state, ...newCart };
    },
    [GUEST_UPDATE_CART]: () => {
        const { defaultShippingAmount, defaultBonusAmount, defaultShippingOptions } = state;
        const newCart = createCalculatedCart(value, defaultShippingAmount, defaultBonusAmount, defaultShippingOptions);

        setLocalCart(JSON.stringify(newCart));
        return { ...state, ...newCart };
    },
}));

function createCalculatedCart(products, shippingAmount = 0, bonusAmount = 0, shippingOptions = {}) {
    const data = {
        products,
        shippingAmount,
        discount: 0,
        subTotal: 0,
        bonus: 0,
        totalAmount: 0,
        notAvailableProducts: [],
    };

    products.forEach((product) => {
        data.discount += Number(product.discounted_price)
            ? (Number(product.price) - Number(product.discounted_price)) * Number(product.product_count)
            : 0;
        data.subTotal += Number(product.price) * Number(product.product_count);
    });

    data.totalAmount = data.subTotal - data.discount;
    if (data.totalAmount >= shippingOptions.max) {
        data.shippingAmount = 0;
    }
    data.totalAmount += +data.shippingAmount;
    data.bonus = bonusAmount * data.totalAmount;

    for (let key in data) {
        if (typeof data[key] === 'number') {
            data[key] = Number(data[key].toFixed(2));
        }
    }
    return data;
}
