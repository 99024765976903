import React from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import MobileMenu from './components/header/moblile/MobileMenu';
import MobileHeader from './components/header/moblile/MobileHeader';
import Header from './components/header/Header';
import MainCategories from './components/mainCategories/MainCategories';
import SubCategories from './components/subCategories/SubCategories';
import ProductsPage from './components/ProductsPage/ProductsPage';
import ProductPreview from './components/preview/ProductPreview';

function TempMenuLayout() {
    const urlPath = window.location.pathname?.split('/');
    const section = Number(urlPath[2]);
    return (
        <>
            <ToastContainer autoClose={4000} hideProgressBar />

            <ProductPreview />

            <MobileMenu />

            <div className="site">
                <header className="site__header d-lg-none">
                    <MobileHeader sectionNumber={section} />
                </header>

                <header className="site__header d-lg-block d-none">
                    <Header sectionNumber={section} />
                </header>

                <div className="site">
                    <div className="site__body">
                        <Switch>
                            <Route
                                exact
                                path="/menu/:section/categories"
                                render={(props) => <MainCategories {...props} />}
                            />
                            <Route
                                exact
                                path="/menu/:section/categories/:id"
                                render={(props) => <SubCategories {...props} />}
                            />
                            <Route
                                exact
                                path="/menu/:section/categories/:id/products"
                                render={(props) => <ProductsPage {...props} />}
                            />
                            <Redirect to="/menu/3/categories" />
                        </Switch>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TempMenuLayout;

TempMenuLayout.propTypes = {};
