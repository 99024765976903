// react
import React from 'react';

// third-party
import { connect } from 'react-redux';
import MobileLinks from '../mobile/MobileLinks';
import { FormattedMessage } from 'react-intl';

function BlockMobileMenu(props) {
    const { categoriesMenu, locale } = props;

    return (
        <div className="block block-mobile-menu">
            <div className="container">
                <div className="block-mobile-menu-title">
                    <FormattedMessage id={'Categories'} />
                </div>
                <MobileLinks
                    links={categoriesMenu}
                    locale={locale}
                    onItemClick={() => null}
                    mobileMenuState={{ open: null }}
                />
            </div>
        </div>
    );
}

const mapStateToProps = ({ locale, categoriesMenu }) => ({ locale, categoriesMenu });

export default connect(mapStateToProps)(BlockMobileMenu);
