// react
import React, { useEffect, useState } from 'react';

// application
import Pagination from '../../shared/Pagination';
import Rating from '../../shared/Rating';

// data stubs
import axios from 'axios';
import { GLOBAL_URL } from '../../config/general';
import Cookies from 'js-cookie';
import VoteStar from '../../shared/VoteStar';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { TOAST_MESSAGES } from '../../../constants/general';

const STAR_DEFAULT_COUNT = 5;

function ProductTabReviews({ reviews, productId }) {
    const [starCount, setStarCount] = useState(STAR_DEFAULT_COUNT);
    const [commentText, setCommentText] = useState('');
    const [page, setPage] = useState(1);
    const totalPages = Math.ceil(reviews.length / 3);
    const [currentPageReviews, setCurrentPageReviews] = useState([]);

    useEffect(() => {
        const a = reviews.filter((review, i) => {
            return i >= (page - 1) * 3 && i <= 3 * page - 1;
        });

        setCurrentPageReviews(a);
    }, [page, reviews]);

    const handleStarClick = (e) => {
        setStarCount(e.target.id);
    };

    const handleTextChange = (e) => {
        setCommentText(e.target.value);
    };

    return (
        <div className="reviews-view">
            <div className="reviews-view__list">
                <h3 className="reviews-view__header">
                    <FormattedMessage id="Customer Reviews" />
                </h3>
                <div className="reviews-list">
                    <ol className="reviews-list__content">
                        {currentPageReviews.map((review, index) => (
                            <li key={index} className="reviews-list__item">
                                <div className="review">
                                    <div className="review__avatar">
                                        <img src={review.avatar || 'images/avatars/avatar-3.jpg'} alt="" />
                                    </div>
                                    <div className=" review__content">
                                        <div className=" review__author">{`${review.first_name} ${review.last_name}`}</div>
                                        <div className=" review__rating">
                                            <Rating value={review.rate} />
                                        </div>
                                        <div className=" review__text">{review.comment_text}</div>
                                        {/*<div className=" review__date">{review.date}</div>*/}
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ol>
                    {!!currentPageReviews.length ? (
                        <div className="reviews-list__pagination">
                            <Pagination
                                current={page}
                                siblings={2}
                                total={totalPages}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    ) : (
                        <>
                            <FormattedMessage id="There are no reviews yet" />
                        </>
                    )}
                </div>
            </div>

            <form className="reviews-view__form">
                <h3 className="reviews-view__header">
                    <FormattedMessage id="Write A Review" />
                </h3>
                <div className="row">
                    <div className="col-12 col-lg-9 col-xl-8">
                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <label htmlFor="review-stars">
                                    <FormattedMessage id="Review Stars" />
                                </label>
                                <div>
                                    {[1, 2, 3, 4, 5].map((item) => (
                                        <VoteStar
                                            filled={item <= starCount}
                                            handleClick={handleStarClick}
                                            id={item}
                                            key={item}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="review-text">
                                <FormattedMessage id="Your Review" />
                            </label>
                            <textarea
                                className="form-control"
                                id="review-text"
                                rows="6"
                                value={commentText}
                                onChange={handleTextChange}
                            />
                        </div>
                        <div className="form-group mb-0">
                            <button type="submit" className="btn btn-primary btn-lg" onClick={handlePostReview}>
                                <FormattedMessage id="Post Your Review" />
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );

    function handlePageChange(value) {
        setPage(value);
    }

    function handlePostReview(e) {
        e.preventDefault();
        axios
            .post(
                GLOBAL_URL + 'reviews/setReviews',
                { productId, commentText, rate: starCount },
                {
                    headers: {
                        authorization: Cookies.get('token'),
                    },
                }
            )
            .then((res) => {
                const { error, message } = res.data;
                if (!error) {
                    toast.success(TOAST_MESSAGES.reviewSuccess);
                    setCommentText('');
                    setStarCount(STAR_DEFAULT_COUNT);
                } else {
                    toast.error(message);
                }

                console.log(res.data);
            })
            .catch((e) => console.log(e));
    }
}

export default ProductTabReviews;
